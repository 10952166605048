

export const serviceTypes  = [
    { value: 'cleaning', label: 'Cleaning' },
    { value: 'maintenance', label: 'Maintenance' },
    { value: 'repair', label: 'Repair' },
    { value: 'landscaping', label: 'Landscaping' },
    { value: 'security', label: 'Security' },
    { value: 'pest_control', label: 'Pest Control' },
    { value: 'waste_management', label: 'Waste Management' },
    { value: 'plumbing', label: 'Plumbing' },
    { value: 'electrical', label: 'Electrical' },
    { value: 'hvac', label: 'HVAC' },
    { value: 'painting', label: 'Painting' },
    { value: 'roofing', label: 'Roofing' },
    { value: 'snow_removal', label: 'Snow Removal' },
    { value: 'emergency_services', label: 'Emergency Services' },
    { value: 'renovation', label: 'Renovation' },
    { value: 'property_inspection', label: 'Property Inspection' },
    { value: 'appliance_repair', label: 'Appliance Repair' },
    { value: 'locksmith', label: 'Locksmith' },
    { value: 'window_cleaning', label: 'Window Cleaning' },
    { value: 'carpentry', label: 'Carpentry' },
];


export const isCustomServiceType = (serviceType: string | null): boolean => {
    if(serviceType == null){
        return false;
    }
    return !serviceTypes.some(
        (entry: { value: string; label: string }) => entry.value === serviceType
    );
};