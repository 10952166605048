import * as React from "react";
import { useState } from "react";
import { Box, Tabs, Tab, Typography, Button } from "@mui/material";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import { TimelineEvent } from "../interfaces/TimeLineEvent";
import UnsavedChangesConfirmationModal from "./common/UnsavedChangesConfirmationModal";
import TimelineTable from "./TimelineDataGrid";
import Ribbon from "./Ribbon";
import { GridRowModes, GridRowModesModel } from "@mui/x-data-grid";
import TimelineList from "./TimelineDataGrid";

// Utility component for managing tab panels
function TabPanel(props: { children?: React.ReactNode; index: number; value: number; }) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

interface TimelineProps {
    goBack: () => void;
    onDataChange: (contractTimeLineEvents: TimelineEvent[]) => void;
    currentValue: TimelineEvent[] ;
    hasEndDate:boolean |null;
    hasEffectiveDate:boolean |null;
    hasCloseDate:boolean |null;

}

const DefaultAndUserTime: React.FC<TimelineProps> = ({
                                                         currentValue,
                                                         goBack,
                                                         onDataChange,
                                                         hasEndDate,
                                                         hasEffectiveDate,
                                                         hasCloseDate,
                                                     }: TimelineProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const defaultEventsFilter = ["Signature Date", "End Date", "Effective Date", "Renewal Date"];

    const handleSubmit = () => {
        /*if (userCreatedEvents !== undefined) {
            const shortEventsExist = userCreatedEvents.filter((event: { event?: string }) => {
                return event.event !== undefined && event.event.length < 5;
            }).length > 0;

            if (shortEventsExist) {
                return;
            }
        }*/

        const isAnyUserRowInEditMode = Object.values(userRowModesModel).some(row => row.mode === GridRowModes.Edit);
        if (isAnyUserRowInEditMode) {
            setIsModalOpen(true);
            return;
        }
        //

    };

    const handleConfirmCancellation = () => {
        setIsModalOpen(false);
        goBack();
    };

    const handleCancelCancellation = () => {
        setIsModalOpen(false);
    };

    const [defaultEvents, setDefaultEvents] = useState(currentValue);
    const [userCreatedEvents, setUserCreatedEvents] = useState(() => {
        return currentValue}
    );
    const [userRowModesModel, setUserRowModesModel] = useState<GridRowModesModel>({});

    const handleDataChangeUserCreated = (newData: TimelineEvent[]) => {
        setUserCreatedEvents(newData);
    };

    const handleAddEvent = () => {
        //setAddEventTrigger(prev => prev + 1);
    };


    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="h5">Timeline</Typography>
            </Box>

            <TimelineList
                goBack={goBack}
                onDataChange={onDataChange}
                currentValue={userCreatedEvents} // Pass the filtered user-created events
                editable={true}
                title={"Custom Contract Events"}
                hasEndDate={hasEndDate}
                hasEffectiveDate={hasEffectiveDate}
                hasCloseDate={hasCloseDate}
            />

            <UnsavedChangesConfirmationModal
                open={isModalOpen}
                onConfirm={handleConfirmCancellation}
                onCancel={handleCancelCancellation}
                message={"You have rows that are still being edited. Would you like to proceed and submit your changes, ignoring the edits in progress?"}
                confirmMessage={"YES, SUBMIT"}
            />
        </Box>
    );
};

export default DefaultAndUserTime;
