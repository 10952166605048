import React from 'react';
import Chip from '@mui/material/Chip';
import {SxProps, Theme} from "@mui/material";

interface StatusChipProps {
    status: string;
    sx?: SxProps<Theme>;
}

const StatusChip: React.FC<StatusChipProps> = ({ status, sx }) => {
    let backgroundColor, color, fontWeight;
    switch (status) {
        case 'Pending':
            backgroundColor = '#ffd97a'; // Light Yellow
            color = '#9b7e1e'; // Dark Yellow
            fontWeight = 'bold';
            break;
        case 'Paid':
            backgroundColor = '#98FB98'; // Pale Green
            color = '#3CB371'; // Medium Sea Green
            fontWeight = 'bold';
            break;
        case 'Delinquent':
            backgroundColor = '#FFB6C1'; // Light Pink
            color = '#d55595'; // Hot Pink
            fontWeight = 'bold';
            break;
        case 'Complete':
            backgroundColor = '#98FB98'; // Pale Green
            color = '#3CB371'; // Medium Sea Green
            fontWeight = 'bold';
            break;
        case 'Incomplete':
            backgroundColor = '#FFB6C1'; // Light Pink
            color = '#d55595'; // Hot Pink
            fontWeight = 'bold';
            break;
        // Default values for backgroundColor, color, and fontWeight are already undefined
    }

    return (
        <Chip
            label={status}
            size="small"
            sx={{
                bgcolor: backgroundColor || 'transparent',
                color: color || 'inherit',
                fontWeight: fontWeight || 'normal',
                ...sx // Spread the sx props here
            }}
        />
    );
};

export default StatusChip;
