import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import Chat from '../components/Chat'; // Import the Chat component
import ContractEdit from "./ContractEdit";
import {Contract, ContractDataOrNull} from "../interfaces/contract";
import {CONTRACT_ENDPOINT} from "../interfaces/apiConstants";
interface VendorInfo {
    name: string;
    location: string;
    rating: number;
    reviewCount: number;
    imageUrl: string;
}


const vendorInfo: VendorInfo = {
    name: 'Property Manager LLC',
    location: 'New York City, NY',
    rating: 4.96,
    reviewCount: 6,
    imageUrl: '/garage_15346.png', // Replace with the path to the vendor's image
};
const ContractPage: React.FC = () => {

    const { contractId } = useParams<{ contractId: string }>();

    const [contractData2, setContractData2] = useState<Contract>({
        contractId: 999,
        contractName: '',
        vendor: {
            id: 0,
            businessName: '',
        },
        serviceProvider: {
            id: 0,
            businessName: '',
        },
        contractValue: 0,
        paymentTerms: '', // Assuming it's a string, change the type if necessary
        cancellationPolicy: '', // Assuming it's a string, change the type if necessary
        confidentialityClause: '', // Assuming it's a string, change the type if necessary
        liabilityClauses: '', // Assuming it's a string, change the type if necessary
        disputeResolution: '', // Assuming it's a string, change the type if necessary
        signatureStatus: '', // Assuming it's a string, change the type if necessary
        effectiveDate: '',
        notes: [],
        status: '',
        closeDate: null,
        endDate: null,
        attachments: [], // Replace 'any' with a more specific type if possible*/
        scopeOfWork: '',
        talentRequirements: '',
        projectOverview: '',
        serviceType: '',
        events: [],
        netTerm: null,
        term: null,
        hasEffectiveDate: null,
        hasCloseDate: null,
        hasEndDate: null,
        //renewalDate: null
    });
    const fetchContractData = async () => {
        try {
            const response = await fetch(`${CONTRACT_ENDPOINT}/${contractId}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();


            const sortedEvents = data.events.sort((a: any, b: any) => new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime());
            const contractData = {
                contractId: data.contractId || 0, // default to 0 if null
                vendor: data.vendor || "Default Vendor", // default to "Default Vendor" if null
                serviceProvider: data.serviceProvider || "Default Vendor", // default to "Default Vendor" if null
                closeDate: data.closeDate , // default to a placeholder date if null
                endDate: data.endDate, // default to a placeholder date if null
                effectiveDate: data.effectiveDate, // default to a placeholder date if null
                contractName: data.contractName || "Default Contract Name", // default if null
                contractValue: data.contractValue , // default to 0 if null
                paymentTerms: data.netTerm, // default if null
                renewalDate: data.renewalDate, // default if null
                cancellationPolicy: data.cancellationPolicy || "Default Cancellation Policy", // default if null
                confidentialityClause: data.confidentialityClause || "Default Confidentiality Clause", // default if null
                liabilityClauses: data.liabilityClauses || "Default Liability Clauses", // default if null
                disputeResolution: data.disputeResolution || "Default Dispute Resolution", // default if null
                signatureStatus: data.signatureStatus || "Unsigned", // default if null
                notes: data.notes || "No notes available.", // default if null
                status: data.status , // default to "Inactive" if null
                attachments: data.attachments || [], // default if null
                scopeOfWork: data.scopeOfWork || "",
                talentRequirements: data.talentRequirements || "",
                projectOverview: data.projectOverview || "",
                serviceType: data.serviceType ,
                events: sortedEvents || [],
                netTerm: data.netTerm , // default if null
                term: data.term, // default if null
                hasEffectiveDate: data.hasEffectiveDate,
                hasCloseDate: data.hasCloseDate,
                hasEndDate: data.hasEndDate,
            };

            console.log("VVVVVVVVV Got the Contract! VVVVV")
            console.log(data)
            setContractData2(contractData);
        } catch (error) {
            console.error('Fetch error:', error);
            // Handle the error appropriately
        }
    };

    useEffect(() => {
        fetchContractData();
    }, [contractId]);

    const contractData = {
        contractId: contractId,
        location: 'Hotel Downtown',
        client: 'Plumbing Services Inc.',
        project: 'Bathroom Renovation',
        serviceType: 'Plumbing',
        duration: '3 months',
        permitsAndApprovals: 'Client to obtain all necessary permits and approvals.',
        insurance: 'Contractor and client to have liability insurance coverage.',
        paymentSchedule: [
            { milestone: 'Project initiation', amount: '$5,000', dueDate: '01/15/2024', documentLink: undefined },
            { milestone: 'Mid-project inspection', amount: '$10,000', dueDate: '03/15/2024', documentLink: 'https://www.google.com' },
            { milestone: 'Project completion', amount: '$15,000', dueDate: '06/15/2024', documentLink: 'https://www.google.com' },
        ],
        scopeOfWork: `
        - Replace all plumbing fixtures in the existing bathrooms.
        - Install new water supply and drainage systems.
        - Ensure compliance with local plumbing codes.
    `,
        startDate: '2023-01-15 00:00:00',
        endDate: '2023-12-31 00:00:00',
        contractValue: 11000,
        paymentTerms: 'Net 30',
        renewalTerms: '1 year',
        cancellationPolicy: '30 days notice',
        modificationClause: 'Allowed',
        confidentialityClause: 'Standard',
        liabilityClauses: 'Limited',
        disputeResolution: 'Arbitration',
        signature: 'Signed',
        attachments: 'Contract.pdf',
        notes: 'This is a sample contract.',
        status: 'Active'
    };


    return (
        <ContractEdit onClose={()=>{}}
                      onRightPanelExpand={()=>{}}
                      onRightPanelRetract={()=>{}}
                      contract={contractData2} ></ContractEdit>
    );
};

export default ContractPage;
