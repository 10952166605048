// src/components/CustomTable.tsx
import React, {useEffect, useState} from 'react';
import {
    DataGrid,
    GridColDef,
    GridRenderCellParams, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport,
    GridToolbarFilterButton, GridToolbarQuickFilter,
    GridValueGetterParams
} from '@mui/x-data-grid';
import {Link, useHistory} from 'react-router-dom';
import {Button, Fade, Paper, TextField, Typography} from "@mui/material";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import Box from "@mui/material/Box";
import ContractEdit from "./ContractEdit";
import Modal from "@mui/material/Modal";
import {CONTRACT_ENDPOINT} from "../interfaces/apiConstants";
import {useUser} from "./UserProvider";
import {
    fetchAndMapContractsByAccount, postContract,
} from "../apis/fetchContracts";
import DataGridToolBar from "./DataGridToolBar";
import {valueToLabel} from "../utils/valueToLabel";
import {serviceTypes} from "../constants/serviceTyps";
import {useAccount} from "./provider/AccountProvider";
import Chip from "@mui/material/Chip";
import {statusTypes} from "../constants/statusTypes";
import ServiceAgreementModal from "./ServiceAgreementModal";
import {Attachment} from "../interfaces/attachment";
import Ribbon from "./Ribbon";

// Define the structure of your contract data
interface ContractRow {
    contractId: number;
    vendor: string; // Assuming 'vendor' is the field you want to display
    serviceType: string
    status: string
    contractName: string | undefined | null
}

const renderStatus = (value: string | null) => {
    let backgroundColor, color, label;
    switch (value) {
        case '':
            backgroundColor = '#FFB6C1'; // Light Pink
            color = '#d55595'; // Hot Pink
            label = 'Incomplete';
            break;
        case null:
            backgroundColor = '#FFB6C1'; // Light Pink
            color = '#d55595'; // Hot Pink
            label = 'Incomplete';
            break;
        default:
            backgroundColor = '#D3D3D3'; // Light Grey
            color = '#A9A9A9'; // Dark Grey
            label = value;
    }

    if (value === '' || value===null) {
        return (
            <Chip
                label={'Incomplete'}
                size="small"
                sx={{ bgcolor: backgroundColor, color: color, fontWeight: 'bold' }}
            />
        );
    } else {
        return label; // Return the default text if status is empty
    }
};

const columns: GridColDef[] = [
    { field: 'vendorName', headerName: 'Supplier', flex: 1, align: 'center', headerAlign: 'center', renderCell: (params: GridRenderCellParams<any>) => renderStatus(params.value)},
    { field: 'serviceType', headerName: 'Service Type', flex: 1, align: 'center', headerAlign: 'center',
        renderCell: (params: GridRenderCellParams<any>) => {
            // Call valueToLabel and store the result in a variable
            const label = valueToLabel(params.value, serviceTypes);
            // Return the label inside a span element
            return renderStatus(label);
        }
    },
    {
        field: 'contractName',
        headerName: 'Contract Name',
        flex: 1,
        align: 'center',
        headerAlign: 'center',

    },
    {
        field: 'contractId',
        headerName: 'Contract ID',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params: GridRenderCellParams<any>) => {
            // Call valueToLabel and store the result in a variable
            // Return the label inside a span element
            return "100000"+params.value;
        }
    },

    { field: 'status', headerName: 'Status', flex: 1, align: 'center', headerAlign: 'center',
        renderCell: (params: GridRenderCellParams<any>) => {
            // Call valueToLabel and store the result in a variable
            const label = valueToLabel(params.value, statusTypes);
            // Return the label inside a span element
            return renderStatus(label);
        }
    },

];

// Function to construct the file URL and open it in a new window/tab
const openFile = (filename: string) => {
    const fileUrl = `${CONTRACT_ENDPOINT}/file/${filename}`;
    window.open(fileUrl, '_blank');
};

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '10px', // Add border radius here
    '&::-webkit-scrollbar': {
        width: '6px',
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#d3d3d3',
        borderRadius: '10px',
        '&:hover': {
            backgroundColor: '#efa9a9',
        },
    },
};


// Add an interface for the component props
interface CustomTableProps {
    showAddContractButton?: boolean;
    vendorFilter?: string;  // New prop to accept the vendor name for filtering
}
const CustomTable: React.FC<CustomTableProps> = ({ showAddContractButton = true, vendorFilter = ''  }) => {
    const [contracts, setContracts] = useState<ContractRow[]>([]);
    const [loading, setLoading] = useState(true);
    const [isServiceAgreementModalOpen, setIsServiceAgreementModalOpen] = useState(false);

    const { state: { user } } = useUser();
    const { state: { account } } = useAccount();

    useEffect(() => {
        if (user?.id && account) {
            setLoading(true);
            fetchAndMapContractsByAccount(user.id)
                .then(accountToContractsMap => {
                    let filteredContracts = accountToContractsMap.get(account.id) as ContractRow[];
                    // Apply filter if vendorFilter is not empty

                    console.log("DEBUG")
                    if (vendorFilter) {

                        filteredContracts = filteredContracts.filter(contract => contract.vendor.toLowerCase().includes(vendorFilter.toLowerCase()));
                    }
                    setContracts(filteredContracts);
                    setLoading(false);
                })
                .catch(error => {
                    console.error("Failed to fetch contracts:", error);
                    setLoading(false);
                });
        }
    }, [user?.id, account, vendorFilter]);  // Include vendorFilter in dependency array to re-run effect when it changes

    const history = useHistory();

    const handleAddContractClick = () => {
        setIsServiceAgreementModalOpen(true)
        //history.push(`/drawer/contract/addContract`);
    };

    const handleRowClick = (params: any) => {
        const contractId = params.row.contractId;
        history.push(`/drawer/contract/${contractId}`);
    };


    const handleSubmitAttachment = (contractPayload: any, newAttachment: Attachment) => {
        postContract(contractPayload, [newAttachment])
            .then(response => {
                // Assuming the response includes a contract ID, navigate to the contract details page
                history.push(`/drawer/contract/${response.contractId}`);
            })
            .catch(error => {
                // Error handling if the API call fails
                console.error('Failed to submit attachment and contract:', error);

                // Alert the user of the error. Customize the message based on the error or your application's needs
                alert("There was a problem submitting the contract. Please try again.");
            });
    };

    const contractPayload = () => {
        return {
            accountId: account?.id,  // Assuming account ID is also static or null if not specified
            clientId: user?.id,  // Use the user's ID or null if user is undefined
            contractId: null,  // Assuming contract ID is not yet assigned
            vendorId: null,  // No vendor assigned
            serviceProviderId: null,  // No service provider assigned
            contractName: '~3213@',  // Contract name is null initially
            contractValue: 0,  // Initial contract value is 0
            paymentTerms: null,  // No payment terms defined
            renewalTerms: null,  // No renewal terms defined
            cancellationPolicy: '',  // Empty cancellation policy
            confidentialityClause: '',  // Empty confidentiality clause
            liabilityClauses: null,  // No liability clauses defined
            disputeResolution: '',  // Empty dispute resolution
            signatureStatus: '',  // No signature status
            effectiveDate: null,  // No effective date set
            closeDate: null,  // No close date set
            endDate: null,  // No end date set
            projectOverview: '',  // Empty project overview
            talentRequirements: '',  // No talent requirements
            scopeOfWork: '',  // No scope of work defined
            status: '',  // Status is empty
            serviceType: '',  // Service type is undefined
            term: null,  // Term not set
            netTerm: null,  // Net term not set
            events: [],  // No events initially
            notes: []  // No notes initially
        };
    };

    return (
        <Box>
            <div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="h5" style={{ paddingLeft: '5px' }}>Contracts</Typography>
                </div>
                <br />
                <Ribbon
                    leftChildren={
                        showAddContractButton && (
                            <Button
                                onClick={handleAddContractClick}
                                variant="contained"
                                startIcon={<AddTwoToneIcon style={{ fontSize: 'small' }} />}
                            >
                                Add Contract
                            </Button>
                        )
                    }
                    rightChildren={null} // Or you can omit this prop entirely if not needed
                />
                <Fade in={!loading} timeout={400}>
                    <div style={{ height: "80vh", width: '100%' }}>
                        <DataGrid
                            disableColumnMenu
                            rows={contracts}
                            columns={columns}
                            autoPageSize={true}
                            loading={loading}
                            getRowId={(row) => row.contractName}
                            onRowClick={handleRowClick}
                            slots={{
                                toolbar: DataGridToolBar,
                            }}
                            localeText={{ noRowsLabel: account ? "No Rows" : "Please select an account from the dropdown to view contracts." }}
                            sx={{
                                '.MuiDataGrid-columnHeaderTitle': {
                                    fontWeight: 'bold',
                                }
                            }}
                        />
                    </div>
                </Fade>
            </div>
            <ServiceAgreementModal
                attachments={[]}
                open={isServiceAgreementModalOpen}
                onClose={() => setIsServiceAgreementModalOpen(false)}
                currentContractPayload={contractPayload()}
                handleSubmit={handleSubmitAttachment}
                newContract={true}
            />
        </Box>
    );
};

export default CustomTable;
