import React, { useEffect, useState } from 'react';
import { Box, Paper, Grid, Typography, Divider, ListItemText, Fade, List, ListItem } from '@mui/material';
import { useUser } from './UserProvider';
import { useAccount } from './provider/AccountProvider';
import { fetchAndMapAccountAndEvents, fetchAndMapContractsByAccount } from '../apis/fetchContracts';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';

const HomeDashboard = () => {
    const [alerts, setAlerts] = useState<any>([]);
    const [contractCount, setContractCount] = useState<number>(0);
    const { state: { user } } = useUser();
    const { state: { account } } = useAccount();
    const history = useHistory();

    useEffect(() => {
        if (user?.id && account !== null) {
            fetchAndMapAccountAndEvents(user.id)
                .then(accountToEvents => {
                    setAlerts(account && accountToEvents.has(account.id)
                        ? eventsToAlerts(accountToEvents.get(account.id) || [])
                        : []);
                })
                .catch(error => {
                    console.error('Failed to fetch contracts:', error);
                });

            fetchAndMapContractsByAccount(user.id)
                .then(accountToContractsMap => {
                    setContractCount(accountToContractsMap.get(account.id)?.length ?? 0);
                })
                .catch(error => {
                    console.error('Failed to fetch contracts:', error);
                });
        }
    }, [user?.id, account]);

    const eventsToAlerts = (events: any[]) => {
        return events.map(event => ({
            contractId: event.contractId,
            id: event.id,
            contractName: event.contractName,
            paymentName: event.contractName,
            date: event.eventDate,
            amount: event.event
        }));
    };

    const handleAlertClick = (contractId: any) => {
        history.push(`/drawer/contract/${contractId}`);
    };

    const handleContractsClick = () => {
        history.push(`/drawer/contracts`);
    };

    return (
        <Box sx={{ flexGrow: 1, p: 3 }}>
            <Typography variant="h4" gutterBottom>
                Welcome back, {user ? user.name : 'Guest'}.
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                {dayjs().format('MMMM D, YYYY [at] h:mm A')}
            </Typography>

            <Grid container spacing={3} sx={{ mb: 3 }}>
                {/* Summary Papers */}
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Fade in={true} timeout={400}>
                        <Paper
                            onClick={handleContractsClick}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                height: '100%',
                                minHeight: '500px',
                                '&:hover': {
                                    backgroundColor: '#cbcbcb',
                                    cursor: 'pointer'
                                },
                                borderWidth: 1,
                                borderStyle: 'solid',
                                borderColor: 'rgba(176,176,176,0.69)',

                            }}
                            elevation={0}
                        >
                            <Box sx={{ textAlign: 'center', p: 3 }}>
                                <Typography variant="h5" gutterBottom>
                                    Contracts
                                </Typography>
                                <Typography variant="h4">
                                    {contractCount}
                                </Typography>
                            </Box>
                        </Paper>
                    </Fade>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Fade in={true} timeout={400}>
                        <Paper sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%', minHeight: '500px',
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderColor: 'rgba(176,176,176,0.69)',
                        }}
                            elevation={0}
                        >
                            <Box sx={{ textAlign: 'center', p: 3 }}>
                                <Typography variant="h5" gutterBottom>
                                    Upcoming Alerts
                                </Typography>
                                <List sx={{ maxHeight: 300, overflowY: 'auto' }}>
                                    {alerts.map((alert: { contractName: any; paymentName: any; date: any; amount: any; contractId: any }, index: React.Key) => (
                                        <React.Fragment key={index}>
                                            <ListItem
                                                sx={{
                                                    '&:hover': {
                                                        backgroundColor: '#cbcbcb',
                                                        cursor: 'pointer'
                                                    }
                                                }}
                                                onClick={() => handleAlertClick(alert.contractId)}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography component="span" variant="body1" style={{ fontWeight: 'bold' }}>
                                                            {alert.contractName}
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        <>
                                                            <Typography component="span" variant="body2" style={{ fontWeight: 'bold', display: 'block' }}>
                                                                Date: {alert.date}
                                                            </Typography>
                                                            <Typography component="span" variant="body2" style={{ fontWeight: 'bold', display: 'block' }}>
                                                                Event Description: {alert.amount}
                                                            </Typography>
                                                        </>
                                                    }
                                                />
                                            </ListItem>
                                            {index < alerts.length - 1 && <Divider />}
                                        </React.Fragment>
                                    ))}
                                </List>
                            </Box>
                        </Paper>
                    </Fade>
                </Grid>
            </Grid>
        </Box>
    );
};

export default HomeDashboard;
