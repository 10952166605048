import React, { useRef, useState } from 'react';
import {
    Modal,
    Box,
    Typography,
    Button,
    Paper,
    Divider,
    RadioGroup,
    FormControlLabel,
    Radio,
    Stepper,
    Step,
    StepLabel,
    Card,
    CardMedia,
    CardContent,
    IconButton, CircularProgress, Grid, TextField, Autocomplete
} from '@mui/material';
import { Attachment } from "../interfaces/attachment";
import {generateThumbnail, handleParseDocument} from "../apis/fetchAttachment";
import DeleteIcon from "@mui/icons-material/Delete";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import {labelToValue, valueToLabel} from "../utils/valueToLabel";
import {serviceTypes} from "../constants/serviceTyps";
import {chatGPTtoContractPayload} from "../constants/chatGPTtoContractPayload";
import {documentStepRadioOptions} from "../constants/documentStepRadioOptions";

interface ServiceAgreementModalProps {
    open: boolean;
    onClose: () => void;
    currentContractPayload: any;
    handleSubmit: (contractPayload: any, attachment: Attachment) => void;
    attachments: Attachment[]
    newContract: boolean
}

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    height: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '16px', // Adjust the value to make the corners more or less rounded

};

const ServiceAgreementModal: React.FC<ServiceAgreementModalProps> = ({ open, onClose, handleSubmit, currentContractPayload, attachments, newContract}) => {
    const [activeStep, setActiveStep] = useState(0);
    const [selectedValues, setSelectedValues] = useState<{ [key: number]: string }>({});
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [selectedAttachment, setSelectedAttachment] = useState<Attachment | null>(null);
    const [selectedRepalceAttachment, setSelectedRepalceAttachment] = useState<Attachment | null>(null);
    const [status, setStatus] = useState<{ [key: string]: 'accepted' | 'denied' | null }>({});
    const [state, setState] = useState<'ACTIVE_CONTRACT' | 'ACTIVE_DOCUMENT' | 'DRAFT_DOCUMENT' | 'ARCHIVE_DOCUMENT'>('ACTIVE_CONTRACT' );
    const [contractPayload, setContractPayload] = useState(currentContractPayload);
    const [contractName, setContractName] = useState<string| null>(null);

    const handleServiceAgreementAddAttachment = async (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log( event)
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            const newFile: Attachment = {
                id: `NEW_${URL.createObjectURL(file)}`, // Temp ID
                name: file.name,
                file,
                markedForDelete: false,
                tag: "",
                thumbnail: await generateThumbnail(file),
                state: state
            };
            setSelectedAttachment(newFile); // Store the new attachment temporarily
            //setUploadedFiles([...uploadedFiles, file]); // Store the file in the uploaded files list
        }
    };

    //const handleFileRemove = (index: number) => {
    //    setUploadedFiles(uploadedFiles.filter((_, i) => i !== index));
    //};
    const [parsedData, setParsedData] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    function handleParseClick(file: File) {
        setLoading(true); // Start loading
        handleParseDocument(file).then(result => {
            setLoading(false); // Stop loading

            if (result !== null) {
                console.log("HELLO");
                console.log(result);
                setParsedData(result); // Set the parsed data in state
            }
        }).catch(() => {
            setLoading(false); // Stop loading in case of an error
        });
    }

    const handleReplaceAttachment = (event: any, newValue: Attachment | null) => {
        setSelectedRepalceAttachment(newValue)
    }

    const handleTagChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (selectedAttachment) {
            setSelectedAttachment({ ...selectedAttachment, tag: event.target.value });
        }
    }

    const handleAccept = (key: string, value: string) => {
        const contractPayloadKey = labelToValue(key, chatGPTtoContractPayload);
        if (contractPayloadKey in contractPayload && contractPayloadKey === "contractName") {
            setStatus(prev => ({ ...prev, [key]: 'accepted' }));
            contractPayload[contractPayloadKey] = value;
        } else {
            setStatus(prev => ({ ...prev, [key]: 'denied' }));
        }
    };

    const handleDeny = (key: string) => {
        setStatus(prev => ({ ...prev, [key]: 'denied' }));
    };

    function getPayloadValue(key: string) {
        const contractPayloadKey = labelToValue(key, chatGPTtoContractPayload)
        return contractPayloadKey in contractPayload ? contractPayload[contractPayloadKey] : undefined;
    }

    const handleContractNameChange = (event: { target: { value: any; }; }) => {
        const { value } = event.target;
        setContractName(value)
        setContractPayload((prevPayload: any) => ({
            ...prevPayload,
            contractName: value
        }));
    };

    const steps = [
        {
            label: newContract ? "Contract Name" :  "Document Type",
            description: newContract ? "": 'Select the document type',
            options:  newContract ? [] : [
                { value: 'ACTIVE_CONTRACT', label: 'Active Contract' },
                { value: 'ACTIVE_DOCUMENT', label: 'Active Document' },
                { value: 'DRAFT_DOCUMENT', label: 'Draft Document' },
                { value: 'ARCHIVE_DOCUMENT', label: 'Archive Document' },
            ],
            beforeComponent: (
                <>
                    {newContract && (
                        <>
                        <TextField
                            sx={{ width: 300}}
                            fullWidth
                            label="Contract Name"
                            onChange={handleContractNameChange}
                            helperText="Please specify the name of the contract"
                        />
                            <br></br>
                            <br></br>
                            <br></br>
                        </>

                    )}
                </>
            )
        },
        {
            label: 'Choose Document Status',
            component: (
                <Box>
                    {selectedValues[1] === "replace" ? (
                    <Box sx={{mt: 1, maxWidth: 400}}>
                    <Autocomplete
                        id="autocomplete-outlined-basic"
                        options={attachments}
                        getOptionLabel={(option) => option.name}
                        value={selectedRepalceAttachment}
                        onChange={handleReplaceAttachment}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label=""
                                variant="outlined"
                                fullWidth
                            />
                        )}
                    />
                    </Box>) : (<></>)}
                </Box>


            ),
            options: selectedValues[0] === 'ACTIVE_CONTRACT' ? [
                { value: 'new', label: 'New Document' },
                { value: 'replace', label: 'Replace Existing Document' },
            ] : [
                { value: 'new', label: 'New Document' },
            ],
        },
        {
            label: 'Upload Document',
            component: (
                <Box>
                    <Typography variant="h6" sx={{ my: 1 }}>
                        Select your data
                    </Typography>
                    <Button variant="contained" onClick={() => {fileInputRef.current?.click()}}>
                        Upload File
                        <input
                            type="file"
                            hidden
                            onChange={handleServiceAgreementAddAttachment}
                            ref={fileInputRef}
                        />
                    </Button>
                    {selectedAttachment !== null && (
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, pb: 2, pl: 1, mr: 5 }}>
                        <Box>
                            <Typography variant="body1" sx={{ mt: 3 }}>
                                Name:
                            </Typography>
                            <Typography variant="body1" sx={{ mt: 3 }}>
                                Type:
                            </Typography>
                            <Typography variant="body1" sx={{ mt: 3 }}>
                                Status:
                            </Typography>
                            <Typography variant="body1" sx={{ mt: 3 }}>
                                Tag:
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="body1" sx={{ mt: 3 }}>
                              {selectedAttachment?.name || "Not Selected"}
                            </Typography>

                            <Typography variant="body1" sx={{ mt: 3 }}>
                                {valueToLabel(state, documentStepRadioOptions) || "Error"}
                            </Typography>
                            <Typography variant="body1" sx={{ mt: 3 }}>
                               {valueToLabel(selectedValues[1], documentStepRadioOptions) || "Not Selected"}
                            </Typography>
                            <TextField
                                value={selectedAttachment?.tag || ""}
                                onChange={handleTagChange}
                                sx={{ mt: 2, width: 300 }}
                            />
                        </Box>
                        <Box display="flex" justifyContent="center" >
                        <Card
                            sx={{
                                height: '250px',
                                width: '100%',
                                maxWidth: 200,
                            }}
                        >
                            {selectedAttachment?.thumbnail ? (
                                <CardMedia
                                    component="img"
                                    image={selectedAttachment.thumbnail}
                                    alt={selectedAttachment.name}
                                    style={{ height: 250, width: '100%', objectFit: 'cover' }}
                                />
                            ) : (
                                <Paper style={{ height: 250, backgroundColor: '#f0f0f0' }} elevation={0} />
                            )}
                        </Card>
                        </Box>
                        </Box>

                    )}
                </Box>
            ),
        },
        {
            label: 'Parse with AI',
            component: (
                <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',  }}>
                    <Typography variant="h6">
                        {parsedData ?  "Please, Accept or Deny proposed attributes" : "Optionally, use AI to parse the document" }
                    </Typography>
                        {parsedData && (
                            <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                sx={{ mr: 1 }}
                                onClick={() => setParsedData(null)} // Replace with actual accept handler
                            >
                                Reparse
                            </Button>
                        )}
                            </Box>
                    <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>

                        {loading ? (
                                <Box sx={{ height: 300,  display: 'flex', alignItems: 'center',  justifyContent:"center"  }}>
                                    <Box>
                                    <CircularProgress size={60} />
                                    <Typography variant="body1" color={"primary"}>
                                        Parsing...
                                    </Typography>
                                    </Box>
                                </Box>
                        ) : parsedData ? (
                            <Box sx={{ maxHeight: 300, overflowY: 'auto', width: '100%' }}>
                                {/* Header Row */}
                                <Grid container sx={{ alignItems: 'center', mt: 2, pr: 1, borderBottom: '1px solid #ddd' }}>
                                    <Grid item xs={3}>
                                        <Typography variant="body1">
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant="body1"  sx={{fontWeight: 'bold'}}>
                                            AI Parsed Value
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} >
                                        <Typography variant="body1" sx={{fontWeight: 'bold'}}>
                                            Contract Value
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Typography variant="body1">
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {Object.keys(parsedData).map((key) => (
                                    <Grid container key={key} sx={{ alignItems: 'center', mt: 2, pr: 1 }}>
                                        <Grid item xs={3}>
                                            <Typography variant="body2">
                                                {key}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography variant="body2">
                                                {parsedData[key]}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography variant="body2">
                                                {getPayloadValue(key)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                color={ status[key] === 'accepted' ? "primary" : "secondary" }
                                                sx={{ mr: 1}}
                                                onClick={() => handleAccept(key, parsedData[key])}
                                            >
                                                Accept
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                color={ status[key] === 'denied' ? "primary" : "secondary" }
                                                onClick={() => handleDeny(key)}
                                            >
                                                Deny
                                            </Button>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Box>
                        ) : (
                            <Box sx={{ width: 200 }}>
                                <Card
                                    sx={{
                                        height: '250px',
                                        width: '100%',
                                    }}
                                >
                                    {selectedAttachment?.thumbnail ? (
                                        <CardMedia
                                            component="img"
                                            image={selectedAttachment.thumbnail}
                                            alt={selectedAttachment.name}
                                            style={{ height: 250, width: '100%', objectFit: 'cover' }}
                                        />
                                    ) : (
                                        <Paper style={{ height: 250, backgroundColor: '#f0f0f0' }} elevation={0} />
                                    )}
                                </Card>
                                <Button
                                    variant="outlined"
                                    sx={{ mt: 1, width: '100%' }}
                                    startIcon={<AutoFixHighIcon style={{ fontSize: 'large' }} />}
                                    onClick={() => {
                                        if (selectedAttachment && selectedAttachment.file) {
                                            handleParseClick(selectedAttachment.file);
                                        } else {
                                            alert('No document selected or document file is missing.');
                                        }
                                    }}
                                >
                                    Parse!
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Box>
            ),
        },
    ];

    const handleNext = () => {
        // Assuming the upload step index is 2
        const uploadStepIndex = 2;
        const parseStepIndex = 3; // Next step after upload, where parsing begins

        // Ensure an attachment is selected before moving from the upload step to the parse step
        if (activeStep === uploadStepIndex && selectedAttachment === null) {
            alert('Please upload a document to proceed.');
            return;
        }

        if (!newContract && (activeStep === 0 || activeStep === 1) && !selectedValues[activeStep]) {
            alert('Please make a selection to proceed.');
            return;
        }

        if (newContract && (activeStep === 0) && !contractName) {
            alert('Please ent a contract name');
            return;
        }

        if (activeStep === 1 && selectedValues[1] === "replace" && !selectedRepalceAttachment) {
            alert('Please select a replacement document');
            return;
        }

        if(activeStep === 3){
            //alert(JSON.stringify(contractPayload, null, 2));
            if(selectedAttachment !== null){
                handleSubmit(contractPayload, selectedAttachment)
            }else{
                alert('A document was not uplaoded, cannot submit');
            }
            handleReset()
            onClose()
            return;
        }

        // Move to the next step if all validations are passed
        setActiveStep((prevActiveStep) => prevActiveStep + 1);

    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0); // Resetting the step to the beginning of the process
        setSelectedValues({}); // Clearing all selected values
        if (fileInputRef.current) {
            fileInputRef.current.value = ""; // Resetting the file input if needed
        }
        setSelectedAttachment(null); // Clear any selected attachments
        setSelectedRepalceAttachment(null); // Clear any selected attachments for replacement
        setStatus({}); // Resetting the status of each item, assumed to be related to approval/denial
    };

    const handleRadioChange = (stepIndex: number, value: string) => {
        // Update selected values first
        setSelectedValues((prev) => ({
            ...prev,
            [stepIndex]: value,
        }));

        // Check if the current step index is 1
        if (stepIndex === 0) {
            // Call getState to update the state based on the new value
            let state = value as 'ACTIVE_CONTRACT' | 'ACTIVE_DOCUMENT' | 'DRAFT_DOCUMENT' | 'ARCHIVE_DOCUMENT';
            setState(state); // Set the new state

            // If an attachment is already selected, update its state
            setSelectedAttachment(prevAttachment => {
                if (prevAttachment === null) return null; // Return null if the previous attachment is null
                return {
                    ...prevAttachment,
                    state: state // Update the state of the attachment
                };
            });
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Paper sx={modalStyle}>
                <Box sx={{ borderBottom: '1px solid #ddd', pb: 2, mb: 2}}>
                    <Typography variant="h5" component="h2">
                        Contract/Documents
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', height: 'calc(100% - 50px)', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', flex: 1 }}>
                        <Box sx={{ minWidth: 200, maxWidth: 250, mr: 2 }}>
                            <Stepper activeStep={activeStep} orientation="vertical">
                                {steps.map((step, index) => (
                                    <Step key={step.label}>
                                        <StepLabel>{step.label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Box>
                        <Divider orientation="vertical" flexItem />
                        <Box sx={{ ml: 4, flexGrow: 1 }}>
                            {steps.map((step, index) => (
                                <Box key={index} sx={{ display: activeStep === index ? 'block' : 'none' }}>
                                    {step.beforeComponent }
                                    {typeof step.description === 'string' ? <Typography sx={{ fontWeight: 'bold' }}>{step.description}</Typography>: (<></>) }

                                    {step.options ? (
                                        <RadioGroup
                                            value={selectedValues[index] || ''}
                                            onChange={(e) => handleRadioChange(index, e.target.value)}
                                        >
                                            {step.options?.map((option) => (
                                                <FormControlLabel
                                                    key={option.value}
                                                    value={option.value}
                                                    control={<Radio />}
                                                    label={option.label}
                                                />
                                            ))}
                                        </RadioGroup>)
                                        : (<></>)}
                                    {step.component }

                                </Box>
                            ))}
                            {activeStep === steps.length && (
                                <Paper square elevation={0} sx={{ p: 3 }}>
                                    <Typography>All steps completed - you're finished</Typography>
                                    <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                                        Reset
                                    </Button>
                                </Paper>
                            )}
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4, pb: 2, pr: 4 }}>
                        <Box>
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 2 }}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleNext}
                            >
                                {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                            </Button>
                        </Box>
                        <Button variant="outlined" color="secondary" onClick={onClose}>
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </Modal>
    );
};

export default ServiceAgreementModal;
