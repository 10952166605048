import React, {useEffect, useState} from 'react';
import {Box, Button, MenuItem, Select, SelectChangeEvent, Stack, Typography} from "@mui/material";
import {prompText} from "../constants/prompText";
import {Prompt} from "react-router-dom";

type ResponsiveDatePickersProps = {
    label: string;
    goBack: () => void;
    onDataChange: (newValue: string | null) => void;
    currentValue: string | null;
    selections: Array<{ value: string, label: string }>;
};

export default function InputSelect({ label, goBack, onDataChange, currentValue, selections }: ResponsiveDatePickersProps) {
    const [tempData, setTempData] = useState<string | null>(currentValue);
    const [isSaveAction, setIsSaveAction] = useState<boolean | null>(null); // Flag to indicate save/cancel

    // Update the local temporary data value
    const handleDataChange = (event: SelectChangeEvent<string>) => {
        setTempData(event.target.value as string);
    };

    // Call the onDataChange callback with the temporary data value when "Save" is clicked
    const handleSave = () => {
        console.log("DOOOGGGGGGG")
        console.log(tempData)
        if(tempData===undefined){
            onDataChange(null);
        }else{
            onDataChange(tempData);

        }
        setIsSaveAction(true);  // Mark as cancel action
    };

    const handleCancel = () => {
        setIsSaveAction(false);  // Mark as cancel action

    };

    // Effect to handle navigation after state update
    useEffect(() => {
        if (isSaveAction !== null) {
            goBack(); // Trigger goBack after state is set
        }
    }, [isSaveAction]);

    const handlePrompt = (location: any) => {
        const shouldNavigate = window.confirm(prompText);
        if (!shouldNavigate) {
            setIsSaveAction(null); // Reset to null when user cancels the prompt
            return false; // Cancel navigation
        }
        return true; // Allow navigation
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '90vh'
            }}
        >
        <Stack spacing={2} alignItems="center">
            <Typography variant="h5">{label}</Typography>

            <Select
                style={{ minWidth: '200px' }}
                value={tempData ? tempData : undefined}
                onChange={handleDataChange}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
            >
                {selections.map((item) => (
                    <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                ))}
            </Select>

            <Button style={{ width: '50%' }} variant="contained" onClick={handleSave}>Save</Button>
            <Button style={{ width: '50%' }} onClick={handleCancel}>Cancel</Button>

            <Prompt
                when={(tempData !== currentValue) &&  isSaveAction !== true}
                message={handlePrompt }

            />
        </Stack>
        </Box>
    );
}
