// UserContext.tsx
import React, {createContext, useContext, useReducer, ReactNode, useEffect} from 'react';

// Define the user type
interface User {
    name: string;
    password: string;
    id: number;
}

// Define the shape of the context state
interface UserState {
    user: User | null;
}

// Action types
type Action =
    | { type: 'LOGIN'; payload: User }
    | { type: 'LOGOUT' };

// Define the type for the context value
interface UserContextValue {
    state: UserState;
    dispatch: React.Dispatch<Action>;
}

// Create the context with an initial undefined value
const UserContext = createContext<UserContextValue | undefined>(undefined);

// User reducer
const userReducer = (state: UserState, action: Action): UserState => {
    switch (action.type) {
        case 'LOGIN':
            return { ...state, user: action.payload };
        case 'LOGOUT':
            return { ...state, user: null };
        default:
            return state;
    }
};

// UserProvider component
export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const initialState: UserState = {
        // Try to load the user from local storage, or default to null if not found
        user: JSON.parse(localStorage.getItem('user') || 'null'),
    };

    const [state, dispatch] = useReducer(userReducer, initialState);

    // Effect to run when the state changes
    useEffect(() => {
        // Save the user to local storage whenever the state changes
        if (state.user) {
            localStorage.setItem('user', JSON.stringify(state.user));
        } else {
            // Clear the user from local storage if logged out
            localStorage.removeItem('user');
        }
    }, [state.user]);

    return (
        <UserContext.Provider value={{ state, dispatch }}>
            {children}
        </UserContext.Provider>
    );
};
// Custom hook to use the user context
export const useUser = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};
