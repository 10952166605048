import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {Button, Stack, Typography} from "@mui/material";
import { useState } from "react";

type ResponsiveDatePickersProps = {
    label: string;
    goBack: () => void;
    onDataChange: (string: any) => void;
    onFileSelect: (string: any) => void;
    currentValue: string;
};

export default function BasicAttachments({
                                             label,
                                             goBack,
                                             onDataChange,
                                             currentValue,
                                             onFileSelect
                                         }: ResponsiveDatePickersProps) {
    const [tempData, setTempData] = useState<any>(currentValue);
    const [selectedFileName, setSelectedFileName] = useState<string | null>(null);
    const [tempFileUrl, setTempFileUrl] = useState<string | null>(null);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    // Update the local temporary date value
    const handleDataChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTempData(event.target.value);
    };

    // Call the onDateChange callback with the temporary date value when "Save" is clicked
    const handleFileInput = (e: any) => {
        // Handle file selection
        const file = e.target.files[0];
        if (file) {
            setSelectedFileName(file.name); // Set the selected file name

            // Simulate storing the file in a temporary location and get the URL
            // In a real application, you would save the file on your server or in your application's storage
            const tempFileUrl = URL.createObjectURL(file);
            setTempFileUrl(tempFileUrl);
            setSelectedFile(file); // U

            onFileSelect(file);
        }
    };

    interface FileData {
        blobUrl: string | null;
        fileName: string | null;
        file: File | null;
    }

    const openFileInNewTab = () => {
        if (tempFileUrl) {
            window.open(tempFileUrl, '_blank');
        }
    };

    const handleSave = () => {
        //console.log(tempFileUrl)

        const fileData: FileData = {
            blobUrl: tempFileUrl,
            fileName: selectedFileName,
            file: selectedFile,
        };
        onDataChange(fileData);
        goBack()
    };


    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '80vh' // This assumes you want to take the full viewport height
            }}
        >
        <Stack spacing={2} alignItems="center">
            <Typography variant="h5">{label}</Typography>
            <input
                accept="*" // Adjust this to limit to specific file types
                style={{ display: 'none' }}
                id="file-upload-button"
                multiple
                type="file"
                onChange={handleFileInput}
            />
            <label htmlFor="file-upload-button">
                <Button style={{ minWidth: '50%' }} variant="contained" component="span">
                    Upload
                </Button>
            </label>
            {selectedFileName && tempFileUrl && (
                <p>
                    Selected File:{" "}
                    <a href="#" onClick={openFileInNewTab}>
                        {selectedFileName}
                    </a>
                </p>
            )}
            <Button style={{ minWidth: '50%' }} variant="contained" component="span" onClick={handleSave}>
                Save
            </Button>
            <Button style={{ minWidth: '50%' }} onClick={goBack}>
                Cancel
            </Button>
        </Stack>
        </Box>
    );
}
