import React from 'react';
import { Box, Button, Container, Grid, Paper, Tab, Tabs, Typography, Link } from '@mui/material';
import {useParams} from "react-router-dom";
import ContractPage from "./ContractPage";
import CustomTable from "./Dashboard";
function VendorProfile() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
        setValue(newValue);
    };
    const { vendorId } = useParams<{ vendorId: string }>();

    return (
        <Box sx={{height:"90vh", overflowX:"auto"}}>
        <Container component="main" >
            <Paper elevation={0} sx={{ padding: 2, flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={8}>
                        <Typography variant="h4" gutterBottom>
                            {vendorId}
                        </Typography>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="About" />
                                <Tab label="License & Insurance" />
                                <Tab label="Contacts" />
                                <Tab label="Reviews & Ratings" />
                            </Tabs>
                        </Box>


                    </Grid>
                    <Grid item xs={12} sm={4}>

                        <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold'}}>
                            Phone
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            (516) 553-2523
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 ,fontWeight: 'bold'}}>
                            Address
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Wantagh, NY 11793
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom sx={{ mt: 2, fontWeight: 'bold'}}>
                            Services
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                             Garage Doors
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom sx={{ mt: 2, fontWeight: 'bold'}}>
                            Service Areas
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            11793, 11235, 11135
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
            <br/>
            <CustomTable showAddContractButton={false} vendorFilter={vendorId} />
        </Container>
        </Box>
    );
}

export default VendorProfile;
