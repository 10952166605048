import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {Box, Button, Container, MenuItem, Paper, Select, TextField, Typography} from '@mui/material';
import { industries } from '../constants/industries';
import ContractEdit from "./ContractEdit";
import {useHistory} from "react-router-dom";
function AddContract() {

    const history = useHistory();
    const handleClose = () => {
        // Navigate to the contract page with the contract's ID
        history.push(`/drawer/contracts`);
    };

    return (
        <ContractEdit onClose={handleClose} contract={null}
                onRightPanelExpand={()=>{}}
                onRightPanelRetract={()=>{}}></ContractEdit>
    );
}

export default AddContract;