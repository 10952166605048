import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Typography, Box, Switch, FormControlLabel, Fade } from '@mui/material';
import { styled } from '@mui/material/styles';
import SignInForm from "./SignInPage";

// Define a custom styled switch
const CustomSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#53aef3',
        '&:hover': {
            backgroundColor: 'rgba(83, 174, 243, 0.08)',
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#53aef3',
    },
}));

const LandingPage = () => {
    const history = useHistory();
    const [showSignIn, setShowSignIn] = useState(false);
    const [darkMode, setDarkMode] = useState(false);

    const handleThemeToggle = () => setDarkMode(!darkMode);
    const handleShowSignIn = () => setShowSignIn(true);
    const handleBack = () => setShowSignIn(false);

    // Define color variables for light and dark themes
    const lightBackground = 'radial-gradient(circle at center, #FFFFFF 30%, #00FFC491 70%)';
    const lightInnerBackground = 'radial-gradient(circle at center, #FFFFFF 0%, #8049FC8B 100%)';
    const lightOverlay = 'rgba(255,255,255,0.51)';

    const darkBackground = 'radial-gradient(circle at center, #53aef3 0%, #000000 100%)';
    const darkInnerBackground = 'radial-gradient(circle at center, #53aef3 0%, #000000 80%)';
    const darkOverlay = 'rgba(10,12,12,0.55)';

    return (
        <Box
            sx={{
                background: darkMode ? darkBackground : lightBackground,
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
                position: 'relative',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 16,
                    right: 16,
                }}
            >
                <FormControlLabel
                    control={<CustomSwitch checked={darkMode} onChange={handleThemeToggle} />}
                    label={""}
                />
            </Box>
            <Box
                sx={{
                    background: darkMode ? darkInnerBackground : lightInnerBackground,
                    height: '100vh',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        background: darkMode ? darkOverlay : lightOverlay,
                        height: '100vh',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        textAlign: 'center',
                        p: 2,
                    }}
                >
                    <Fade in={!showSignIn} timeout={500}>
                        <Box display={!showSignIn ? 'block' : 'none'}>
                            <Box>
                                <img src="/travis_logo3.png" alt="Logo" style={{ height: '140px' }} />
                            </Box>
                            <Typography variant="h4" component="h2" gutterBottom color={darkMode ? '#8fb1fd' : 'textPrimary'}>
                                Redefining B2B
                            </Typography>
                            <Typography variant="h5" component="h2" gutterBottom color={darkMode ? '#8fb1fd' : 'textPrimary'}>
                                {'Empower Your Business with Seamless Integration, '}
                                {'Smart Sourcing, and Efficient Contract Handling. '}
                            </Typography>
                            <Box sx={{ mt: 4 }}>
                                <Button
                                    variant="outlined"
                                    size="large"
                                    sx={{
                                        mr: 2,
                                        color: darkMode ? '#8fb1fd' : 'black',
                                        borderColor: darkMode ? '#8fb1fd' : 'black',
                                        '&:hover': {
                                            backgroundColor: darkMode ? '#8fb1fd' : 'black',
                                            color: 'white',
                                        },
                                    }}
                                    onClick={handleShowSignIn}
                                >
                                    Transform Your Business
                                </Button>
                            </Box>
                        </Box>
                    </Fade>
                    <Fade in={showSignIn} timeout={500}>
                        <Box display={showSignIn ? 'block' : 'none'}>
                            <SignInForm darkMode={darkMode} />
                            <Button
                                onClick={handleBack}
                                variant="outlined"
                                size="large"
                                sx={{
                                    color: darkMode ? '#8fb1fd' : 'rgba(0, 0, 0, 0.54)',
                                    borderColor: darkMode ? '#8fb1fd' : 'rgba(0, 0, 0, 0.54)',
                                    width: "200px",
                                    '&:hover': {
                                        backgroundColor: darkMode ? '#8fb1fd' : 'rgba(0,0,0,0.96)',
                                        color: 'white',
                                    },

                                }}
                            >
                                Back
                            </Button>
                        </Box>
                    </Fade>
                </Box>
            </Box>
        </Box>
    );
};

export default LandingPage;
