

export const chatGPTtoContractPayload  = [
    { value: 'contractName', label: 'Agreement Number' },
    { value: 'contractStatus', label: 'contractStatus' },
    { value: 'effectiveDate', label: 'Effective Date' },
    { value: 'endDate', label: 'End Date' },
    { value: 'closeDate', label: 'Signature Date' },
    { value: 'netTerm', label: 'Net Terms' },
];


