

import React, {useState} from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    ListItemText,
    makeStyles,
    TextField,
    Typography
} from '@mui/material';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";


const Task: React.FC = () => {

    const [checkedState, setCheckedState] = useState({
        proposals: false,
        contracts: false,
        vendors: false,
        todo: false
    });

    const handleChange = (event: { target: { name: any; checked: any; }; }) => {
        setCheckedState({ ...checkedState, [event.target.name]: event.target.checked });
    };

    const payments = [
        { contractName: "AlphaTech Services Agreement", paymentName: "Initial Deposit", date: "January 15, 2024", amount: "$5,000" },
        { contractName: "BetaCorp Software Licensing", paymentName: "Quarterly License Fee", date: "February 1, 2024", amount: "$2,500" },
        // ... Add 8 more payments from your original list
        { contractName: "Iota Inc Project Delivery", paymentName: "Final Payment", date: "July 22, 2024", amount: "$3,200" },
        { contractName: "Kappa Consulting", paymentName: "Consultation Fee", date: "August 5, 2024", amount: "$1,800" },
        { contractName: "Lambda Logistics", paymentName: "Service Fee", date: "September 10, 2024", amount: "$4,100" },
        { contractName: "Mu Manufacturing", paymentName: "Quarterly Production Charge", date: "October 17, 2024", amount: "$6,500" },
        { contractName: "Nu Networks", paymentName: "Installation Charge", date: "November 25, 2024", amount: "$2,250" },
        { contractName: "Xi Exports", paymentName: "Export Fees", date: "December 1, 2024", amount: "$1,950" },
        { contractName: "Omicron Outsourcing", paymentName: "Monthly Outsourcing Fee", date: "December 15, 2024", amount: "$3,300" },
        { contractName: "Pi Properties", paymentName: "Rental Payment", date: "January 10, 2025", amount: "$2,700" },
        { contractName: "Rho Retailers", paymentName: "Retail Partnership Fee", date: "February 20, 2025", amount: "$2,100" },
        { contractName: "Sigma Services", paymentName: "Annual Service Fee", date: "March 30, 2025", amount: "$5,500" }
        // ... Feel free to add more payments
    ];

    const paymentDetails = {
        contractName: "Mu Manufacturing",
        paymentName: "Quarterly Production Charge",
        date: "October 17, 2024",
        amount: "$6,500"
    };

    const [amount, setAmount] = useState('');

    const handlePayment = () => {
        // Implement payment logic here
        //console.log(`Paying ${amount} for ${paymentDetails.paymentName}`);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={2}>

                <Paper sx={{ p: 2}}>
                    <Typography variant="h5" sx={{ my: 2 }}>
                        Filters
                    </Typography>
                    <FormGroup  sx={{ height: 1000 }}>
                        <FormControlLabel
                            control={<Checkbox checked={checkedState.proposals} onChange={handleChange} name="proposals" />}
                            label="Proposals"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={checkedState.contracts} onChange={handleChange} name="contracts" />}
                            label="Contracts"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={checkedState.vendors} onChange={handleChange} name="vendors" />}
                            label="Vendors"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={checkedState.todo} onChange={handleChange} name="todo" />}
                            label="To Do"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={checkedState.todo} onChange={handleChange} name="todo" />}
                            label="Active"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={checkedState.todo} onChange={handleChange} name="todo" />}
                            label="Past Due"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={checkedState.todo} onChange={handleChange} name="todo" />}
                            label="Expired"
                        />
                    </FormGroup>
                </Paper>
            </Grid>
            <Grid item xs={3}>
                <Paper sx={{ p: 2}}>
                    <Typography variant="h5" sx={{ my: 2 }}>
                        Alerts
                    </Typography>

                    <List sx={{ maxHeight: 1000, overflowY: 'auto' }}> {/* Adjust maxHeight as needed */}
                        {payments.map((payment, index) => (
                            <React.Fragment key={index}>
                                <ListItem
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: '#ffbcbc', // Change this color as needed
                                            cursor: 'pointer'
                                        }
                                    }}
                                >
                                    <ListItemText
                                        primary={`${payment.contractName} - ${payment.paymentName}`}
                                        secondary={`Date: ${payment.date}, Amount: ${payment.amount}`}
                                    />
                                </ListItem>
                                {index < payments.length - 1 && <Divider />}
                            </React.Fragment>
                        ))}
                    </List>
                </Paper>
            </Grid>
            <Grid item xs={7}>
                <Paper sx={{ p: 2 }}>
                    <Typography variant="h5" sx={{ my: 2 }}>
                        Make Payment
                    </Typography>
                    <Grid container spacing={2}  >
                        <Grid item xs={12}>
                            <Typography>Contract Name: {paymentDetails.contractName}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>Payment Name: {paymentDetails.paymentName}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>Due Date: {paymentDetails.date}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>Amount Due: {paymentDetails.amount}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Payment Amount"
                                variant="outlined"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handlePayment}
                            >
                                Submit Payment
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default Task;