// PreviewContext.tsx
import React, { createContext, useContext, useReducer, ReactNode, useEffect } from 'react';

// Define the shape of the context state
interface PreviewState {
    previewEnabled: boolean;
}

// Action types
type Action =
    | { type: 'TOGGLE_PREVIEW' };

// Define the type for the context value
interface PreviewContextValue {
    state: PreviewState;
    dispatch: React.Dispatch<Action>;
}

// Create the context with an initial value
const PreviewContext = createContext<PreviewContextValue | undefined>(undefined);

// Preview reducer
const previewReducer = (state: PreviewState, action: Action): PreviewState => {
    switch (action.type) {
        case 'TOGGLE_PREVIEW':
            return { ...state, previewEnabled: !state.previewEnabled };
        default:
            return state;
    }
};

// PreviewProvider component
export const PreviewProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const initialState: PreviewState = {
        // Initially set to false or load from local storage or other persistence if necessary
        previewEnabled: false,
    };

    const [state, dispatch] = useReducer(previewReducer, initialState);

    // Optionally persist the preview state
    useEffect(() => {
        localStorage.setItem('previewEnabled', JSON.stringify(state.previewEnabled));
    }, [state.previewEnabled]);

    return (
        <PreviewContext.Provider value={{ state, dispatch }}>
            {children}
        </PreviewContext.Provider>
    );
};

// Custom hook to use the preview context
export const usePreview = () => {
    const context = useContext(PreviewContext);
    if (!context) {
        throw new Error('usePreview must be used within a PreviewProvider');
    }
    return context;
};
