import React, { useEffect, useState } from 'react';
import {
    Box,
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Typography,
    Paper,
    Button
} from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { useHistory } from "react-router-dom";
import Divider from "@mui/material/Divider";
import {JOBPOST_ENDPOINT} from "../interfaces/apiConstants";

interface JobPost {
    id: number;
    jobTitle: string;
    location: string;
    projectOverview: string;
    scopeOfWork: string;
    talentRequirements: string;
}

function JobPostList() {
    const [jobPosts, setJobPosts] = useState<JobPost[]>([]);
    const history = useHistory();

    useEffect(() => {
        // Replace with the actual API endpoint
        fetch(`${JOBPOST_ENDPOINT}`)
            .then(response => response.json())
            .then(data => setJobPosts(data))
            .catch(error => console.error('Error fetching job posts:', error));
    }, []);

    const handleAddJobPost = () => {
        history.push('/drawer/jobpost/add');
    };

    const handleSelectJobPost = (id: number) => {
        // Navigate to the job post details page, replace '/jobpost' with the actual route
        //history.push(`/jobpost/${id}`);
        history.push(`/drawer/quote`);

    };

    return (
        <Paper style={{ padding: '20px' }}>
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="h4" style={{ paddingLeft: '5px' }}>Job Posts</Typography>
                <Button onClick={handleAddJobPost}
                        variant="contained"
                        startIcon={<AddTwoToneIcon style={{ fontSize: 'small' }} />}
                >
                    Add Job Post
                </Button>
            </Box>

            <List>
                {jobPosts.map((jobPost, index) => (
                    <React.Fragment key={jobPost.id}>
                        <ListItem alignItems="flex-start" onClick={() => handleSelectJobPost(jobPost.id)}>
                            <ListItemAvatar>
                                {/* Replace with the actual image/avatar logic */}
                                <Avatar>{jobPost.jobTitle[0]}</Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={jobPost.jobTitle}
                                secondary={
                                    <>
                                        <Typography component="span" variant="body2" color="text.primary">
                                            Location: {jobPost.location}
                                        </Typography>
                                        {/* Additional job post details can go here */}
                                    </>
                                }
                            />
                        </ListItem>
                        {/* Conditionally add the Divider */}
                        {index !== jobPosts.length - 1 && <Divider variant="inset" component="li" />}
                    </React.Fragment>
                ))}
            </List>
        </Paper>
    );
}

export default JobPostList;
