import React from 'react';
import {TextField, Button, Container, Grid, Typography, Paper} from '@mui/material';


type ResponsiveDatePickersProps = {
    goBack: () => void;
    onDataChange: (string: any) => void;
};

interface ContactInformation {
    firstName: string;
    lastName: string;
    address1: string;
    address2?: string; // Optional since it's not marked with an asterisk
    city: string;
    state: string;
    zipCode: string;
    phone: string;
    email: string;
}

export default function ContactForm({  goBack, onDataChange }: ResponsiveDatePickersProps) {
    // You would handle form submission here

    const [contactInfo, setContactInfo] = React.useState<ContactInformation>({
        firstName: '',
        lastName: '',
        address1: '',
        address2: '', // Optional field, can be omitted
        city: '',
        state: '',
        zipCode: '',
        phone: '',
        email: '',
    });

    const handleChange = (event: { target: { name: any; value: any; }; }) => {
        const { name, value } = event.target;
        setContactInfo({ ...contactInfo, [name]: value });
    };


    const handleSubmit = (event: any) => {
        onDataChange(contactInfo);
        goBack();
        // Form processing logic goes here
    };

    const handleCancel = ()=>{
        goBack();
    };

    return (
        <Container component="main" maxWidth="xs">
            <Typography component="h1" variant="h5">
                Contact Info
            </Typography>
            <br/>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            id="first-name"
                            label="First Name"
                            name="firstName"
                            autoComplete="given-name"
                            value={contactInfo.firstName}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            id="last-name"
                            label="Last Name"
                            name="lastName"
                            autoComplete="family-name"
                            value={contactInfo.lastName}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            id="address1"
                            label="Address 1"
                            name="address1"
                            autoComplete="address-line1"
                            value={contactInfo.address1}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="address2"
                            label="Address 2"
                            name="address2"
                            autoComplete="address-line2"
                            value={contactInfo.address2}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            id="city"
                            label="City"
                            name="city"
                            autoComplete="address-level2"
                            value={contactInfo.city}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            required
                            fullWidth
                            id="state"
                            label="State"
                            name="state"
                            autoComplete="address-level1"
                            value={contactInfo.state}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            required
                            fullWidth
                            id="zip"
                            label="ZIP Code"
                            name="zip"
                            autoComplete="postal-code"
                            value={contactInfo.zipCode}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            id="phone"
                            label="Phone"
                            name="phone"
                            autoComplete="tel"
                            value={contactInfo.phone}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            value={contactInfo.email}
                            onChange={handleChange}
                        />
                    </Grid>
                     </Grid>
                    <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ mt: 2 }}>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                fullWidth // Makes the button stretch to full width
                            >
                                Submit
                            </Button>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 1 }}> {/* Add margin-top for spacing between buttons */}
                            <Button
                                onClick={handleCancel} // Change this to handleCancel or similar for actual cancel behavior
                                fullWidth // Makes the button stretch to full width
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
            </form>
        </Container>
    );
}

