import * as React from "react";
import { useState, useEffect } from "react";
import { Box, Button, Typography, TextField, IconButton, List, ListItem, ListItemText, ListItemSecondaryAction, Paper } from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import generateNewId from "../utils/generateId";
import dayjs from 'dayjs';
import UnsavedChangesConfirmationModal from './common/UnsavedChangesConfirmationModal';
import Ribbon from "./Ribbon";

interface ContractTimeLineEvent {
    id: string;
    noteDate: Date;
    note: string;
    markedForDelete: boolean;
}

interface TimelineProps {
    goBack: () => void;
    onDataChange: (contractTimeLineEvents: ContractTimeLineEvent[]) => void;
    currentValue: ContractTimeLineEvent[] | null;
}

const darkenColor = (color: string, factor: number) => {
    let colorInt = parseInt(color.slice(1), 16);
    let r = (colorInt >> 16) & 255;
    let g = (colorInt >> 8) & 255;
    let b = colorInt & 255;
    r = Math.max(0, r - factor);
    g = Math.max(0, g - factor);
    b = Math.max(0, b - factor);
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
};

const TextAreaTable: React.FC<TimelineProps> = ({ currentValue, goBack, onDataChange }: TimelineProps) => {
    const [rows, setRows] = useState<ContractTimeLineEvent[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (currentValue && currentValue.length > 0) {
            const sortedRows = [...currentValue].sort((a, b) => b.noteDate.getTime() - a.noteDate.getTime());
            setRows(sortedRows);
        } else {
            setRows([{
                id: generateNewId(),
                noteDate: new Date(),
                note: '',
                markedForDelete: false,
            }]);
        }
    }, [currentValue]);

    const handleAddEvent = () => {
        const id = generateNewId();
        setRows((oldRows) => [
            {
                id,
                noteDate: new Date(),
                note: '',
                markedForDelete: false,
            },
            ...oldRows,
        ]);
    };

    const handleInputChange = <T extends keyof ContractTimeLineEvent>(index: number, field: T, value: ContractTimeLineEvent[T]): void => {
        const updatedRows: ContractTimeLineEvent[] = [...rows];
        updatedRows[index][field] = value;
        setRows(updatedRows);
    };

    const handleDeleteEvent = (index: number) => {
        const updatedRows: ContractTimeLineEvent[] = [...rows];
        updatedRows[index].markedForDelete = true;
        setRows(updatedRows);
    };

    const handleSubmit = () => {
        const isAnyRowInEditMode = rows.some(row => row.note === '' || row.note === 'Default note (will not be saved if filled)');

        if (isAnyRowInEditMode) {
            setIsModalOpen(true);
            return;
        }

        onDataChange(rows.filter(row => row.note !== 'Default note (will not be saved if filled)'));
        goBack();
    };

    const handleCancel = () => {
        goBack();
    };

    const handleConfirmCancellation = () => {
        setIsModalOpen(false);
        onDataChange(rows.filter(row => row.note !== 'Default note (will not be saved if filled)'));
        goBack();
    };

    const handleCancelCancellation = () => {
        setIsModalOpen(false);
    };

    const baseColor = '#73b9f5';
    const darkeningFactor = 15;

    const displayRows = rows.filter(row => !row.markedForDelete);

    return (
        <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="h5">Notes</Typography>
            </Box>
            <Box sx={{ overflow: 'auto', p: 2 }}>
            <Ribbon
                leftChildren={
                    <Button onClick={handleAddEvent} startIcon={<AddTwoToneIcon />}>
                        Add Note
                    </Button>
                }
                rightChildren={
                    <>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            Save
                        </Button>
                        <Button onClick={handleCancel}>
                            Cancel
                        </Button>
                    </>
                }
            />
            <Box>
                <List>
                    {displayRows.map((row, index) => (
                        <Paper
                            key={row.id}
                            sx={{
                                m: 2,
                                p: 2,
                                borderLeft: 7,
                                borderRight: 1,
                                borderTop: 1,
                                borderBottom: 1,
                                borderColor: 'rgba(52,52,52,0.69)',
                                borderRadius: 2
                            }}
                            elevation={0}
                        >
                            <ListItem>
                                <ListItemText
                                    primary={
                                        <TextField
                                            multiline
                                            minRows={3}
                                            value={row.note}
                                            onChange={(e) => handleInputChange(index, 'note', e.target.value)}
                                            fullWidth
                                            variant="outlined"
                                            InputProps={{
                                                style: {
                                                    fontSize: '.9rem',
                                                    backgroundColor: '#f9f9f9',
                                                    border: '1px solid #ccc',
                                                    borderRadius: '4px',
                                                    padding: '8px',
                                                    overflow: 'auto',
                                                }
                                            }}
                                        />
                                    }
                                    secondary={
                                        <TextField
                                            type="date"
                                            value={dayjs(row.noteDate).format('YYYY-MM-DD')}
                                            onChange={(e) => handleInputChange(index, 'noteDate', new Date(e.target.value))}
                                            fullWidth
                                            variant="standard"
                                            InputProps={{ style: { fontSize: '0.8rem' } }}
                                        />
                                    }
                                />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteEvent(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </Paper>
                    ))}
                </List>
            </Box>
            <UnsavedChangesConfirmationModal
                open={isModalOpen}
                onConfirm={handleConfirmCancellation}
                onCancel={handleCancelCancellation}
                message={"You have rows that are still being edited. Would you like to proceed and submit your changes, ignoring the edits in progress?"}
                confirmMessage={"YES, SUBMIT"}
            />
            </Box>
        </Box>
    );
};

export default TextAreaTable;
