import {
    GridToolbarColumnsButton,
    GridToolbarContainer, GridToolbarExport,
    GridToolbarFilterButton,
    GridToolbarQuickFilter
} from "@mui/x-data-grid";
import React from "react";
import ContractEdit from "./ContractEdit";


function DataGridToolBar() {
    return (
        <GridToolbarContainer sx={{ paddingTop: 2, paddingRight: 2, paddingLeft: 2,  display: 'flex', justifyContent: 'space-between' }}>
            <div>
                <GridToolbarQuickFilter />
            </div>
            <div>
                <GridToolbarExport />
            </div>
        </GridToolbarContainer>
    );
}


export default DataGridToolBar;