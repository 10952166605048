import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, InputAdornment, Stack, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { Prompt } from "react-router-dom";
import { prompText } from "../constants/prompText";

type ResponsiveDatePickersProps = {
    label: string;
    goBack: () => void;
    onDataChange: (string: any) => void;
    originalValue: string | null;
    startText: string;
    commas: boolean;
};

export default function BasicTextFields({ label, goBack, onDataChange, originalValue, startText, commas }: ResponsiveDatePickersProps) {
    const [currentValue, setCurrentValue] = useState<any>(originalValue);
    const [isSaveAction, setIsSaveAction] = useState<boolean | null>(null); // Flag to indicate save/cancel

    // Update the local temporary value
    const handleDataChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const numericValue = value.replace(/,/g, ''); // Remove existing commas
        if (!isNaN(Number(numericValue)) && commas) {
            setCurrentValue(formatNumber(numericValue));
        } else {
            setCurrentValue(value);
        }
    };

    // Save the changes and mark as save action
    const handleSave = () => {
        onDataChange(currentValue);
        setIsSaveAction(true);  // Mark as save action
    };

    // Cancel the changes and mark as cancel action
    const handleCancel = () => {
        setIsSaveAction(false);  // Mark as cancel action
    };

    // Effect to handle navigation after state update
    useEffect(() => {
        if (isSaveAction !== null) {
            goBack(); // Trigger goBack after state is set
        }
    }, [isSaveAction]);

    // Format the number with commas
    function formatNumber(value: string) {
        return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    const handlePrompt = (location: any) => {
        const shouldNavigate = window.confirm(prompText);
        if (!shouldNavigate) {
            setIsSaveAction(null); // Reset to null when user cancels the prompt
            return false; // Cancel navigation
        }
        return true; // Allow navigation
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '90vh' // This assumes you want to take the full viewport height
            }}
        >
            <Stack spacing={2} alignItems="center">
                <Typography variant="h5">{label}</Typography>
                <TextField
                    id="outlined-basic"
                    variant="outlined"
                    value={currentValue}
                    onChange={handleDataChange}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">{startText}</InputAdornment>,
                    }}
                    sx={{ width: '300px' }}
                />

                <Button style={{ width: '50%' }} variant="contained" onClick={handleSave}>
                    Save
                </Button>
                <Button style={{ width: '50%' }} onClick={handleCancel}>
                    Cancel
                </Button>
            </Stack>

            {/* The Prompt will only show if there are unsaved changes and the user hasn't clicked save */}
            <Prompt
                when={currentValue !== originalValue && isSaveAction !== true}
                message={handlePrompt}
            />
        </Box>
    );
}
