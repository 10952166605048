import React, {useEffect, useRef, useState} from "react";
import dayjs from "dayjs";
import ResponsiveDatePickers from "./InputDateComponent";
import BasicTextFields from "./InputTextField";
import InputSelect from "./InputSelect";
import {
    Accordion,
    Alert, Avatar,
    Button, Divider, Fade,
    Grid,
    IconButton, ListItemText, Paper, Slide, Snackbar, Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow, Tabs, TextField, Tooltip,
    Typography, useTheme
} from "@mui/material";
import NotificationsIcon from '@mui/icons-material/Notifications';
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArticleTwoToneIcon from '@mui/icons-material/ArticleTwoTone';
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import BasicAttachments from "./BasicAttachments";
import InputVendorTextField from "./InputVendorTextField";
import ProfileVendorEdit from "./ProfileVendorEdit";
import {Contract, ContractDataOrNull} from "../interfaces/contract";
import {CONTRACT_ENDPOINT} from "../interfaces/apiConstants";
import {useUser} from "./UserProvider";
import AttachmentsPage from "./AttachmentPage";
import ScopeOfWorkPage from "./ScopeOfWorkPage";
import {ScopeItem} from "../interfaces/scopeOfWork";
import {isCustomServiceType, serviceTypes} from "../constants/serviceTyps";
import {useHistory, useLocation} from "react-router-dom";
import TimelineDataGrid from "./TimelineDataGrid";
import TimelineTable from "./TimelineDataGrid";
import StatusChip from "./common/StatusChip";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import DataTable from "./SchedulePayment";
import ContractInvoiceTable from "./ContractInvoicePage";
import ReconInvoiceTable from "./ReconInvoice";
import AutoAwesomeTwoToneIcon from "@mui/icons-material/AutoAwesomeTwoTone";
import {valueToLabel} from "../utils/valueToLabel";
import InputSelectWithOverride from "./InputSelectWithOverride";
import TermInputNumber from "./TermInputNumber";
import {TabPanel, TimelineOppositeContent} from "@mui/lab";
import NotesTable from "./NotesDataGrid";
import TextAreaTable from "./TextAreaTable";
import NotificationsActiveTwoToneIcon from "@mui/icons-material/NotificationsActiveTwoTone";
import {TimelineEvent} from "../interfaces/TimeLineEvent";
import {useAccount} from "./provider/AccountProvider";
import {Vendor2} from "../interfaces/Vendor2";
import InputAutocomplete from "./InputAutocomplete";
import DefaultAndUserTime from "./DefaultAndUserTime";
import {statusTypes} from "../constants/statusTypes";
import DocumentsDashboard from "./DocumentsDashboard";
import DocumentsEdit from "./DocumentsEdit";
import HorizontalTimeline from "./HorizontalTimeline";
import DateDisplay from "./DateDisplay";
import TimelineComponent from "./TimelineComponent";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import {Attachment} from "../interfaces/attachment";
import {deleteAttachmentApi, saveAttachmentApi} from "../apis/fetchContracts";
import {ChevronLeft, ChevronRight} from "@mui/icons-material";
import TimelineList from "./TimelineDataGrid";
import NotificationSettings from "./NotificationSettings";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import Filter1Icon from "@mui/icons-material/Filter1";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import EventIcon from "@mui/icons-material/Event";
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
interface ContractEditProps {
    onClose: () => void; // onClose is a function that takes no arguments and returns void
    contract: ContractDataOrNull
    onRightPanelExpand:() => void ;
    onRightPanelRetract:() => void ;

}



const ContractEdit: React.FC<ContractEditProps> = ({ onClose, contract,
                                                       onRightPanelExpand,
                                                       onRightPanelRetract }) => {

    const { state: { user } } = useUser(); // Destructure to get user from state
    // Function to initialize form fields for a new contract

    const initializeNewContract = () => {
        setContractName(null);
        setVendor(null);
        setServiceProvider({
            vendorId:null,
            vendorName:''
        });
        setContractValue("0");
        setPaymentTerms(null)
        setRenewalTerms(null)
        setCancellationPolicy('')
        setCancellationPolicy('')
        setConfidentialityClause('')
        setLiabilityClause(null)
        setDisputeResolution('')
        setSignature('')
        setAttachments([])
        //setAttachment(null)
        setScopeItems({
            talentRequirements: "",
            scopeOfWork: "",
            projectOverview: "",
        })
        setStatus('')
        setClientId(user?.id)
        setServiceType('')
        setHasEndDate(null)
        setHasCloseDate(null)
        setHasEffectiveDate(null)
        // ... set other fields to default values
    };

    // Function to load existing contract data into form fields
    const loadExistingContract = (contractData: Contract) => {
        setContractId(contractData.contractId)
        setContractName(contractData.contractName);
        setVendor({
            vendorId: contractData.vendor.id,
            vendorName: contractData.vendor.businessName, //contractData.vendorName
        });
        setServiceProvider({
            vendorId: contractData.serviceProvider.id,
            vendorName: contractData.serviceProvider.businessName, //contractData.vendorName
        });
        setContractValue(contractData.contractValue.toString())
        setPaymentTerms(contractData.netTerm)
        setRenewalTerms({
            term:contractData.term
        })
        setCloseDate(contractData.closeDate ? (dayjs(contractData.closeDate)) : null);
        setEffectiveDate(contractData.effectiveDate ? (dayjs(contractData.effectiveDate)) : null);
        setEndDate(contractData.endDate ? (dayjs(contractData.endDate)) : null);
        setCancellationPolicy(contractData.cancellationPolicy)
        setConfidentialityClause(contractData.confidentialityClause)
        setLiabilityClause(contractData.liabilityClauses)
        setDisputeResolution(contractData.disputeResolution)
        setSignature(contractData.signatureStatus)
        const convertedAttachments: Attachment[] = contractData.attachments.map((attachment) => ({
            id: attachment.attachmentId.toString(), // Convert number to string
            name: attachment.fileName,
            file: new File([""], attachment.fileName, { type: "application/octet-stream" }), // Placeholder File object; you might need actual file data here
            tag: attachment.tag || undefined, // Convert empty string to undefined if needed
            markedForDelete: false,
            thumbnail: null,
            state: attachment.state
        }));

        setScopeItems({
            talentRequirements: contractData.talentRequirements,
            scopeOfWork: contractData.scopeOfWork,
            projectOverview: contractData.projectOverview,
        })

        const convertEvents: TimelineEvent[] = contractData.events.map((timeLineEvent: TimelineEvent) => ({
            id: timeLineEvent.id.toString(),
            event: timeLineEvent.event, // Convert number to string
            eventDate: new Date(timeLineEvent.eventDate), // Convert number to string
            markedForDelete: false,
            notify: true, // Default to false; user can enable
            dailyNotificationXDaysBefore: 2, // Default 2 days before
            weeklyNotificationXDaysBefore: 0, // Default 2 days before
            monthlyNotificationXDaysBefore: 0, // Default 2 days before
        }));

        const notes: Note[] = contractData.notes.map((note: Note) => ({
            id: note.id.toString(),
            note: note.note, // Convert number to string
            noteDate: new Date(note.noteDate), // Convert number to string
            markedForDelete: false,
        }));
        setNotes(notes)
        console.log("MATTHEW PIERI")
        console.log(convertEvents)
        setContractTimelineEvent(convertEvents)
        setAttachments(convertedAttachments);
        setStatus(contractData.status)
        setCancellationPolicy(contractData.cancellationPolicy)
        setServiceType(contractData.serviceType)

        setHasEndDate(contractData.hasEndDate)
        setHasCloseDate(contractData.hasCloseDate)
        setHasEffectiveDate(contractData.hasEffectiveDate)

        // ... set other fields with data from contractData
    };

    // Effect to determine if we are editing an existing contract or creating a new one

    const [contractId, setContractId] = useState<number | null>(null);

    const [clientId, setClientId] = useState<number | null | undefined>(null);

    const [serviceType, setServiceType] = useState<string | null>('');
    const [hasCloseDate, setHasCloseDate] = useState<boolean | null>(null);
    const [hasEndDate, setHasEndDate] = useState<boolean | null>(null);
    const [hasEffectiveDate, setHasEffectiveDate] = useState<boolean | null>(null);

    function handleServiceType(serviceType: string | null) {
        let localContract = contractPayload()
        localContract.serviceType = serviceType;
        handleSubmit(localContract).then(r => {
            console.log(JSON.stringify(r, null, 2))
            setServiceType(serviceType)
        })
    }

    const [contractName, setContractName] = useState<string | null>(null);

    const [scopeItems, setScopeItems] = useState<ScopeItem>({
        projectOverview: "",
        scopeOfWork: "",
        talentRequirements:"", // Optional property for a tag
    });

    function handleScopeChange(scopeItems: ScopeItem) {
        setScopeItems(scopeItems)
        let localContract = contractPayload()
        localContract.scopeOfWork = scopeItems.scopeOfWork;
        handleSubmit(localContract).then(r => {})
    }

    const hancleContractNameChange = (newContractValue: string) => {
        let localContract = contractPayload()
        localContract.contractName = newContractValue;
        handleSubmit(localContract).then(response => {
            try {
                setContractName(response.contractName);

                console.log("matt")
                console.log(response)

                const convertEvents: TimelineEvent[] = response.events.map((timeLineEvent: TimelineEvent) => ({
                    id: timeLineEvent.id.toString(),
                    event: timeLineEvent.event, // Convert number to string
                    eventDate: new Date(timeLineEvent.eventDate), // Convert number to string
                    markedForDelete: false,
                    notify: timeLineEvent.notify, // Default to false; user can enable
                    dailyNotificationXDaysBefore: timeLineEvent.dailyNotificationXDaysBefore, // Default 2 days before
                    weeklyNotificationXDaysBefore: timeLineEvent.weeklyNotificationXDaysBefore, // Default 2 days before
                    monthlyNotificationXDaysBefore: timeLineEvent.monthlyNotificationXDaysBefore, // Default 2 days before
                }));

                setContractTimelineEvent(convertEvents)
                handleEditNone();
            } catch (error) {
                console.error('Failed to change contract name:', error);
            }
        });
    };

    const { state: { account } } = useAccount(); // Destructure to get account from state

    const contractPayload = () =>{
        return {
            accountId: account?.id,
            clientId: clientId,
            contractId: contractId,
            vendorId: vendor?.vendorId,
            serviceProviderId: serviceProvider?.vendorId,
            contractName: contractName,
            contractValue: parseFloat(contractValue.replace(/[$,]/g, '')),
            //attachments: 'test',
            paymentTerms,
            renewalTerms,
            cancellationPolicy,
            confidentialityClause,
            liabilityClauses: liabilityClause,
            disputeResolution,
            signatureStatus: signature,
            effectiveDate:  effectiveDate ? effectiveDate.format('YYYY-MM-DD') : null,
            closeDate: closeDate ? closeDate.format('YYYY-MM-DD') : null,
            endDate: endDate ? endDate.format('YYYY-MM-DD') : null,
            projectOverview: scopeItems.projectOverview,
            talentRequirements: scopeItems.talentRequirements,
            scopeOfWork: scopeItems.scopeOfWork,
            status: status,
            serviceType: serviceType,
            term: renewalTerms?.term,
            //renewalDate: renewalTerms.renewalDate,
            netTerm: paymentTerms,
            events: contractTimelineEvent.map(event => {
                if (event.id.includes('NEW')) {
                    return { ...event, id: '' }; // creates a new object with the updated id
                }
                return event; // returns the original event if no update is needed
            }),
            notes: notes.map(note => {
                if (note.id.includes('NEW')) {
                    return { ...note, id: '' }; // creates a new object with the updated id
                }
                return note; // returns the original event if no update is needed
            }),
            hasEffectiveDate: hasEffectiveDate,
            hasCloseDate: hasCloseDate,
            hasEndDate: hasEndDate,
        };
    }

    const [closeDate, setCloseDate] = useState<dayjs.Dayjs | null>(null);
    const handleCloseDateChange = (newDate: dayjs.Dayjs, isApplicable: boolean | null) => {
        let localContract = contractPayload()
        localContract.closeDate = newDate ? newDate.format('YYYY-MM-DD') : null;
        localContract.hasCloseDate = isApplicable;
        handleSubmit(localContract).then(r => {
            setCloseDate(newDate);
            setHasCloseDate(isApplicable)
        })
    };

    const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(null);
    const handleEndDateChange = (newDate: dayjs.Dayjs, isApplicable: boolean | null) => {
        let localContract = contractPayload()
        localContract.endDate = newDate ? newDate.format('YYYY-MM-DD') : null;
        localContract.hasEndDate = isApplicable;
        handleSubmit(localContract).then(r => {
            setEndDate(newDate);
            setHasEndDate(isApplicable)
        })
    };

    const [effectiveDate, setEffectiveDate] = useState<dayjs.Dayjs | null>(null);
    const handleEffectiveDateChange= (newDate: dayjs.Dayjs, isApplicable: boolean | null) => {
        let localContract = contractPayload()
        localContract.effectiveDate = newDate ? newDate.format('YYYY-MM-DD') : null;
        localContract.hasEffectiveDate = isApplicable;
        handleSubmit(localContract).then(r => {
            setEffectiveDate(newDate);
            setHasEffectiveDate(isApplicable)
        })
    };


    const [contractValue, setContractValue] = useState<string>("0");
    const handleContractValueChange = (newContractValue: string) => {
        setContractValue(newContractValue);
        let localContract = contractPayload()
        localContract.contractValue = parseFloat(contractValue.replace(/[$,]/g, ''));
        handleSubmit(localContract).then(r => {})
    };

    const contractValueSelections = [
        { value: 'NET_30', label: 'Net 30' },
        { value: 'NET_45', label: 'Net 45' },
        { value: 'NET_60', label: 'Net 60' },
    ];

    const [paymentTerms, setPaymentTerms] = useState<string | null>(null);
    const handlePaymentTermsChange = (newPaymentTerms: string | null) => {
        setPaymentTerms(newPaymentTerms);
        let localContract = contractPayload()
        localContract.netTerm = newPaymentTerms;
        handleSubmit(localContract).then(r => {})

    };

    const renewalTermsSelections = [
        { value: '.5 year', label: '.5 year' },
        { value: '1 year', label: '1 year' },
        { value: '2 year', label: '2 year' },
    ];

    /* RENEWAL TERMS */

    interface RenewalTerms{
        term: string | null,
        //renewalDate: string | null
    }

    const [renewalTerms, setRenewalTerms] = useState<RenewalTerms | null>({
        term:"0_DAYS",
        //renewalDate:null
    });
    const handleRenewalTermsChange = (newRenewalTerms: RenewalTerms) => {
        let localContract = contractPayload()
        localContract.term = newRenewalTerms.term;
        //localContract.renewalDate = newRenewalTerms.renewalDate;
        handleSubmit(localContract).then(response => {
            try {
                console.log(response)
                setRenewalTerms({term: response.term});
            } catch (error) {
                console.error('Failed to change renewal term:', error);
            }
        });
    };

    /* CANCELLATION POLICY  */

    const cancellationPolicySelections = [
        { value: '30 days notice', label: '30 days notice' },
        { value: '45 days notice', label: '45 days notice' },
        { value: '60 days notice', label: '60 days notice' },
    ];

    const [cancellationPolicy, setCancellationPolicy] = useState<string>('30 days notice');
    const handleCancellationPolicy = (newCancellationPolicy: string) => {
        setCancellationPolicy(newCancellationPolicy);
    };

    /* CONFIDENTIALITY CLAUSE */

    const confidentialityClauseSelections = [
        { value: 'Standard', label: 'Standard' },
    ];

    const [confidentialityClause, setConfidentialityClause] = useState<string>('Standard');
    const handleConfidentialityClause = (newConfidentialityClause: string) => {
        setConfidentialityClause(newConfidentialityClause);
    };

    /* LIABILITY CLAUSE */

    const liabilityClauseSelections = [
        { value: 'Limited', label: 'Limited' },
    ];

    const [liabilityClause, setLiabilityClause] = useState<string | null>('Limited');
    const handleLiabilityClause = (newLiabilityClause: string) => {
        setLiabilityClause(newLiabilityClause);
    };

    /* DISPUTE RESOLUTION */

    const disputeResolutionSelections = [
        { value: 'Arbitration', label: 'Arbitration' },
    ];

    const [disputeResolution, setDisputeResolution] = useState<string>('Arbitration');
    const handleDisputeResolution = (newDisputeResolution: string) => {
        setDisputeResolution(newDisputeResolution);
    };

    /* SIGNATURE */

    const signatureSelections = [
        { value: 'SIGNED', label: 'SIGNED' },
        { value: 'PENDING SIGNATURE', label: 'PENDING SIGNATURE' },
    ];

    const [signature, setSignature] = useState<string>('PENDING SIGNATURE');
    const handleSignature = (newSignature: string) => {
        setSignature(newSignature);
    };

    //const [attachmentName, setAttachmentName] = useState<string | null>('');
    const [attachments, setAttachments] = useState<Attachment[] >([]);

    const handleAttachmentChange = (attachments: Attachment[], contractPayload: any) => {
        let localContract = contractPayload;//contractPayload()
        handleSubmit(localContract, attachments).then(response => {
            const convertedAttachments: Attachment[] = response.attachments.map((attachment: { attachmentId: { toString: () => any; }; fileName: string; tag: any; thumbnail: string | null; state: 'ACTIVE_CONTRACT' | 'ACTIVE_DOCUMENT' | 'DRAFT_DOCUMENT' | 'ARCHIVE_DOCUMENT'; }) => ({
                id: attachment.attachmentId.toString(), // Convert number to string
                name: attachment.fileName,
                file: new File([""], attachment.fileName, { type: "application/octet-stream" }), // Placeholder File object; you might need actual file data here
                tag: attachment.tag || undefined, // Convert empty string to undefined if needed
                markedForDelete: false,
                thumbnail: attachment?.thumbnail,
                state: attachment.state
            }));
            setAttachments(convertedAttachments);
            setContractName(response.contractName);
        })
    };

    const [contractTimelineEvent, setContractTimelineEvent] = useState<TimelineEvent[] >([]);

    interface Note {
        id: string;
        noteDate: Date;
        note: string;
        markedForDelete: boolean,
    }

    const [notes, setNotes] = useState<Note[] >([

    ]);


    const handleContractTimeLineEventsChange = (contractTimeLineEvents: TimelineEvent[]) => {
        setContractTimelineEvent(contractTimeLineEvents);
        let localContract = contractPayload();

         let transformed = contractTimeLineEvents.map(event => {
            if (event.id.includes('NEW')) {
                return { ...event, id: '' }; // creates a new object with the updated id
            }
            return event; // returns the original event if no update is needed
        })
        console.log(transformed)

        localContract.events = transformed
        handleSubmit(localContract).then(r => {})
    };

    const handleNotesChange = (notes: Note[]) => {
        setNotes(notes);
        let localContract = contractPayload();

        let transformed = notes.map(note => {
            if (note.id.includes('NEW')) {
                return { ...note, id: '' }; // creates a new object with the updated id
            }
            return note; // returns the original event if no update is needed
        })

        localContract.notes = transformed
        handleSubmit(localContract).then(r => {})
    };

    const statusSelections = [
        { value: 'NOT_ACTIVE', label: 'NOT ACTIVE' },
        { value: 'ACTIVE', label: 'ACTIVE' },
    ];

    const [status, setStatus] = useState<string | null>(null);
    const handleStatus = (status: string | null) => {
        setStatus(status);
        let localContract = contractPayload()
        localContract.status = status;
        handleSubmit(localContract).then(r => {})
    };



    const [vendor, setVendor] = useState<Vendor2 | null>({
        vendorId: null,
        vendorName: ''
    });

    const [serviceProvider, setServiceProvider] = useState<Vendor2 | null>({
        vendorId: null,
        vendorName: ''
    });
    const handleVendor = (vendor: Vendor2 | null, serviceProviderVendor: Vendor2 | null) => {
        setVendor(vendor);
        setServiceProvider(serviceProviderVendor)
        let localContract = contractPayload()
        localContract.vendorId =  vendor?.vendorId;
        localContract.serviceProviderId =  serviceProviderVendor?.vendorId;
        handleSubmit(localContract).then(r => {})
    };

    const EditStates = {
        VENDOR: 'VENDOR',
        CLOSE_DATE: 'CLOSE_DATE',
        END_DATE: 'END_DATE',
        CONTRACT_VALUE: 'CONTRACT_VALUE',
        PAYMENT_TERMS: 'PAYMENT_TERMS',
        RENEWAL_TERMS: 'RENEWAL_TERMS',
        CANCELLATION_POLICY: 'CANCELLATION_POLICY',
        CONFIDENTIALITY_CLAUSE: 'CONFIDENTIALITY_CLAUSE',
        LIABILITY_CLAUSE: 'LIABILITY_CLAUSE',
        DISPUTE_RESOLUTION: 'DISPUTE_RESOLUTION',
        SIGNATURE: 'SIGNATURE',
        ATTACHMENTS: 'ATTACHMENTS',
        NOTES: 'NOTES',
        STATUS: 'STATUS',
        CONTRACT_NAME: 'CONTRACT_NAME',
        NONE: null,
        SCOPE_OF_WORK: 'SCOPE_OF_WORK',
        SERVICE_TYPE: 'SERVICE_TYPE',
        TIME_LINE: 'TIME_LINE',
        EFFECTIVE_DATE: 'EFFECTIVE_DATE'
        // Add other states as needed
    };

    const [editState, setEditState] = useState<string | null>(null);


    const updateURLWithQueryParam = (paramKey: string, paramValue?: string) => {
        const params = new URLSearchParams(window.location.search);
        if (paramValue) {
            // Set the parameter if a value is provided
            params.set(paramKey, paramValue);
        } else {
            // Remove the parameter if no value is provided
            params.delete(paramKey);
        }

        const newSearch = params.toString();
        const newUrl = newSearch ? `?${newSearch}` : ''; // If no params remain, create a clean URL

        history.push({ search: newUrl }); // v5
    };

    const location = useLocation();
    const getQueryParam = (param: string) => {
        const searchParams = new URLSearchParams(location.search);
        return searchParams.get(param);
    };

    useEffect(() => {
        const editParam = getQueryParam('edit');
        switch (editParam) {
            case 'vendor':
                setEditState(EditStates.VENDOR);
                break;
            case 'contractName':
                setEditState(EditStates.CONTRACT_NAME);
                break;
            case 'closeDate':
                setEditState(EditStates.CLOSE_DATE);
                break;
            case 'effectiveDate':
                setEditState(EditStates.EFFECTIVE_DATE);
                break;
            case 'endDate':
                setEditState(EditStates.END_DATE);
                break;
            case 'contractValue':
                setEditState(EditStates.CONTRACT_VALUE);
                break;
            case 'scopeOfWork':
                setEditState(EditStates.SCOPE_OF_WORK);
                break;
            case 'timeline':
                setEditState(EditStates.TIME_LINE);
                break;
            case 'paymentTerms':
                setEditState(EditStates.PAYMENT_TERMS);
                break;
            case 'renewalTerms':
                setEditState(EditStates.RENEWAL_TERMS);
                break;
            case 'attachments':
                setEditState(EditStates.ATTACHMENTS);
                break;
            case 'notes':
                setEditState(EditStates.NOTES);
                break;
            case 'status':
                setEditState(EditStates.STATUS);
                break;
            case 'serviceType':
                setEditState(EditStates.SERVICE_TYPE);
                break;
            default:
                setEditState(EditStates.NONE);
        }
    }, [location.search]); // This triggers when the URL search params change
    const handleEditNone = () => {
        console.log("Handle Edit None")
        console.log("Handle Edit None")
        console.log("Handle Edit None")
        updateURLWithQueryParam('edit'); // This will remove the 'edit' query parameter entirely
        //setEditState(EditStates.NONE);
    };

    const handleEditVendor = () => {
        updateURLWithQueryParam('edit', 'vendor');
    };

    const handleEditContractName = () => {
        updateURLWithQueryParam('edit', 'contractName');
    };

    const handleEditCloseDate = () => {
        updateURLWithQueryParam('edit', 'closeDate');
    };

    const handleEditEffectiveDate = () => {
        updateURLWithQueryParam('edit', 'effectiveDate');
    };

    const handleEditEndDate = () => {
        updateURLWithQueryParam('edit', 'endDate');
    };

    const handleEditContractValue = () => {
        updateURLWithQueryParam('edit', 'contractValue');
    };

    const handleEditScopeOfWork = () => {
        updateURLWithQueryParam('edit', 'scopeOfWork');
    };

    const handleEditTimeline = () => {
        updateURLWithQueryParam('edit', 'timeline');
    };

    const handleEditPaymentTerms = () => {
        updateURLWithQueryParam('edit', 'paymentTerms');
    };

    const handleEditRenewalTerms = () => {
        updateURLWithQueryParam('edit', 'renewalTerms');
    };

    const handleEditAttachments = () => {
        updateURLWithQueryParam('edit', 'attachments');
    };

    const handleEditNotes = () => {
        updateURLWithQueryParam('edit', 'notes');
    };

    const handleEditStatus = () => {
        updateURLWithQueryParam('edit', 'status');
    };

    const handleEditServiceType = () => {
        updateURLWithQueryParam('edit', 'serviceType');
    };

    function handleTimeTableGoBack() {
        setEditState(EditStates.NONE)
        onRightPanelRetract()
    }


    const editActions = {
        vendor: handleEditVendor,
        contractName: handleEditContractName,
        closeDate: handleEditCloseDate,
        effectiveDate: handleEditEffectiveDate,
        endDate: handleEditEndDate,
        contractValue: handleEditContractValue,
        scopeOfWork: handleEditScopeOfWork,
        timeline: handleEditTimeline,
        paymentTerms: handleEditPaymentTerms,
        renewalTerms: handleEditRenewalTerms,
        attachments: handleEditAttachments,
        notes: handleEditNotes,
        status: handleEditStatus,
        serviceType: handleEditServiceType,
        timeTableGoBack: handleTimeTableGoBack,
    };

    const handleEditBasedOnURL = () => {
        const params = new URLSearchParams(window.location.search);

        for (const [key, action] of Object.entries(editActions)) {
            if (params.has(key)) {
                action();
                break; // If you want to handle only the first matched key, otherwise remove this line
            }
        }
    };

// Call this function in a useEffect to check the URL when the component mounts
    useEffect(() => {
        handleEditBasedOnURL();
    }, []);


    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    // Example delete API call function


    const history = useHistory();

    //const isInitialMount = useRef(true);
    const [isInitialMount, setIsInitialMount] = useState(true);
    const [shouldHandleSubmit, setShouldHandleSubmit] = useState(false);

    useEffect(() => {
        // Reset shouldHandleSubmit on each contract change to prevent handleSubmit after loading/initializing contract.
        if (contract) {
            console.log(contract);
            loadExistingContract(contract);
        } else {
            initializeNewContract();
        }
    }, [contract]);

    const isSubmitting = useRef(false);

    function replaceLastUrlSegmentWithoutNavigating(newSegment: string): void {
        // Get the current URL from the window object
        const currentUrl = window.location.href;

        // Create a URL object
        const urlObject = new URL(currentUrl);

        // Break the pathname into parts
        const pathSegments = urlObject.pathname.split('/');

        // Replace the last segment with the new segment
        pathSegments[pathSegments.length - 1] = newSegment;

        // Join the segments back to form the new pathname
        urlObject.pathname = pathSegments.join('/');

        // Update the URL in the browser's address bar without navigating
        window.history.replaceState({}, '', urlObject.toString());
    }

    const [historyList, setHistoryList] = useState<string[]>([]);

    const getBasePath = (path: string) => {
        return path.split('?')[0]; // Return everything before the '?' (the base path)
    };


    ///////////////**** Redirect Url to Edit State None if user tries to directly access Edit Pages /////////////////
   /* useEffect(() => {
        // Update history when location changes, using the base path without query params
        const basePath = getBasePath(location.pathname);
        setHistoryList((prevHistory) => [...prevHistory, basePath]);
    }, [location]);

    useEffect(() => {
        const checkNavigation = () => {
            const basePath = getBasePath(location.pathname);

            // Check if the base path exists in the historyList
            if (!historyList.includes(basePath)) {
                // Redirect to the same base path without the query params
                history.push(basePath);
            }
        };

        checkNavigation();
    }, [history, location]);*/
    ///////////////**** Redirect Url to Edit State None if user tries to directly access Edit Pages /////////////////

    const handleSubmit = async (contractPayload: any, attachments: Attachment[] = []) => {
        console.log("Handle Submit!!!!!!!!!!!!!!")
        // Prepare the contract details data
        const contractDetails = contractPayload;
        console.log(contractDetails);

        const contractDetailsData =  JSON.stringify(contractDetails);

        // First, submit the contract details
        try {
            const responseDetails = await fetch(`${CONTRACT_ENDPOINT}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: contractDetailsData
            });

            if (!responseDetails.ok) {
                return responseDetails.text().then(text => {
                    const message =  text || 'Contract details submission failed'
                    console.log(text)
                    setErrorMessage(message);
                    setOpen(true);
                    return;
                });

            }

            // Parse the JSON response body to access the contractId
            const responseData = await responseDetails.json(); // This line extracts the JSON body

            // Check if the responseData actually contains the contractId
            if (responseData && responseData.contractId) {

                setContractId(responseData.contractId) //TODO Move this out of here
                replaceLastUrlSegmentWithoutNavigating(responseData.contractId);

                const convertEvents: TimelineEvent[] = responseData.events.map((timeLineEvent: TimelineEvent) => ({
                    id: timeLineEvent.id.toString(),
                    event: timeLineEvent.event,
                    eventDate: new Date(timeLineEvent.eventDate),
                    markedForDelete: timeLineEvent.markedForDelete,
                    notify: timeLineEvent.notify,
                    dailyNotificationXDaysBefore: timeLineEvent.dailyNotificationXDaysBefore,
                    weeklyNotificationXDaysBefore: timeLineEvent.weeklyNotificationXDaysBefore,
                    monthlyNotificationXDaysBefore: timeLineEvent.monthlyNotificationXDaysBefore,
                }));

                setContractTimelineEvent(convertEvents);

                const notes: Note[] = responseData.notes.map((note: Note) => ({
                    id: note.id.toString(),
                    note: note.note, // Convert number to string
                    noteDate: new Date(note.noteDate), // Convert number to string
                    markedForDelete: note.markedForDelete,
                }));
                setNotes(notes)

                if( attachments ){
                    attachments.forEach((attachment) => {
                        console.log(attachment)

                        if(attachment.markedForDelete){
                            deleteAttachmentApi(attachment.id);
                        }else{
                            saveAttachmentApi(attachment, responseData.contractId);
                        } //TODO FIX UPDATE CONTRACT BUG THIS DISCOUNT BUG
                    });
                }

            } else {
                throw new Error('No contract ID present or no file to upload');
            }

            return responseData

        } catch (error: any) {
            console.error('There was an error submitting the contract:', error);
            setErrorMessage(error.toString());
            setOpen(true);
            return Promise.reject(error.toString());
        }

    };

    function handleVendorClick() {
        history.push('/drawer/vendor/' + vendor);
    }


    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    interface TabPanelProps {
        children?: React.ReactNode;
        index: number;
        value: number;
    }

    const handleTabsClick = (event: React.MouseEvent) => {
        event.stopPropagation();
    };


    const displayEvents = contractTimelineEvent
        .filter(event => {
            return !(event.event === "Signature Date" && (hasCloseDate === false || hasCloseDate === null));
        }).filter(event => {
            return !(event.event === "End Date" && (hasEndDate === false || hasEndDate === null));
        }).filter(event => {
            return !(event.event === "Effective Date" && (hasEffectiveDate === false || hasEffectiveDate === null));
        })
        .sort((a, b) => new Date(b.eventDate).getTime() - new Date(a.eventDate).getTime()); // Sort by date

    const getIconForEventType = (eventType: string) => {
        switch (eventType) {
            case 'Signature Date':
                return <DriveFileRenameOutlineIcon color="primary" />;
            case 'End Date':
                return <EventBusyIcon color="secondary" />;
            case 'Effective Date':
                return <Filter1Icon color="secondary" />;
            case 'Renewal Date':
                return <AutorenewIcon />;
            default:
                return <EventIcon />;
        }
    };

    const theme = useTheme();
    const renderEditComponent = () => {
        switch (editState) {
            case EditStates.VENDOR:
                return  <InputVendorTextField currentValue={vendor} currentServiceProvider={serviceProvider} goBack={ handleEditNone} onDataChange={handleVendor} label={"VENDOR"} />;
            case EditStates.CLOSE_DATE:
                return < ResponsiveDatePickers key="closeDate" isApplicable={hasCloseDate} currentValue={closeDate} goBack={handleEditNone} onDateChange={handleCloseDateChange} label={"Signature Date"}/>;
            case EditStates.END_DATE:
                return  <ResponsiveDatePickers key="endDate"  isApplicable={hasEndDate} currentValue={endDate} goBack={handleEditNone} onDateChange={handleEndDateChange} label={"End Date"}/>;
            case EditStates.CONTRACT_VALUE:
                return  <BasicTextFields  key="contractValue" originalValue={contractValue} goBack={handleEditNone} onDataChange={handleContractValueChange} commas={true} label={"Contract Value"} startText={"$"} />;
            case EditStates.PAYMENT_TERMS:
                return  <InputSelectWithOverride currentValue={paymentTerms} goBack={handleEditNone} onDataChange={handlePaymentTermsChange} label={"Net Terms"} selections={contractValueSelections}/>;
            case EditStates.RENEWAL_TERMS:
                return  <TermInputNumber currentValue={renewalTerms} goBack={handleEditNone} onDataChange={handleRenewalTermsChange} label={"Renewal Terms"} />;
           /* case EditStates.CANCELLATION_POLICY:
                return  <InputSelect currentValue={cancellationPolicy} goBack={ () => setEditState(EditStates.NONE)} onDataChange={handleCancellationPolicy} label={"Cancellation Policy"} selections={cancellationPolicySelections}/>;
            case EditStates.CONFIDENTIALITY_CLAUSE:
                return  <InputSelect currentValue={confidentialityClause} goBack={ () => setEditState(EditStates.NONE)} onDataChange={handleConfidentialityClause} label={"Confidentiality Clause"} selections={confidentialityClauseSelections}/>;
            case EditStates.LIABILITY_CLAUSE:
                return  <InputSelect currentValue={liabilityClause || ""} goBack={ () => setEditState(EditStates.NONE)} onDataChange={handleLiabilityClause} label={"Liability Clause"} selections={liabilityClauseSelections}/>;
            case EditStates.DISPUTE_RESOLUTION:
                return  <InputSelect currentValue={disputeResolution} goBack={ () => setEditState(EditStates.NONE)} onDataChange={handleDisputeResolution} label={"Dispute Resolution"} selections={disputeResolutionSelections}/>;
            case EditStates.SIGNATURE:
                return  <InputSelect currentValue={signature} goBack={ () => setEditState(EditStates.NONE)} onDataChange={handleSignature} label={"Signature"} selections={signatureSelections}/>;*/
            case EditStates.CONTRACT_NAME:
                return  <BasicTextFields  key="contractName" originalValue={contractName} goBack={handleEditNone} onDataChange={hancleContractNameChange} commas={false} label={"Contract Name"} startText={""} />;
            case EditStates.ATTACHMENTS:
                return <DocumentsEdit currentValue={attachments} goBack={handleEditNone} onDataChange={handleAttachmentChange}  contractPayload={contractPayload()} />;
                //return <AttachmentsPage readonly={false} currentValue={attachments} goBack={ () => setEditState(EditStates.NONE)} onDataChange={handleAttachmentChange}  ></AttachmentsPage>
                //return  <BasicAttachments onFileSelect={()=>{}} currentValue={contractName} goBack={ () => setEditState(EditStates.NONE)} onDataChange={handleAttachmentChange} label={"Attachments"} />;
            case EditStates.STATUS:
                return  <InputSelect currentValue={status} goBack={handleEditNone} onDataChange={handleStatus} label={"Status"} selections={statusSelections}/>;
            case EditStates.SCOPE_OF_WORK:
                return <ScopeOfWorkPage currentValue={scopeItems} goBack={handleEditNone} onDataChange={handleScopeChange}  ></ScopeOfWorkPage>;
            case EditStates.TIME_LINE:
                return <DefaultAndUserTime currentValue={contractTimelineEvent} onDataChange={handleContractTimeLineEventsChange} goBack={ handleTimeTableGoBack} hasCloseDate={hasCloseDate} hasEffectiveDate={hasEffectiveDate} hasEndDate={hasEndDate}></DefaultAndUserTime>
            case EditStates.SERVICE_TYPE:
                return  <InputAutocomplete currentValue={serviceType} goBack={handleEditNone} onDataChange={handleServiceType} label={"Service Type"} selections={serviceTypes}/>;
            case EditStates.NOTES:
                return <TextAreaTable  currentValue={notes} onDataChange={handleNotesChange} goBack={ handleTimeTableGoBack}></TextAreaTable>
            case EditStates.EFFECTIVE_DATE:
                return  <ResponsiveDatePickers key="effectiveDate"  currentValue={effectiveDate} isApplicable={hasEffectiveDate}  goBack={handleEditNone} onDateChange={handleEffectiveDateChange} label={"Effective Date"}/>;

                //return  <NotesTable currentValue={contractTimelineEvent} onDataChange={()=>{console.log("asdf")}} goBack={ handleTimeTableGoBack} ></NotesTable>

            // Add cases for other attributes
            default:
                return <div>
                    <Paper elevation={0} sx={{  mx:1,

                    }}>
                        <Box p={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography variant="h5" sx={{ pb: 2 }}>
                                Contract Name: {contractName}
                            </Typography>
                            <IconButton onClick={handleToggle} sx={{ color: 'primary.main' }}>
                                {isMinimized ?  <ChevronLeft /> : <ChevronRight />  }
                            </IconButton>
                        </Box>
                        <Box display="flex" justifyContent="space-between" sx={{ pl: 1,  pr: 5 }}>
                            <Box sx={{ pb: 2 }}>
                                <Typography variant="h6" sx={{ pb: 1 }}>Service Type</Typography>
                                <Typography>{serviceType ? valueToLabel(serviceType, serviceTypes) : ''}</Typography>
                            </Box>
                            <Box sx={{ pb: 2 }}>
                                <Typography variant="h6" sx={{ pb: 1 }}>Total Contract Value</Typography>
                                <Typography>{"$" + new Intl.NumberFormat().format(Number(contractValue))}</Typography>
                            </Box>
                            <Box sx={{ pb: 2 }}>
                                <Typography variant="h6" sx={{ pb: 1 }}>Status</Typography>
                                <Typography>{status ? valueToLabel(status, statusTypes): ""}</Typography>
                            </Box>
                        </Box>
                        <Box  p={1}>
                        <Typography variant="h6" sx={{ pb: 1 }}>Scope of Work</Typography>
                        <Typography sx={{ pb: 2 }}>{scopeItems.scopeOfWork}</Typography>
                        </Box>
                    </Paper>

                    <br/>
                    <Box
                        sx={{
                            mx: 1,

                        }}>

                    <Accordion
                        defaultExpanded
                        elevation={0}
                        sx={{
                            padding: "5px",
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderColor: 'rgba(176,176,176,0.69)'
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                        {/*<Typography variant="h5" style={{ padding: "5px"}}>Timeline</Typography>*/}
                        <Tabs value={value} onChange={handleChange} onClick={handleTabsClick} aria-label="basic tabs example">
                            <Tab
                                label={"Timeline  (" + displayEvents.length  +")"}
                                {...a11yProps(0)}
                                sx={{
                                    typography: 'h6',
                                    paddingRight: theme.spacing(2),
                                    textTransform: 'none', // Prevents uppercase transformation
                                }}
                            />
                            <Tab
                                label={"Documents (" + attachments?.filter(attachment => !attachment.markedForDelete).length + ")"}
                                {...a11yProps(1)}
                                sx={{
                                    typography: 'h6',
                                    paddingRight: theme.spacing(2),
                                    textTransform: 'none', // Prevents uppercase transformation
                                }}
                            />

                            <Tab
                                label={"Notes  (" + notes?.filter(note => !note.markedForDelete).length   +")"}
                                {...a11yProps(2)}
                                sx={{
                                    typography: 'h6',
                                    paddingRight: theme.spacing(2),
                                    textTransform: 'none', // Prevents uppercase transformation
                                }}
                            />
                        </Tabs>
                        </AccordionSummary>
                        <AccordionDetails >
                        <Box >{/*sx={{overflowY:'auto', minHeight: '350px', maxHeight: '700px'}}>*/}
                        {value === 0 && (
                            <Box sx={{  backgroundColor: '#ffffff',  }}>
                                <List>
                                    {displayEvents.map((row, index) => (
                                        <Accordion key={row.id}
                                                   elevation={0}
                                                   sx={{
                                                       padding: "5px",
                                                       borderWidth: 1,
                                                       borderStyle: 'solid',
                                                       borderColor: 'rgba(176,176,176,0.6)',
                                                   }}
                                        >
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                <Grid container alignItems="center" spacing={2} sx={{ width: '100%' }}>
                                                    <Grid item xs={3}>
                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                            {getIconForEventType(row.event)}
                                                            <Typography sx={{ ml: 2 }}>{row.event || ''}</Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <Typography>{dayjs.utc(row.eventDate).format('ddd, MMM DD, YYYY')}</Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </AccordionSummary>
                                                <AccordionDetails>
                                                    <Divider sx={{ mb: 2 }} />
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                                        <Box sx={{ flex: 1 }}>
                                                            {/* Non-Editable View */}
                                                            <Grid container spacing={1} sx={{ width: '100%', mb: 1 }}>
                                                                <Grid item xs={3}>
                                                                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Event:</Typography>
                                                                </Grid>
                                                                <Grid item xs={9}>
                                                                    <Typography variant="body1">{row.event}</Typography>
                                                                </Grid>

                                                                <Grid item xs={3}>
                                                                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Date:</Typography>
                                                                </Grid>
                                                                <Grid item xs={9}>
                                                                    <Typography variant="body1">{dayjs.utc(row.eventDate).format('YYYY-MM-DD')}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Box>
                                                </AccordionDetails>
                                        </Accordion>
                                    ))}
                                </List>
                            </Box>
                        )}
                        {value === 1 && (
                            <DocumentsDashboard currentValue={attachments} contractPayload={null} editable={false} goBack={handleEditNone} onDataChange={null}
                            ></DocumentsDashboard>
                        )}
                        {value === 2 && (
                            <List sx={{mx:3, marginTop: 1} }>
                                {notes.map((note) => {
                                    const dateString = new Date(note.noteDate).toLocaleDateString('en-US', {
                                        weekday: 'short', // "Wed"
                                        year: 'numeric', // "2024"
                                        month: 'short', // "Mar"
                                        day: 'numeric', // "03"
                                    });
                                    return (

                                    <Paper elevation={2} key={note.id} sx={{ mb: 2,

                                    }}>
                                        <ListItem>
                                            <ListItemText primary={note.note} secondary={dateString} />
                                        </ListItem>
                                    </Paper>
                                )})}
                            </List>
                        )}
                        </Box>
                        </AccordionDetails>
                    </Accordion>
                    </Box>

                    <br/>
                    <Paper elevation={0} sx={{ p: 2,  mx:1,
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: 'rgba(176,176,176,0.69)'
                    }}>
                        <DataTable></DataTable>
                    </Paper>
                    <br/>
                    <Paper elevation={0} sx={{ p: 2,  mx:1,
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: 'rgba(176,176,176,0.69)'
                    }}>
                        <ContractInvoiceTable></ContractInvoiceTable>
                    </Paper>
                    <br />
                    {renderContent2()}
                    <br />
                    <br />
                    <br />
                </div>; // or some default view
        }
    };


    const checkCompletion = () => {
        // Destructure your scopeItems to access the properties directly
        const { projectOverview, scopeOfWork, talentRequirements } = scopeItems;

        // Check if any of the values are empty strings
        if ( !scopeOfWork) {
            return "Incomplete";
        }
        return "Complete";
    };


    const [runRecon, setRunRecon] = React.useState(false);
    const handleRunRecon = () => setRunRecon(true);
    const renderContent2 = () => {
        if (runRecon) {
            // Render the edit component if an edit state is set
            return(
                <Paper elevation={0} sx={{ p: 2,  mx:1,
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: 'rgba(176,176,176,0.69)'
                }}>
                    <ReconInvoiceTable></ReconInvoiceTable>
                </Paper>
            )
        } else {
            return (
                <Paper elevation={0} sx={{padding:"5px",  height: 400,  display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: 'rgba(176,176,176,0.69)'
                }}>
                    <Button onClick={handleRunRecon}><AutoAwesomeTwoToneIcon style={{ fontSize: '100px', cursor: 'pointer' }} /></Button>
                    <Typography variant="subtitle1" >Run Invoice Recon</Typography>
                </Paper>
            )
        }
    }


    const test = () =>{
        console.log("TEST TEST TEST");
        console.log(closeDate);
        return closeDate ? closeDate.format('M/DD/YYYY') : '';

    }

    const disableContractFields = (label: string) =>{
        return "Contract Name" !== label && contractName===null;
    }

    const trimText = (text: string, maxLength: number) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    const getNetTermLabel = (text: string | null) =>{
        if(text){
            if(text === "NA"){
                return "Not Applicable"
            }else {
                return "Net " + text.split("_")[1];
            }
        }
    }

    const getDateValue = (date: dayjs.Dayjs | null, hasDate: boolean | null) =>{
        if(hasDate === null){
            return "Incomplete"
        }else if (hasDate === false ){
            return "Not Applicable"
        }else {
            return date ? date.format('M/DD/YYYY') : "Invalid state please reach  out to support"
        }
    }

    const getRenewalTerm = (text: string | null | undefined) =>{
        if(text){
            if(text === "NA"){
                return "Not Applicable"
            }else {
                return text.toLowerCase().replace("_", " ")
            }
        }
    }
    const [isMinimized, setIsMinimized] = useState(false);

    const handleToggle = () => {
        setIsMinimized(prevState => !prevState);
    };

    function stringToColor(string: string) {
        const colorPairs = [
            { light: '#FFB6C1', dark: '#FF69B4' },
            { light: '#FFD700', dark: '#FFC107' },
            { light: '#FF69B4', dark: '#C71585' },
            { light: '#ADFF2F', dark: '#7FFF00' },
            { light: '#FFA07A', dark: '#FF4500' },
            { light: '#20B2AA', dark: '#008B8B' },
            { light: '#87CEEB', dark: '#4682B4' },
            { light: '#FF6347', dark: '#CD5C5C' },
            { light: '#8A2BE2', dark: '#4B0082' },
            { light: '#00CED1', dark: '#008B8B' },
            { light: '#FF4500', dark: '#B22222' },
            { light: '#DA70D6', dark: '#BA55D3' },
            { light: '#7FFFD4', dark: '#40E0D0' },
            { light: '#B0C4DE', dark: '#4682B4' },
            { light: '#5F9EA0', dark: '#2F4F4F' },
            { light: '#FFDAB9', dark: '#FFA07A' },
            { light: '#DDA0DD', dark: '#9932CC' },
            { light: '#BDB76B', dark: '#808000' },
            { light: '#FFFACD', dark: '#FFD700' },
            { light: '#66CDAA', dark: '#20B2AA' },
        ];

        let hash = 0;
        for (let i = 0; i < string.length; i++) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        // Use the hash to select a color pair from the array
        const colorIndex = Math.abs(hash) % colorPairs.length;
        const selectedPair = colorPairs[colorIndex];

        // Create a linear gradient using the light and dark version of the selected color
        const gradient = `linear-gradient(90deg, ${selectedPair.light}, ${selectedPair.dark})`;

        return gradient;
    }

    function stringAvatar(name: string | undefined) {
        return {
            sx: {
                background: name ? stringToColor(name) : "lightgray", // Use background to apply gradient
                color: 'white',
                fontSize: 37, // Increase the font size
                width: 80, height: 80,

            },
            children: name? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`: '',
        };
    }


    const getServiceTypeLabel = (
        serviceType: string | null,
        serviceTypes: { value: string; label: string }[]
    ): string  => {
        if (!serviceType) {
            return "";
        }
        if (isCustomServiceType(serviceType)) {
            return serviceType;
        } else {
            return valueToLabel(serviceType, serviceTypes);
        }
    };

    const renderContent = () => {
        return (
            <Grid container sx={{   }}>
                <Grid item xs={12} md={isMinimized ? 12 : 8} component="main" sx={{ overflowY: 'auto', height: "90vh" }}>

                {editState === EditStates.NONE ? (
                        renderEditComponent()
                    ) : (
                        <Box >

                            {renderEditComponent()}
                        </Box>
                    )}
                </Grid>
                <Fade in={!isMinimized} timeout={400} mountOnEnter unmountOnExit >
                <Grid item xs={12} md={4} component="main" sx={{  position: 'sticky', top: 0, overflowY: 'auto', height: "90vh"}}>
                    <Paper onClick={handleVendorClick} elevation={0} sx={{  mx:1, cursor: 'pointer', p: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between',
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: 'rgba(176,176,176,0.69)',
                        '&:hover': {
                            backgroundColor: 'rgba(119,147,255,0.29)', // Change this to the desired hover color
                        }
                    }}>
                        <Box >
                            <Typography gutterBottom variant="h6" component="div" >
                                Supplier Details
                            </Typography>
                            <Typography>
                                {vendor?.vendorName}
                            </Typography>
                        </Box>
                        <Avatar
                            {...(stringAvatar(vendor?.vendorName) )
                        }

                        />
                    </Paper>
                     <br/>
                    <Paper elevation={0} sx={{ padding: "5px", mx:1,

                    }}>
                    <Grid>
                        <Grid  alignItems="center" justifyContent="space-between" sx={{  my: 1 } }>
                            <Grid item>
                                <Typography variant="h6" sx={{ pl: 1 }}>Edit Contract Details</Typography>
                            </Grid>
                        </Grid>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    {/* Contract Rows */}
                                    {[
                                        { label: 'Contract Name', value: contractName || 'Incomplete', onClick: handleEditContractName, isEdit: editState === EditStates.CONTRACT_NAME },
                                        { label: 'Supplier', value: vendor?.vendorName || 'Incomplete', onClick: handleEditVendor, isEdit: editState === EditStates.VENDOR },
                                        { label: 'Service Type', value:  getServiceTypeLabel(serviceType, serviceTypes), onClick: handleEditServiceType, isEdit: editState === EditStates.SERVICE_TYPE },
                                        { label: 'Scope of Work', value: trimText(scopeItems.scopeOfWork, 10), onClick: handleEditScopeOfWork, isEdit: editState === EditStates.SCOPE_OF_WORK },
                                        { label: 'Signature Date', value: getDateValue(closeDate, hasCloseDate), onClick: handleEditCloseDate, isEdit: editState === EditStates.CLOSE_DATE },
                                        { label: 'Effective Date', value: getDateValue(effectiveDate, hasEffectiveDate), onClick: handleEditEffectiveDate, isEdit: editState === EditStates.EFFECTIVE_DATE },
                                        { label: 'End Date', value: getDateValue(endDate, hasEndDate), onClick: handleEditEndDate, isEdit: editState === EditStates.END_DATE },
                                        { label: 'Renewal Terms', value: getRenewalTerm(renewalTerms?.term) || 'Incomplete', onClick: handleEditRenewalTerms, isEdit: editState === EditStates.RENEWAL_TERMS },
                                        { label: 'Net Terms', value: getNetTermLabel(paymentTerms) || 'Incomplete', onClick: handleEditPaymentTerms, isEdit: editState === EditStates.PAYMENT_TERMS },
                                        { label: 'Timeline', value: `${displayEvents.filter(event => !event.markedForDelete).length} Event(s)`, onClick: handleEditTimeline , isEdit: editState === EditStates.TIME_LINE },
                                        { label: 'Documents', value: `${attachments?.filter(attachment => !attachment.markedForDelete).length} Document(s)`, onClick: handleEditAttachments , isEdit: editState === EditStates.ATTACHMENTS },
                                        { label: 'Notes', value: `${notes?.filter(note => !note.markedForDelete).length} Note(s)`, onClick: handleEditNotes , isEdit: editState === EditStates.NOTES },
                                        { label: 'Total Contract Value', value: `$${contractValue}`, onClick: handleEditContractValue , isEdit: editState === EditStates.CONTRACT_VALUE },
                                        { label: 'Status', value: status ? valueToLabel(status, statusTypes) : 'Incomplete', onClick: handleEditStatus , isEdit: editState === EditStates.STATUS },
                                    ].map((row, index) => (
                                        <TableRow key={index} hover onClick={row.onClick} sx={{
                                            display: 'flex',
                                            flexDirection: { xs: 'column', sm: 'row' },
                                            justifyContent: 'space-between',
                                            opacity: disableContractFields(row.label) ? 0.5 : 1,
                                            pointerEvents: disableContractFields(row.label) ? 'none' : 'auto',
                                            backgroundColor: row.isEdit ?  'grey.100' : 'inherit', // Highlight row if isEdit is true
                                        }}>
                                            <TableCell sx={{ flex: 1, fontWeight: row.isEdit  ? 'bold' : 'normal' }}>{row.label}:</TableCell>
                                            <TableCell sx={{ flex: 1 }}><StatusChip status={row.value} /></TableCell>
                                            <TableCell align="right" sx={{ flex: 1 }}>
                                                <ArrowForwardIosIcon style={{ color: 'blue', fontSize: 'small' }} />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Snackbar
                            open={open}
                            autoHideDuration={12000} // Increased duration to 10000 milliseconds (10 seconds)
                            onClose={() => setOpen(false)}
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Centers the Snackbar
                        >
                            <Alert
                                onClose={() => setOpen(false)}
                                severity="error"
                                sx={{
                                    width: '100%',
                                    fontSize: '1.25rem', // Increase font size
                                }}
                            >
                                {errorMessage}
                            </Alert>
                        </Snackbar>
                    </Grid>
                    </Paper>
                </Grid>
                </Fade>
            </Grid>
        );

    };


    return (
        renderContent()

    );
};

export default ContractEdit;