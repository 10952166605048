import {CONTRACT_ENDPOINT} from "../interfaces/apiConstants";


export const generateThumbnail = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
        const response = await fetch(`${CONTRACT_ENDPOINT}/generate-thumbnail`, {
            method: 'POST',
            body: formData,
        });

        if (response.ok) {
            const blob = await response.blob();
            return URL.createObjectURL(blob);
        } else {
            console.error('Error generating thumbnail');
            return null;
        }
    } catch (error) {
        console.error('An error occurred while generating the thumbnail:', error);
        return null;
    }
};


export const handleParseDocument = async (file: string | Blob): Promise<void> => {
    try {
        const helloWorldResponse = await fetch('https://travismarket.com/chatgpt/');
        const helloWorldData = await helloWorldResponse.json();
        console.log('Hello World API response:', helloWorldData);
    } catch (error) {
        console.error('Error fetching Hello World API:', error);
        if (error instanceof Error) {
            alert('Error fetching Hello World API: ' + error.message);
        } else {
            alert('Error fetching Hello World API.');
        }
        return; // Stop further processing if the Hello World API call fails
    }

    const formData: FormData = new FormData();
    formData.append('file', file);
    console.log(formData)
    try {
        const response: Response = await fetch('https://travismarket.com/chatgpt/api/v1/contractai', {
            method: 'POST',
            body: formData,
        });

        if (response.ok) {
            const result = await response.json();
            console.log('Parsing successful:', result);

            return result
            alert('Document parsed successfully!');
        } else {
            throw new Error('Failed to parse the document.');
        }
    } catch (error) {
        console.error('Error during document parsing:', error);

        if (error instanceof Error) {
            alert('Error parsing document: ' + error.message);
        } else {
            alert('Error parsing document.');
        }
    }
};