import React, { useState, useEffect } from 'react';
import { Box, Button, Autocomplete, TextField, Stack, Typography, Checkbox, FormControlLabel } from "@mui/material";
import UnsavedChangesConfirmationModal from "./common/UnsavedChangesConfirmationModal";
import { valueToLabel } from "../utils/valueToLabel";
import {isCustomServiceType, serviceTypes} from "../constants/serviceTyps";
import {Prompt} from "react-router-dom";
import {prompText} from "../constants/prompText";

type ResponsiveDatePickersProps = {
    label: string;
    goBack: () => void;
    onDataChange: (newValue: string | null) => void;
    currentValue: string | null;
    selections: Array<{ value: string, label: string }>;
};

export default function InputAutocomplete({ label, goBack, onDataChange, currentValue, selections }: ResponsiveDatePickersProps) {
    const [isCustomService, setIsCustomService] = useState<boolean>(isCustomServiceType(currentValue));
    const currentServiceType = currentValue && !isCustomServiceType(currentValue) ? valueToLabel(currentValue, serviceTypes) : currentValue;
    const [standardServiceType, setStandardServiceType] = useState<string | null>(currentValue && !isCustomServiceType(currentValue) ? valueToLabel(currentValue, serviceTypes) : '');
    const [customServiceType, setCustomServiceType] = useState(isCustomServiceType(currentValue) ? currentValue  : '');
    const [isSaveAction, setIsSaveAction] = useState<boolean | null>(null); // Flag to indicate save/cancel

    // Update the local temporary data value
    const handleDataChange = (event: React.ChangeEvent<{}>, newValue: string | null) => {
        setStandardServiceType(newValue);
    };

    const handleCustomServiceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCustomServiceType(event.target.value);
    };

    const handleCustomServiceCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsCustomService(event.target.checked);
        if (!event.target.checked) {
            setCustomServiceType('');
        }
    };

    // Call the onDataChange callback with the temporary data value when "Save" is clicked
    const handleSave = () => {
        if (isCustomService) {
            onDataChange(customServiceType);
        } else {
            const selectedItem = selections.find(x => x.label === standardServiceType);
            if (selectedItem) {
                onDataChange(selectedItem.value);
            } else {
                onDataChange(null);
            }
        }
        setIsSaveAction(true);  // Mark as save action
    };

    const handleCancel = () => {
        setIsSaveAction(false);  // Mark as cancel action

    };

    // Effect to handle navigation after state update
    useEffect(() => {
        if (isSaveAction !== null) {
            goBack(); // Trigger goBack after state is set
        }
    }, [isSaveAction]);

    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            const serviceType = isCustomService ? customServiceType : standardServiceType;
            if (serviceType !== currentServiceType) {
                event.preventDefault();
                event.returnValue = ''; // Standard for browsers
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isCustomService, customServiceType, standardServiceType, currentServiceType]);


    const handlePrompt = (location: any) => {
        const shouldNavigate = window.confirm(prompText);
        if (!shouldNavigate) {
            setIsSaveAction(null); // Reset to null when user cancels the prompt
            return false; // Cancel navigation
        }
        return true; // Allow navigation
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '90vh'
            }}
        >
            <Stack spacing={2} alignItems="center">
                <Typography variant="h5">{label}</Typography>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isCustomService}
                            onChange={handleCustomServiceCheckboxChange}
                        />
                    }
                    label="Custom Service Type"
                />
                {!isCustomService ? (
                    <Autocomplete
                        style={{ minWidth: '300px' }}
                        value={standardServiceType}
                        onChange={handleDataChange}
                        options={selections.map((option) => option.label)}
                        renderInput={(params) => <TextField {...params} />}
                    />
                ) : (
                    <TextField
                        style={{ minWidth: '300px' }}
                        value={customServiceType}
                        onChange={handleCustomServiceChange}
                        placeholder="Enter custom service type"
                    />
                )}

                <Button style={{ width: '50%' }} variant="contained" onClick={handleSave}>Save</Button>
                <Button style={{ width: '50%' }} onClick={handleCancel}>Cancel</Button>
            </Stack>
            <Prompt
                when={(isCustomService ? customServiceType !== currentServiceType : standardServiceType !== currentServiceType) &&  isSaveAction !== true}
                message={handlePrompt }

            />
        </Box>
    );
}
