// UnsavedChangesConfirmationModal.tsx

import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

interface UnsavedChangesConfirmationModal {
    open: boolean;
    onConfirm: () => void;
    onCancel: () => void;
    message: string;
    confirmMessage: string;
}

const UnsavedChangesConfirmationModal: React.FC<UnsavedChangesConfirmationModal> = ({
                                                                                             open,
                                                                                             onConfirm,
                                                                                             onCancel,
                                                                                        message,
                                                                                        confirmMessage,


                                                                                         }) => {
    return (
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="unsaved-changes-title"
            aria-describedby="unsaved-changes-description"
        >
            <DialogTitle id="unsaved-changes-title">Unsaved Changes</DialogTitle>
            <DialogContent>
                <DialogContentText id="unsaved-changes-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary">
                    No, Go Back
                </Button>
                <Button onClick={onConfirm} color="primary" autoFocus>
                    {confirmMessage}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UnsavedChangesConfirmationModal;
