import React, {useEffect, useState} from 'react';
import { Box, Card, CardActions, CardContent, CardMedia, Button, Typography, Grid, Chip } from '@mui/material';
import StarRateIcon from '@mui/icons-material/StarRate';
import {VENDOR_ENDPOINT} from "../interfaces/apiConstants";
import {Vendor} from "../interfaces/vendor";
import {Vendor2} from "../interfaces/Vendor2";

// Mock data - replace with actual data from your application
interface Professional {
    id: number | null;
    name: string ;
    rating: number  | null;
    reviewCount: number  | null;
    location: string  | null;
    category: string  | null;
    distance: string  | null;
    bookings: number  | null;
    image: string  | null;
    quoteText: string  | null;
}

const professionals: Professional[] = [
    {
        "id": 1,
        "name": "Garage Company LLC",
        "rating": 4.96,
        "reviewCount": 85,
        "location": "Brooklyn, NY",
        "category": "Repair",
        "distance": "9 miles from Queens",
        "bookings": 181,
        "image": "garage_1.png", // Replace with actual image path
        "quoteText": "Book Garage Company LLC for commercial Garage replacement and installation, we are lic..."
    },
    {
        "id": 2,
        "name": "Garage Experts Inc.",
        "rating": 4.85,
        "reviewCount": 72,
        "location": "Manhattan, NY",
        "category": "Maintenance",
        "distance": "5 miles from Queens",
        "bookings": 215,
        "image": "garage_2.png", // Replace with actual image path
        "quoteText": "Choose Garage Experts Inc. for all your garage maintenance needs in Manhattan."
    },
    {
        "id": 3,
        "name": "Citywide Garage Solutions",
        "rating": 4.90,
        "reviewCount": 97,
        "location": "Queens, NY",
        "category": "Installation",
        "distance": "2 miles from Brooklyn",
        "bookings": 130,
        "image": "garage_3.png", // Replace with actual image path
        "quoteText": "Citywide Garage Solutions offers top-notch garage installation services in Queens."
    },
    {
        "id": 4,
        "name": "Brooklyn Garage Masters",
        "rating": 4.78,
        "reviewCount": 64,
        "location": "Bronx, NY",
        "category": "Repair",
        "distance": "7 miles from Manhattan",
        "bookings": 146,
        "image": "garage_4.png", // Replace with actual image path
        "quoteText": "For reliable garage repairs, trust Brooklyn Garage Masters to get the job done."
    },
    {
        "id": 5,
        "name": "Queens Garage Pros",
        "rating": 4.92,
        "reviewCount": 88,
        "location": "Queens, NY",
        "category": "Maintenance",
        "distance": "3 miles from Brooklyn",
        "bookings": 198,
        "image": "garage_5.png", // Replace with actual image path
        "quoteText": "Queens Garage Pros specializes in garage maintenance services for your convenience."
    },
    {
        "id": 6,
        "name": "Manhattan Garage Gurus",
        "rating": 4.89,
        "reviewCount": 79,
        "location": "Manhattan, NY",
        "category": "Installation",
        "distance": "6 miles from Queens",
        "bookings": 162,
        "image": "garage_6.png", // Replace with actual image path
        "quoteText": "Experience expert garage installations with Manhattan Garage Gurus."
    },
    {
        "id": 7,
        "name": "Bronx Garage Repairs",
        "rating": 4.81,
        "reviewCount": 69,
        "location": "Bronx, NY",
        "category": "Repair",
        "distance": "8 miles from Queens",
        "bookings": 175,
        "image": "garage_7.png", // Replace with actual image path
        "quoteText": "Bronx Garage Repairs is your go-to choice for garage repair services in the Bronx."
    },
    {
        "id": 8,
        "name": "Brooklyn Garage Installations",
        "rating": 4.94,
        "reviewCount": 93,
        "location": "Brooklyn, NY",
        "category": "Installation",
        "distance": "4 miles from Queens",
        "bookings": 203,
        "image": "garage_8.png", // Replace with actual image path
        "quoteText": "Upgrade your garage with professional installations by Brooklyn Garage Installations."
    }
]

interface ProfessionalCardProps {
    professional: Professional;
    briefView: boolean; // Add 'briefView' as an optional prop.
    handleSelect: (vendor: Vendor2) => void;

}

function ProfessionalCard({ professional, briefView, handleSelect }: ProfessionalCardProps) {
    // ... rest of your component logic


    const handleClick = (event: any) => {
        // Check if handleSelect is provided before calling
        console.log(professional.name)
        handleSelect({
            vendorName: professional.name,
            vendorId: professional.id
        }
        );
    };
    return (
        <Card
            sx={{
                minHeight: 200,
                maxWidth: 345,
                cursor: 'pointer', // Change cursor to pointer to indicate it's clickable
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.07)', // Or any color you prefer
                },
            }}
            onClick={handleClick} // Add the onClick handler here
        >

            <CardMedia
                component="img"
                height="150"
                image={'/' + professional.image}

            />

            <CardContent >
                <Typography gutterBottom variant="h5" component="div">
                    {professional.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {professional.location}
                </Typography>

                {briefView && ( <div>
                <Box display="flex" alignItems="center">
                    <StarRateIcon color="primary" />
                    <Typography variant="body2" color="text.secondary">
                        {professional.rating} ({professional.reviewCount} reviews)
                    </Typography>
                </Box>

                <Typography variant="body2" color="text.secondary">
                    {professional.category}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {professional.distance}
                </Typography>
                <Typography variant="body2">
                    {professional.bookings} Verified Bookings
                </Typography>
                <Typography variant="body2">
                    {professional.quoteText}
                </Typography>

                    </div>)}
            </CardContent>

            {briefView && ( <div>
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 1 }}>
                <Button size="small">Watch</Button>
                <Button size="small" variant="contained" color="primary">
                    Invite
                </Button>
            </Box>
            </div>
            )}
        </Card>
    );
}

export function convertVendorToProfessional(vendor: Vendor2): Professional {
    // The example below assumes that you have a method to generate an ID,
    // and you have default values for the `rating`, `reviewCount`, `bookings` etc.
    // You will need to adjust this according to your actual requirements.
    return {
        id: vendor.vendorId, // This function should generate a unique ID.
        name: vendor.vendorName ,
        rating: null, // Assuming there is no direct mapping for this property.
        reviewCount: null, // Assuming there is no direct mapping for this property.
        location: '', //`${vendor.city}, ${vendor.state}`, // Concatenating city and state for location.
        category: '', //vendor.typeOfBusiness, // Assuming typeOfBusiness maps to category.
        distance: null, // Assuming there is no direct mapping for this property.
        bookings: null, // Assuming there is no direct mapping for this property.
        image: 'default_vendor.png', // Assuming there is no direct mapping for this property.
        quoteText: '', //vendor.businessDescription, // Assuming businessDescription maps to quoteText.
    };
}

function generateUniqueKeyForVendor(vendor: Vendor): string {
    // Assuming vendors have a unique email, otherwise, you may need to use or generate another unique identifier
    return vendor.email;
}

// You would need to create a generateId function or provide an id from some other means.
function generateId(): number {
    // This is a placeholder. Replace with your actual ID generation logic.
    return Math.floor(Math.random() * 1000000);
}
interface ProfessionalsListProps {
    short: boolean; // This is an optional prop, add 'briefView' to your component props.
    handleSelect: (vendor: Vendor2) => void;
    searchTerm: string
}

interface VendorResponse {
    id: number;
    businessName: string;
    // ... include other properties from your response
}
function ProfessionalsList({ short, handleSelect, searchTerm }: ProfessionalsListProps) {
    const [vendors, setVendors] = useState<Vendor2[]>([]);
    const fetchVendors = async () => {
        try {
            const response = await fetch(`${VENDOR_ENDPOINT}`);
            const fetchedVendors: VendorResponse[] = await response.json();

            // Convert your fetched vendors to the Vendor type expected by the ProfessionalCard
            const vendorsToDisplay: Vendor2[] = fetchedVendors.map(vendorResponse => ({
                vendorId: vendorResponse.id,
                vendorName: vendorResponse.businessName,
                // ... map other fields as necessary
            }));

            setVendors(vendorsToDisplay);
        } catch (error) {
            console.error('Failed to fetch vendors:', error);
        }
    };
    useEffect(() => {
        fetchVendors();
    }, []);

    const onDataChange = (newData: any) => {
        fetchVendors()
    };

    const filteredVendors =  vendors.filter(vendor =>
        vendor.vendorName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                {!short &&
                    filteredVendors.map((vendor: Vendor2) => (
                    // Implicit return used here by replacing the curly braces with parentheses
                  <Grid item xs={12} sm={6} md={4} key={vendor.vendorId}>
                        <ProfessionalCard handleSelect={handleSelect} briefView={short} professional={convertVendorToProfessional(vendor)} />
                    </Grid>
                ))}
                {/*professionals.map((professional) => (
                    <Grid item xs={12} sm={6} md={4} key={professional.id}>
                        <ProfessionalCard handleSelect={handleSelect} briefView={short} professional={professional} />
                    </Grid>
                ))*/}

            </Grid>
        </Box>
    );

}

export default ProfessionalsList;
