




export const LOCAL_API_BASE_URL = 'http://localhost:8080/api';
export const REMOTE_API_BASE_URL = 'https://travismarket.com/app/api';

//const BASE_URL = REMOTE_API_BASE_URL;//LOCAL_API_BASE_URL; // Change this for running apis locally
const BASE_URL = REMOTE_API_BASE_URL;//LOCAL_API_BASE_URL; // Change this for running apis locally
// Endpoints
export const CONTRACT_ENDPOINT_PATH = '/contract';
export const VENDOR_ENDPOINT_PATH = '/vendor';
export const JOBPOST_ENDPOINT_PATH = '/jobposts';
export const ACCOUNT_ENDPOINT_PATH = '/account';
export const CONTRACT_ENDPOINT = `${BASE_URL}${CONTRACT_ENDPOINT_PATH}`
export const VENDOR_ENDPOINT = `${BASE_URL}${VENDOR_ENDPOINT_PATH}`
export const JOBPOST_ENDPOINT = `${BASE_URL}${JOBPOST_ENDPOINT_PATH}`
export const ACCOUNT_ENDPOINT = `${BASE_URL}${ACCOUNT_ENDPOINT_PATH}`
