import React, {useEffect, useState} from 'react';
import {TextField, Button, Container, Typography, Box, Paper, Grid} from '@mui/material';
import {EventSimulator, Simulate} from "react-dom/test-utils";
import input = Simulate.input;
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import {JOBPOST_ENDPOINT} from "../interfaces/apiConstants";

function JobPostForm() {
    const [formData, setFormData] = useState({
        jobTitle: '',
        location: '',
        projectOverview: '',
        scopeOfWork: '',
        talentRequirements: '',
        aiPrompt: ''
    });

    const fetchJobPost = async (input: string) => {
        try {
            const api = `${JOBPOST_ENDPOINT}/${input}`;
            //console.log(api)

            const response = await fetch(api);
            //console.log(response)

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            //console.log(data)

            setFormData({ // Assuming you want to update formData here
                ...formData,
                projectOverview: data.description,
                scopeOfWork: data.scope.join("\n"), // Join array elements if needed
                talentRequirements: data.requirements.join("\n") // Join array elements if needed
            });
        } catch (error) {
            console.error('Error fetching data: ', error);
        }
    };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleAutoFill = () => {
        fetchJobPost(formData.aiPrompt);
    };

    return (
        <div>

            {/*
            <Paper sx={{p:3, my: 1}}>
                <Grid container alignItems="center" justifyContent="center">
                    <Grid item xs={12} container justifyContent="center">
                        <Box
                            component="img"
                            sx={{
                                height: 120, // You can adjust the size as needed
                                width: 120,
                                maxHeight: { xs: 233, md: 167 },
                                maxWidth: { xs: 350, md: 250 },
                            }}
                            alt="Cute Wizard Logo"
                            src={`/wizard.png`}
                        />
                    </Grid>
                    <Grid item xs={12} container justifyContent="center">
                        <Typography variant="h4" component="h1" gutterBottom>
                            Try Travis!
                        </Typography>
                    </Grid>
                </Grid>

                <TextField
                    fullWidth
                    label="Ask Travis to create the job post for you!"
                    name="aiPrompt"
                    value={formData.aiPrompt}
                    onChange={handleChange}
                    multiline
                    rows={4}
                    margin="normal"
                />

                <Grid container justifyContent="right" style={{ marginTop: '20px' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAutoFill}
                        startIcon={<AutoFixHighIcon style={{ fontSize: 'large' }} />}
                    >
                        TRAVIS
                    </Button>
                </Grid>


            </Paper>
*/}

        <Paper sx={{p:3}}>
            <Container maxWidth="sm">
                <Typography variant="h4" component="h1" gutterBottom>
                    Job Post
                </Typography>
                <Box component="form" noValidate autoComplete="off">
                    <TextField
                        fullWidth
                        label="Job Title"
                        name="jobTitle"
                        value={formData.jobTitle}
                        onChange={handleChange}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label="Location"
                        name="location"
                        value={formData.location}
                        onChange={handleChange}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label="Project Overview"
                        name="projectOverview"
                        value={formData.projectOverview}
                        onChange={handleChange}
                        margin="normal"
                        multiline
                        rows={4}
                    />
                    <TextField
                        fullWidth
                        label="Scope of Work"
                        name="scopeOfWork"
                        value={formData.scopeOfWork}
                        onChange={handleChange}
                        margin="normal"
                        multiline
                        rows={4}
                    />
                    <TextField
                        fullWidth
                        label="Talent Requirements"
                        name="talentRequirements"
                        value={formData.talentRequirements}
                        onChange={handleChange}
                        margin="normal"
                        multiline
                        rows={4}
                    />

                </Box>
            </Container>
        </Paper>
        </div>

    );
}

export default JobPostForm;
