import React, { createContext, useContext, useReducer, ReactNode, useEffect } from 'react';

// Define the account type
interface Account {
    id: number;
    accountName: string;
    corporateAccount: boolean; // Add the corporateAccount property
    // Add other relevant account properties if needed
}

// Define the shape of the context state
interface AccountState {
    account: Account | null;
    accountsUpdated: boolean; // New state to track account updates
}

// Action types
type Action =
    | { type: 'SET_ACCOUNT'; payload: Account }
    | { type: 'CLEAR_ACCOUNT' }
    | { type: 'TRIGGER_ACCOUNT_REFRESH' }; // New action for triggering a refresh

// Define the type for the context value
interface AccountContextValue {
    state: AccountState;
    dispatch: React.Dispatch<Action>;
    triggerAccountRefresh: () => void; // Method to trigger an account refresh
}

// Create the context with an initial undefined value
const AccountContext = createContext<AccountContextValue | undefined>(undefined);

// Account reducer
const accountReducer = (state: AccountState, action: Action): AccountState => {
    switch (action.type) {
        case 'SET_ACCOUNT':
            return { ...state, account: action.payload };
        case 'CLEAR_ACCOUNT':
            return { ...state, account: null };
        case 'TRIGGER_ACCOUNT_REFRESH':
            return { ...state, accountsUpdated: !state.accountsUpdated }; // Toggle to trigger useEffect
        default:
            return state;
    }
};

// AccountProvider component
export const AccountProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const initialState: AccountState = {
        account: JSON.parse(localStorage.getItem('account') || 'null'),
        accountsUpdated: false, // Initialize with false
    };

    const [state, dispatch] = useReducer(accountReducer, initialState);

    // Method to trigger an account refresh
    const triggerAccountRefresh = () => {
        console.log("Triggered for real")
        dispatch({ type: 'TRIGGER_ACCOUNT_REFRESH' });
    };

    useEffect(() => {
        if (state.account) {
            localStorage.setItem('account', JSON.stringify(state.account));
        } else {
            localStorage.removeItem('account');
        }
    }, [state.account]);

    return (
        <AccountContext.Provider value={{ state, dispatch, triggerAccountRefresh }}>
            {children}
        </AccountContext.Provider>
    );
};

// Custom hook to use the account context
export const useAccount = () => {
    const context = useContext(AccountContext);
    if (!context) {
        throw new Error('useAccount must be used within an AccountProvider');
    }
    return context;
};
