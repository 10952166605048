import React from 'react';
import {
    Box,
    Button,
    Container,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography,
    InputLabel,
    FormControl, Paper
} from '@mui/material';
import {BusinessInformation, RealEstateBusinessProperties} from "../interfaces/vendor";


type ScheduledPaymentsRowEditProps = {
    goBack: () => void;
    onDataChange: (data: any) => void;
};


const BusinessPageForm = ({goBack, onDataChange }: ScheduledPaymentsRowEditProps) => {


    const [businessInfo, setBusinessInfo] = React.useState<BusinessInformation>({
        numberOfUnits: null,
        numberOfProperties: null,
        businessName: '',
        typeOfBusiness: '',
        businessWebsite: '',
        entityName: ''
    });

    const handleChange = (event: { target: { name: any; value: any; }; }) => {
        const { name, value } = event.target;
        setBusinessInfo({ ...businessInfo, [name]: value });
    };


    const handleSubmit = (event: any) => {

        //console.log(businessInfo);
        onDataChange(businessInfo)
        goBack();
        // Implement form submission logic here
    };

    const handleCancel = (e: any) => {
        goBack();
        // Implement form submission logic here
    };

    return (

        <Container maxWidth="sm"> {/* Adjust the maxWidth as needed */}
            <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
                    Business Information
                </Typography>
                <form onSubmit={handleSubmit} style={{ width: '100%' }}> {/* fullWidth form */}
                    <Grid container spacing={2}>
                        {/* Your Grid items go here */}
                        {/* Example Grid item */}
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="businessName"
                                name="businessName"
                                label="Business Name"
                                value={businessInfo.businessName}
                                onChange={handleChange}
                            />
                        </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="type-of-business-label">Type of business</InputLabel>
                            <Select
                                labelId="type-of-business-label"
                                id="typeOfBusiness"
                                name="typeOfBusiness"
                                value={businessInfo.typeOfBusiness}
                                label="Type of business"
                                onChange={handleChange}
                            >
                                <MenuItem value="RealEstateServices">Real Estate Services</MenuItem>
                                <MenuItem value="BuildingAndConstruction">Building and Construction</MenuItem>
                                <MenuItem value="Accommodation">Accommodation</MenuItem>
                                <MenuItem value="FoodAndBeverageServices">Food and Beverage Services</MenuItem>
                                <MenuItem value="TravelAndTourism">Travel and Tourism</MenuItem>
                                <MenuItem value="EventManagement">Event Management</MenuItem>
                                <MenuItem value="PropertyMaintenance">Property Maintenance</MenuItem>
                                {/* Add more options as needed */}
                            </Select>
                        </FormControl>
                    </Grid>

                    {businessInfo.typeOfBusiness === 'RealEstateServices' && (
                        <>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="numberOfUnits"
                                    name="numberOfUnits"
                                    label="Number of Units"
                                    value={businessInfo.numberOfUnits}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="numberOfProperties"
                                    name="numberOfProperties"
                                    label="Number of Properties"
                                    value={businessInfo.numberOfProperties}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </>
                    )}

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="entityName"
                            name="entityName"
                            label="Entity Name"
                            value={businessInfo.entityName}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="businessWebsite"
                            name="businessWebsite"
                            label="Business Website"
                            value={businessInfo.businessWebsite}
                            onChange={handleChange}
                        />
                    </Grid>
                    </Grid>
                    <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ mt: 2 }}>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                fullWidth // Makes the button stretch to full width
                            >
                                Submit
                            </Button>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 1 }}> {/* Add margin-top for spacing between buttons */}
                            <Button
                                onClick={handleCancel} // Change this to handleCancel or similar for actual cancel behavior
                                fullWidth // Makes the button stretch to full width
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Container>
    );
}

export default BusinessPageForm;
