import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    InputAdornment,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {prompText} from "../constants/prompText";
import {Prompt} from "react-router-dom";

type InputSelectWithOverrideProps = {
    label: string;
    goBack: () => void;
    onDataChange: (newValue: string | null) => void;
    currentValue: string | null;
    selections: Array<{ value: string, label: string }>;
};

export default function InputSelectWithOverride({
                                                    label,
                                                    goBack,
                                                    onDataChange,
                                                    currentValue,
                                                    selections,
                                                }: InputSelectWithOverrideProps) {
    const defaultToCustom = () => {
        if (currentValue === "NA") {
            return false;
        }
        return !(selections.some(item => item.value === currentValue));
    };

    // Initialize states
    const [isCustom, setIsCustom] = useState<boolean>(defaultToCustom());
    const [hasNetTerms, setHasNetTerms] = useState<boolean>(currentValue === "NA"); // Default to unchecked
    const [isSaveAction, setIsSaveAction] = useState<boolean | null>(null); // Flag to indicate save/cancel

    const [tempData, setTempData] = useState<string | null>(
        currentValue ? defaultToCustom() ? currentValue.split("_")[1] : currentValue : null
    );

    const handleDataChange = (event: SelectChangeEvent<string>) => {
        setTempData(event.target.value as string);
    };

    const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTempData(event.target.value);
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsCustom(event.target.checked);
        if (!event.target.checked) {
            setTempData(currentValue); // Reset to currentValue when unchecking custom
        } else {
            setTempData(null);
        }
    };

    const handleNetTermsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHasNetTerms(event.target.checked);
        if (event.target.checked) {
            // Reset to not custom when net terms is checked
            setIsCustom(false);
            setTempData(null);
        }
    };


    const saveValue = () => {
        if (hasNetTerms) {
            return "NA";
        } else if (isCustom) {
            return tempData ? "NET_" + tempData : null;
        } else {
            return tempData;
        }
    };
    const handleSave = () => {
        onDataChange(saveValue());
        setIsSaveAction(true);  // Mark as save action
    };


    const handleCancel = () => {
        setIsSaveAction(false);  // Mark as cancel action

    };

    // Effect to handle navigation after state update
    useEffect(() => {
        if (isSaveAction !== null) {
            goBack(); // Trigger goBack after state is set
        }
    }, [isSaveAction]);

    const handlePrompt = (location: any) => {
        const shouldNavigate = window.confirm(prompText);
        if (!shouldNavigate) {
            setIsSaveAction(null); // Reset to null when user cancels the prompt
            return false; // Cancel navigation
        }
        return true; // Allow navigation
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '90vh'
            }}
        >
            <Stack spacing={2} alignItems="center">
                <Typography variant="h5" align="center">{label}</Typography>

                {/* Net Terms Checkbox */}
                <FormControlLabel
                    control={<Checkbox checked={hasNetTerms} onChange={handleNetTermsChange} />}
                    label="Net terms is not applicable"
                />

                {!hasNetTerms && (
                    <>
                        {/* Custom Checkbox */}
                        <FormControlLabel
                            control={<Checkbox checked={isCustom} onChange={handleCheckboxChange} />}
                            label="Custom Terms"
                        />

                        {isCustom ? (
                            <TextField
                                type="number"
                                value={isCustom ? tempData : ''}
                                onChange={handleNumberChange}
                                sx={{ minWidth: '200px' }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">Net </InputAdornment>,
                                }}
                            />
                        ) : (
                            <Select
                                value={!isCustom ? tempData ? tempData : '' : ''}
                                onChange={handleDataChange}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                sx={{ minWidth: '200px' }}
                            >
                                {selections.map((item) => (
                                    <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                ))}
                            </Select>
                        )}
                    </>
                )}

                <Button variant="contained" onClick={handleSave} sx={{ width: '50%' }}>Save</Button>
                <Button onClick={handleCancel} sx={{ width: '50%' }}>Cancel</Button>
                <Prompt
                    when={currentValue !== saveValue() &&  isSaveAction !== true}
                    message={handlePrompt}

                />

            </Stack>
        </Box>
    );
}
