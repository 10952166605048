import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    IconButton,
    Menu,
    MenuItem,
    Container,
    CircularProgress,
    Button,
    Grid,
    Card,
    CardMedia,
    Paper,
    Divider, CardContent, Fade, Pagination
} from '@mui/material';
import { CONTRACT_ENDPOINT } from "../interfaces/apiConstants";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone';
import OpenInNewTwoToneIcon from '@mui/icons-material/OpenInNewTwoTone';
import { Attachment } from "../interfaces/attachment";
import { documentStepRadioOptions } from "../constants/documentStepRadioOptions";
import { labelToValue, valueToLabel } from "../utils/valueToLabel";
import { useUser } from "./UserProvider";
import {Document, Page} from "react-pdf";
import MinimizeIcon from "@mui/icons-material/Minimize";
import {Maximize} from "@mui/icons-material";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import Ribbon from "./Ribbon";
import ServiceAgreementModal from "./ServiceAgreementModal";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import DeleteIcon from '@mui/icons-material/Delete';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {deleteAttachmentApi} from "../apis/fetchContracts";
interface AttachmentsPageProps {
    currentValue: Attachment[] | null;
    contractPayload: any | null;
    editable: boolean;
    goBack: () => void;
    onDataChange: ((attachments: Attachment[], contractPayload: any) => void) | null;
}
const DocumentsDashboard: React.FC<AttachmentsPageProps> = ({ currentValue, contractPayload, editable, goBack, onDataChange }) => {
    const [attachments, setAttachments] = useState<Attachment[]>([]);
    const [thumbnails, setThumbnails] = useState<Map<string, string>>(new Map());
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedAttachment, setSelectedAttachment] = useState<Attachment | null>(null);
    const [pdfUrl, setPdfUrl] = useState<string | null>(null);  // State to manage the PDF display
    const [loading, setLoading] = useState<boolean>(false);  // State to manage loading
    const [numPages, setNumPages] = useState<number | null>(null);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [isMaximized, setIsMaximized] = useState<boolean>(false);
    // Update attachments state when currentValue changes
    useEffect(() => {
        if (currentValue) {
            const updatedAttachments = currentValue.map(attachment => ({
                ...attachment,
            }));
            setAttachments(updatedAttachments);
        }
    }, [currentValue]);

    useEffect(() => {
        const fetchThumbnails = async () => {
            const newThumbnails = new Map<string, string>();

            for (const attachment of attachments) {
                if (attachment?.thumbnail) {
                    newThumbnails.set(attachment.id, attachment.thumbnail);
                } else {
                    try {
                        const response = await fetch(`${CONTRACT_ENDPOINT}/thumbnail/${attachment.id}`, {
                            method: 'GET',
                        });

                        if (response.ok) {
                            const blob = await response.blob();
                            const blobUrl = URL.createObjectURL(blob);
                            newThumbnails.set(attachment.id, blobUrl);
                        }
                    } catch (error) {
                        console.error('Error fetching thumbnail:', error);
                    }
                }
            }

            setThumbnails(newThumbnails);
        };
        fetchThumbnails();
    }, [attachments]);

    const handleOpenFile = async (attachmentId: string) => {
        const attachment = attachments.find(attachment => attachment.id === attachmentId);

        if (!attachment) {
            return;
        }

        setLoading(true);  // Start loading

        try {
            const fileUrl = `${CONTRACT_ENDPOINT}/file/${attachment.id}`;
            const response = await fetch(fileUrl, {
                method: 'GET',
            });

            if (!response.ok) throw new Error('Network response was not ok.');

            const blob = await response.blob();
            const blobUrl = window.URL.createObjectURL(blob);
            setPdfUrl(blobUrl);  // Set the PDF URL to display the PDF
            setSelectedAttachment(attachment);  // Store the selected attachment for displaying details
        } catch (error) {
            console.error('There was an error downloading the file:', error);
        } finally {
            setLoading(false);  // Stop loading
        }
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, attachment: Attachment) => {
        setAnchorEl(event.currentTarget);
        setSelectedAttachment(attachment);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedAttachment(null);
    };

    const handleDeSelectClick = () => {
        setPdfUrl(null);  // Close the PDF and show the cards again
        setSelectedAttachment(null);
    };
    interface LoadSuccess {
        numPages: number;
    }
    const onDocumentLoadSuccess = ({ numPages }: LoadSuccess) => {
        setNumPages(numPages);
        setPageNumber(1); // Reset to first page when a new document is loaded
    };

    // Group attachments by state
    const groupedAttachments = attachments.reduce((acc, attachment) => {
        if (!acc[attachment.state]) {
            acc[attachment.state] = [];
        }
        acc[attachment.state].push(attachment);
        return acc;
    }, {} as { [key: string]: Attachment[] });

    const { state: { user } } = useUser(); // Destructure to get user from state

    const toggleMaximize = () => {
        setIsMaximized(!isMaximized);
    };


    const [isServiceAgreementModalOpen, setIsServiceAgreementModalOpen] = useState(false); // State for the new modal
    const handleCancel = () => {
        goBack();
    };

    const handleReplace = () => {
        // Replace the current service agreement
        setIsServiceAgreementModalOpen(false);
    };

    const handleCreateNew = () => {
        setIsServiceAgreementModalOpen(true);
        // Archive the current version and create a new one
    };

    const handleSubmitAttachment = (contractPayload: any, newAttachment: Attachment) => {

        let updatedAttachments = attachments;
        updatedAttachments = [...updatedAttachments, newAttachment]
        if( onDataChange){
            onDataChange(updatedAttachments, contractPayload);
            setAttachments([...attachments, newAttachment]);
        }

    };

    const [isReplaceConfirmOpen, setIsReplaceConfirmOpen] = useState(false);
    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

    const handleReplaceClick = () => {
        setIsReplaceConfirmOpen(true);
    };

    const handleDeleteClick = () => {
        setIsDeleteConfirmOpen(true);
    };

    const handleReplaceConfirm = () => {
        // Logic to replace the document
        setIsReplaceConfirmOpen(false);
    };

    const handleDeleteConfirm = () => {
        if(selectedAttachment){
            deleteAttachmentApi(selectedAttachment.id).then(r => {
                handleDeSelectClick()
                setIsDeleteConfirmOpen(false);
            })
        }
        setIsDeleteConfirmOpen(false);
    };

    const renderRibbon = () => {
        const newSelection = (
            <Button onClick={handleDeSelectClick}>
                New Selection
            </Button>
        );

        const cancelButton = (
            <Button onClick={handleCancel}>
                Cancel
            </Button>
        );

        if (editable && !selectedAttachment) {
            return (
                <Ribbon
                    leftChildren={
                        <>
                            <Button onClick={handleCreateNew} >
                                Add Documents
                            </Button>
                        </>
                    }
                    rightChildren={cancelButton}
                />
            );
        }

        if (!editable && selectedAttachment) {
            return (
                <Ribbon
                    leftChildren={<></>}
                    rightChildren={newSelection}
                />
            );
        }

        if (editable && selectedAttachment) {
            return (
                <Ribbon
                    leftChildren={
                        <>
                            <Button onClick={handleReplaceClick} startIcon={<ChangeCircleIcon />}>
                                Replace
                            </Button>
                            <Button onClick={handleDeleteClick} color={'secondary'} startIcon={<DeleteIcon />}>
                                Delete
                            </Button>
                        </>
                    }
                    rightChildren={newSelection}
                />
            );
        }

        return null;
    };


    return (
        <Box sx={{ overflow: 'auto', p: 2 }}>
            {renderRibbon()}
            <br/>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </Box>
            ) : pdfUrl && selectedAttachment ? (
                <Fade in={!loading} timeout={400}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: isMaximized ? 'column' : 'row',
                            gap: 2,
                        }}
                    >
                        <Container sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Box
                                sx={{
                                    border: '1px solid #ddd',
                                    p: 2,
                                    pt: 5,
                                    width: isMaximized ? '100%' : 500,
                                    position: 'relative',
                                }}
                            >
                                <IconButton
                                    color="primary"
                                    sx={{ position: 'absolute', top: 0, right: 0 }}
                                    onClick={toggleMaximize}
                                >
                                    {isMaximized ? <OpenInFullIcon /> : <OpenInFullIcon />}
                                </IconButton>

                                <Document
                                    file={pdfUrl}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    loading={null}
                                    onLoadError={() => {}}
                                >
                                    {isMaximized ? (
                                        Array.from(new Array(numPages), (el, index) => (
                                            <Box key={`page_${index + 1}`}>
                                                <Page
                                                    pageNumber={index + 1}
                                                    width={800}
                                                    renderTextLayer={false}
                                                    renderAnnotationLayer={false}
                                                />
                                                {index + 1 !== numPages && ( // Avoid adding a divider after the last page
                                                    <Box sx={{
                                                        mt: 2,
                                                        mb: 2,
                                                        borderBottom: '2px solid #000', // Making the divider more prominent
                                                        textAlign: 'center', // Centering the page number
                                                        color: 'grey',
                                                        fontSize: '0.8rem', // Adjust size as needed
                                                    }}>
                                                        <Typography variant="body2">End of Page {index + 1}</Typography>
                                                    </Box>
                                                )}
                                            </Box>
                                        ))
                                    ) : (
                                        <Page
                                            width={500}
                                            pageNumber={pageNumber}
                                            renderTextLayer={false}
                                            renderAnnotationLayer={false}
                                        />
                                    )}
                                </Document>

                                {numPages && !isMaximized && (
                                    <Pagination
                                        count={numPages}
                                        page={pageNumber}
                                        onChange={(event, page) => setPageNumber(page)}
                                        sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
                                    />
                                )}
                            </Box>
                        </Container>

                        {!isMaximized && (
                            <Box
                                sx={{
                                    p: 2,
                                    width: 600,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start', // Align items to the start (top) horizontally
                                    justifyContent: 'flex-start', // Align items to the start (top) vertically
                                }}
                            >
                                <Typography variant="h6" align="center">Document Details</Typography>
                                <br/>
                                <Typography variant="body1" align="center">
                                    <strong>Name:</strong> {selectedAttachment?.name}
                                </Typography>

                            </Box>
                        )}
                    </Box>
                </Fade>
            ) : (
                <Box sx={{ px: 3, mt: 1, mb: 3 }}>
                    {Object.keys(groupedAttachments).map((state, index) => (
                        <React.Fragment key={state}>
                            <Container maxWidth="lg" sx={{ px: 2 }}>
                                <Typography variant="h6" gutterBottom sx={{ mt: index > 0 ? 4 : 0 }}>
                                    {valueToLabel(state, documentStepRadioOptions)}
                                </Typography>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3 }}>
                                    {groupedAttachments[state].map((attachment) => (
                                        <Box key={attachment.id} sx={{ position: 'relative', width: 200 }}>
                                            <Card
                                                sx={{
                                                    cursor: 'pointer',
                                                    height: 300,
                                                    width: '100%',
                                                    border: '2px solid transparent',
                                                    '&:hover': {
                                                        borderColor: 'primary.main',
                                                    },
                                                }}
                                                onClick={() => handleOpenFile(attachment.id)}
                                            >
                                                {thumbnails.get(attachment.id) ? (
                                                    <CardMedia
                                                        component="img"
                                                        image={thumbnails.get(attachment.id)}
                                                        alt={attachment.name}
                                                        sx={{ height: 220, width: '100%', mt: 1 }}
                                                    />
                                                ) : (
                                                    <Paper sx={{ height: 220, backgroundColor: '#f0f0f0' }} elevation={0} />
                                                )}
                                                <Divider />
                                                <CardContent sx={{ ml: -1 }}>
                                                    <Typography variant="body1" noWrap>{attachment.name}</Typography>
                                                    <IconButton
                                                        size="small"
                                                        onClick={(e) => handleMenuOpen(e, attachment)}
                                                        sx={{ position: 'absolute', bottom: 30, right: -10 }}
                                                    >
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                </CardContent>
                                            </Card>
                                        </Box>
                                    ))}
                                </Box>
                            </Container>
                        </React.Fragment>
                    ))}
                </Box>
            )}

            <ServiceAgreementModal
                attachments={attachments}
                open={isServiceAgreementModalOpen}
                onClose={() => setIsServiceAgreementModalOpen(false)}
                currentContractPayload={contractPayload}
                handleSubmit={handleSubmitAttachment}
                newContract={false}
            />
            <Dialog
                open={isReplaceConfirmOpen}
                onClose={() => setIsReplaceConfirmOpen(false)}
                aria-labelledby="replace-confirmation-dialog"
            >
                <DialogTitle id="replace-confirmation-dialog">Replace Document</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to replace this document?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsReplaceConfirmOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleReplaceConfirm} color="primary" autoFocus>
                        Replace
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isDeleteConfirmOpen}
                onClose={() => setIsDeleteConfirmOpen(false)}
                aria-labelledby="delete-confirmation-dialog"
            >
                <DialogTitle id="delete-confirmation-dialog">Delete Document</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this document? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsDeleteConfirmOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="secondary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default DocumentsDashboard;
