import React, { useState, useEffect } from 'react';
import {
    Box,
    TextField,
    IconButton,
    List,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Divider,
    Fade,
    Button,
    Grid, Tooltip
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EventIcon from '@mui/icons-material/Event';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import generateNewId from '../utils/generateId';
import { TimelineEvent } from '../interfaces/TimeLineEvent';
import NotificationSettings from './NotificationSettings';
import dayjs from "dayjs";
import EventBusyIcon from '@mui/icons-material/EventBusy';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import Filter1Icon from '@mui/icons-material/Filter1';
import Ribbon from "./Ribbon";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

interface TimelineProps {
    goBack: () => void;
    onDataChange: (contractTimeLineEvents: TimelineEvent[]) => void;
    currentValue: TimelineEvent[] ;
    editable: boolean;
    hasCloseDate: boolean | null;
    hasEndDate: boolean | null;
    hasEffectiveDate: boolean | null;
    title: string;
}

const TimelineList: React.FC<TimelineProps> = ({
                                                   currentValue,
                                                   goBack,
                                                   onDataChange,
                                                   editable,
                                                   hasCloseDate,
                                                   hasEndDate,
                                                   hasEffectiveDate,
                                                   title,
                                               }) => {

    const [rows, setRows] = useState<TimelineEvent[]>(currentValue);
    const [editMode, setEditMode] = useState<{ [id: string]: boolean }>(rows.reduce((acc, row) => ({ ...acc, [row.id]: false }), {}));
    const [expandedIndex, setExpandedIndex] = useState<string | null>(null);

    const handleAddEvent = () => {
        const id = generateNewId();
        setRows((oldRows) => [
            {
                id,
                eventDate: new Date(),
                event: '',
                eventType: '',
                markedForDelete: false,
                notify: true,
                dailyNotificationXDaysBefore: 2,
                weeklyNotificationXDaysBefore: 0,
                monthlyNotificationXDaysBefore: 0,
            },
            ...oldRows,
        ]);
        setEditMode((prev) => ({ ...prev, [id]: true })); // Auto-enable edit mode for the new event
    };

    const handleInputChange = <T extends keyof TimelineEvent>(id: string, field: T, value: TimelineEvent[T]) => {
        setRows((prevRows) => {
            const updatedRows = [...prevRows];
            const index = updatedRows.findIndex(row => row.id === id);
            updatedRows[index] = { ...updatedRows[index], [field]: value };
            return updatedRows;
        });
    };

    const handleDeleteEvent = (id: string) => {
        setRows((prevRows) => prevRows.map(row => row.id === id ? { ...row, markedForDelete: true } : row));
    };

    const toggleEditMode = (id: string) => {
        setEditMode((prev) => ({ ...prev, [id]: !prev[id] }));
    };

    const getIconForEventType = (eventType: string) => {
        switch (eventType) {
            case 'Signature Date':
                return <DriveFileRenameOutlineIcon color="primary" />;
            case 'End Date':
                return <EventBusyIcon color="secondary" />;
            case 'Effective Date':
                return <Filter1Icon color="secondary" />;
            case 'Renewal Date':
                return <AutorenewIcon />;
            default:
                return <EventIcon />;
        }
    };

    const isDefaultDate = (eventType: string) => {
        switch (eventType) {
            case 'Signature Date':
            case 'End Date':
            case 'Effective Date':
            case 'Renewal Date':
                return true;
            default:
                return false;
        }
    };

    const handleAccordionChange = (id: string) => {
        setExpandedIndex(expandedIndex === id ? null : id);
    };

    const displayRows = rows
        .filter(row => !row.markedForDelete)
        .filter(event => {
            return !(event.event === "Signature Date" && (hasCloseDate === false || hasCloseDate === null));
        }).filter(event => {
            return !(event.event === "End Date" && (hasEndDate === false || hasEndDate === null));
        }).filter(event => {
            return !(event.event === "Effective Date" && (hasEffectiveDate === false || hasEffectiveDate === null));
        })
        .sort((a, b) => new Date(b.eventDate).getTime() - new Date(a.eventDate).getTime()); // Sort by date

    const handleCancel = () => {
        goBack();
    };

    const handleSubmit = () => {
        onDataChange(rows);
        goBack();
    };

    return (
        <Box sx={{ overflow: 'auto', p: 2 }}>
            {editable && (<Ribbon
                leftChildren={
                    <Button onClick={handleAddEvent} startIcon={<AddTwoToneIcon />}>
                        Add Event
                    </Button>
                }
                rightChildren={
                    <>
                        <Button variant="contained" color="primary" onClick={handleSubmit}>
                            Save
                        </Button>
                        <Button onClick={handleCancel}>
                            Cancel
                        </Button>
                    </>
                }
            />)}
            <>
            <List>

                {displayRows.map((row, index) => (
                    <Accordion key={row.id} expanded={expandedIndex === row.id} onChange={() => handleAccordionChange(row.id)}
                       elevation={0}
                       sx={{
                           padding: "5px",
                           borderWidth: 1,
                           borderStyle: 'solid',
                           borderColor: 'rgba(176,176,176,0.6)',
                           borderTop: '1px solid rgba(176,176,176,0.6)', // Always show border at the top
                           borderLeft: '1px solid rgba(176,176,176,0.6)', // Always show border on the left
                           borderRight: '1px solid rgba(176,176,176,0.6)', // Always show border on the right
                           borderBottom: index === displayRows.length - 1 ? '1px solid rgba(176,176,176,0.6)' : 'none', // Only show bottom border for the last row
                       }}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Grid container alignItems="center" spacing={2} sx={{ width: '100%' }}>
                                <Grid item xs={3}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        {getIconForEventType(row.event)}
                                        <Typography sx={{ ml: 2 }}>{row.event || ''}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={9}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Typography>{dayjs.utc(row.eventDate).format('ddd, MMM DD, YYYY')}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <Fade in={expandedIndex === row.id}>
                            <AccordionDetails>
                                <Divider sx={{ mb: 2 }} />
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    {editMode[row.id] ? (
                                        <Box sx={{ flex: 1 }}>
                                            {/* Editable Fields */}
                                            <TextField
                                                label="Event Name"
                                                value={row.event}
                                                onChange={(e) => handleInputChange(row.id, 'event', e.target.value)}
                                                fullWidth
                                                margin="normal"
                                            />
                                            <TextField
                                                label="Event Date"
                                                type="date"
                                                value={dayjs.utc(row.eventDate).format('YYYY-MM-DD')}
                                                onChange={(e) => handleInputChange(row.id, 'eventDate', dayjs.utc(e.target.value).toDate())}
                                                fullWidth
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                            <NotificationSettings
                                                readOnly={false}
                                                dailyNotificationXDaysBefore={row.dailyNotificationXDaysBefore}
                                                weeklyNotificationXDaysBefore={row.weeklyNotificationXDaysBefore}
                                                monthlyNotificationXDaysBefore={row.monthlyNotificationXDaysBefore}
                                                onChange={(settings) => {
                                                    handleInputChange(row.id, 'dailyNotificationXDaysBefore', settings.dailyNotificationXDaysBefore);
                                                    handleInputChange(row.id, 'weeklyNotificationXDaysBefore', settings.weeklyNotificationXDaysBefore);
                                                    handleInputChange(row.id, 'monthlyNotificationXDaysBefore', settings.monthlyNotificationXDaysBefore);
                                                }}
                                            />
                                        </Box>
                                    ) : (
                                        <Box sx={{ flex: 1 }}>
                                            {/* Non-Editable View */}
                                            <Grid container spacing={1} sx={{ width: '100%', mb: 1 }}>
                                                <Grid item xs={3}>
                                                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Event:</Typography>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Typography variant="body1">{row.event}</Typography>
                                                </Grid>

                                                <Grid item xs={3}>
                                                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Date:</Typography>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Typography variant="body1">{dayjs.utc(row.eventDate).format('YYYY-MM-DD')}</Typography>
                                                </Grid>
                                            </Grid>
                                            <NotificationSettings
                                                readOnly={true}
                                                dailyNotificationXDaysBefore={row.dailyNotificationXDaysBefore}
                                                weeklyNotificationXDaysBefore={row.weeklyNotificationXDaysBefore}
                                                monthlyNotificationXDaysBefore={row.monthlyNotificationXDaysBefore}
                                                onChange={(settings) => {
                                                    handleInputChange(row.id, 'dailyNotificationXDaysBefore', settings.dailyNotificationXDaysBefore);
                                                    handleInputChange(row.id, 'weeklyNotificationXDaysBefore', settings.weeklyNotificationXDaysBefore);
                                                    handleInputChange(row.id, 'monthlyNotificationXDaysBefore', settings.monthlyNotificationXDaysBefore);
                                                }}
                                            />
                                        </Box>
                                    )}
                                    {editable && (<Box sx={{ display: 'flex', alignItems: 'center', pl: 2 }}>
                                        <IconButton edge="end" onClick={() => toggleEditMode(row.id)} disabled={isDefaultDate(row.event)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteEvent(row.id)} disabled={isDefaultDate(row.event)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>)}
                                </Box>
                            </AccordionDetails>
                        </Fade>
                    </Accordion>
                ))}
            </List>
            </>

        </Box>
    );
};

export default TimelineList;
