import React, {useState} from 'react';
import {
    Box,
    Card,
    CardContent,
    CardActions,
    Button,
    Typography,
    TextField,
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Grid,
    Paper,
    Divider,
    Stack,
    IconButton, StepButton
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import SendIcon from '@mui/icons-material/Send';
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ListItemIcon from "@mui/material/ListItemIcon";
import MessageItem from "./Messages";
import ContractEdit from "./ContractEdit";
import Stepper from "@mui/material/Stepper";
import HorizontalLinearStepper from "./Stepper";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import ProfessionalsList from "./VendorsSearchResult";
import ProposalCompare from "./ProposalCompare";
import CloseIcon from '@mui/icons-material/Close';
import MinimizeIcon from '@mui/icons-material/Minimize';
import ContractNegotiations from "./ContractNegotiations";

// Define the vendor and event info data structures
interface VendorInfo {
    name: string;
    location: string;
    rating: number;
    reviewCount: number;
    imageUrl: string;
}

interface EventInfo {
    date: string;
    time: string;
    location: string;
}


const vendorInfo: VendorInfo = {
    name: 'Property Manager LLC',
    location: 'New York City, NY',
    rating: 4.96,
    reviewCount: 6,
    imageUrl: '/garage_15346.png', // Replace with the path to the vendor's image
};

const eventInfo: EventInfo = {
    date: 'Sat, August 5, 2023',
    time: '7:30 PM – 8:30 PM (1 hour)',
    location: '199 Bowery, New York, NY 10002',
};
const steps = ['View Job Post', 'Invite Talent', 'Review Proposals', 'Contract Negotiation', 'Accept'];
function MessagingInterface() {

    const [activeStep, setActiveStep] = React.useState(0);

    const listItems = [
        "Design and install a complete, modern garage system suitable for the commercial building.",
        "Ensure the system meets the specific needs of the building, including space optimization, security features, and user accessibility.",
        "Adhere to all local building codes, regulations, and safety standards.",
        "Complete the project within the set timeline and budget.",
    ];

    const vendorItems = [
        "Must have significant experience in commercial garage installations.",
        "Should possess all necessary licenses and insurance.",
        "Must provide references from previous similar projects.",
        "Ability to work collaboratively with our management team.",
        "Strong commitment to safety and quality workmanship."
    ];

    const handleStep = (step: number) => () => {
        setActiveStep(step);
        if(step === 2){
            handleRefresh()
        }
        if(step === 3){
            setActiveNegotiation(-1)
        }
    };

    const [activeProposal, setActiveProposal] = React.useState(-1);
    const [  activeNegotiation, setActiveNegotiation ] = React.useState(-1);
    const handleRefresh = () => {
        setActiveProposal(-1); // Reset activeProposal
        // Additional logic for refreshing ProposalCompare
    };

    return (
        <div>
            <Paper sx={{ mb: 1, p: 2 }} >
                <Typography variant="h4" sx={{ mb: 2 }}>
                    Install a Garage in Building XYZ
                </Typography>
                <Box sx={{ width: '100%' }}>
                    <Stepper nonLinear  activeStep={activeStep}>
                        {steps.map((label, index) => {
                            const stepProps: { completed?: boolean } = {};
                            const labelProps: {
                                optional?: React.ReactNode;
                            } = {};
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepButton color="inherit" onClick={handleStep(index)}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </StepButton>
                                </Step>
                            );
                        })}
                    </Stepper>
                </Box>
            </Paper>
            {activeStep === 0 && (<Paper sx={{ mb: 1, p: 2 }}>
                <Typography gutterBottom variant="h4" component="div">
                    Job Post
                </Typography>
                <List>
                    {/*<ListItem>
                                <ListItemIcon>
                                    <EventIcon />
                                </ListItemIcon>
                                <ListItemText primary={eventInfo.date} />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <AccessTimeIcon />
                                </ListItemIcon>
                                <ListItemText primary={eventInfo.time} />
                            </ListItem>*/}
                    <ListItem>
                        <ListItemIcon>
                            <LocationOnIcon />
                        </ListItemIcon>
                        <ListItemText primary={eventInfo.location} />
                    </ListItem>
                </List>
                <Typography gutterBottom variant="h5" component="div">
                    Project Overview
                </Typography>

                <Typography variant="body1" sx={{ mb: 2 }}>
                    We are a property management company currently overseeing a commercial building project. We are seeking experienced and reliable contractors to undertake the installation of comprehensive garage systems in our property. The ideal vendor should have a proven track record in successfully handling similar garage installation projects.
                </Typography>
                <Typography gutterBottom variant="h5" component="div">
                    Scope of Work
                </Typography>
                <List>
                    {listItems.map((item, index) => (
                        <ListItem key={index}>
                            <ListItemIcon>
                                <FiberManualRecordIcon sx={{ fontSize: '0.6rem' }} />

                            </ListItemIcon>
                            <Typography variant="body1">{item}</Typography>
                        </ListItem>
                    ))}
                </List>
                <Typography gutterBottom variant="h5" component="div">
                    Talent Requirements
                </Typography>
                <List>
                    {vendorItems.map((item, index) => (
                        <ListItem key={index}>
                            <ListItemIcon>
                                <FiberManualRecordIcon sx={{ fontSize: '0.6rem' }} />
                            </ListItemIcon>
                            <Typography variant="body1">{item}</Typography>
                        </ListItem>
                    ))}
                </List>


            </Paper>)}
            {activeStep === 1 && (<Paper sx={{ mb: 1, p: 2 }}>
                <Typography gutterBottom variant="h4" component="div">
                    Invite Talent
                </Typography>


                <TextField  fullWidth sx={{ mb: 2 }} label="Search" id="fullWidth" />


                <ProfessionalsList  handleSelect={()=>{}} searchTerm={""} short></ProfessionalsList>




            </Paper>)}
            {activeStep === 2 && (
                <div>
                    <ProposalCompare
                        activeProposal={activeProposal}
                        setActiveProposal={setActiveProposal}
                    />

                </div>

            )}

            {activeStep === 3 &&
            (<ContractNegotiations
                activeNegotiation={activeNegotiation}
                setActiveNegotiation={setActiveNegotiation}

            ></ContractNegotiations>)
            }

        </div>
    );
}

export default MessagingInterface;
