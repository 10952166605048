import React, {useState, ChangeEvent, useRef, useEffect} from 'react';
import {
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Typography,
    Grid,
    TablePagination,
    Accordion,
    AccordionActions,
    Stack,
    Container
} from '@mui/material';
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import {ScopeItem} from "../interfaces/scopeOfWork";
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import UnsavedChangesConfirmationModal from "./common/UnsavedChangesConfirmationModal";
import {Prompt} from "react-router-dom";
import {prompText} from "../constants/prompText";
interface ScopeOfWorkPageProps {
    goBack: () => void;
    onDataChange: (scopeItems: ScopeItem) => void;
    currentValue: ScopeItem | null;
}

const ScopeOfWorkPage: React.FC<ScopeOfWorkPageProps> = ({
                                                             goBack,
                                                             onDataChange,
                                                             currentValue,
                                                         }: ScopeOfWorkPageProps) => {
    const [scopeItems, setScopeItems] = useState<ScopeItem>(currentValue ? currentValue : {
        projectOverview: "",
        scopeOfWork: "",
        talentRequirements: "" // Optional property for a tag
    });
    const [isSaveAction, setIsSaveAction] = useState<boolean | null>(null); // Flag to indicate save/cancel

    const handleSubmit = () => {
        onDataChange(scopeItems)
        setIsSaveAction(true)
    };

    const handleTextChange = (field: keyof ScopeItem, newValue: string) => {
        setScopeItems((prevState) => ({
            ...prevState,
            [field]: newValue
        }));
    };

    const handleCancel = () =>{
        setIsSaveAction(false)
    }

    useEffect(() => {
        if (isSaveAction !== null) {
            goBack(); // Trigger goBack after state is set
        }
    }, [isSaveAction]);

    const handlePrompt = (location: any) => {
        const shouldNavigate = window.confirm(prompText);
        if (!shouldNavigate) {
            setIsSaveAction(null); // Reset to null when user cancels the prompt
            return false; // Cancel navigation
        }
        return true; // Allow navigation
    };

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
                height: '90vh',// Full view height
            }
            }
        >
            <br/>
            <Stack spacing={2} alignItems="center">
                <Typography variant="h5" sx={{ paddingLeft: '5px' }}>Scope of Work</Typography>

                <TextareaAutosize
                    minRows={8}
                    style={{ width: '600px' }} // Make sure the textarea takes up the full cell width
                    value={scopeItems.scopeOfWork}
                    onChange={(event) => {
                        handleTextChange("scopeOfWork", event.target.value);
                    }}
                />


                <Button style={{ width: '20%' }} variant="contained" onClick={handleSubmit}>Save</Button>
                <Button style={{ width: '20%' }} onClick={handleCancel}>Cancel</Button>
            </Stack>
            <Prompt
                when={currentValue?.scopeOfWork !== scopeItems.scopeOfWork && isSaveAction !== true}
                message={handlePrompt }
            />
        </Box>
    );
};

export default ScopeOfWorkPage;
