
export function valueToLabel(value: string, list: any[]): string {
    const item = list.find(item => item.value === value);

    //console.log("asdfasdffffffff");
    //console.log(list);
    //console.log(item)
    //console.log(value)


    return item ? item.label : '';
}

export function labelToValue(label: string, list: any[]): string {
    const item = list.find(item => item.label === label);
    return item ? item.value : '';
}