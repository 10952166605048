import React from 'react';
import { Breadcrumbs, Link } from '@mui/material';
import { useLocation } from 'react-router-dom';
import {useAccount} from "./provider/AccountProvider";
import {fetchContractData} from "../apis/fetchContracts";

interface BreadcrumbLinksProps {
    isMinimized: boolean;
    drawerWidth: number;
}

type BreadcrumbEntry = [RegExp, (match: RegExpMatchArray) => JSX.Element | null];



const BreadcrumbLinks: React.FC<BreadcrumbLinksProps> = ({ isMinimized, drawerWidth }) => {
    const { state: { account } } = useAccount();

    const breadcrumbConfig: BreadcrumbEntry[] = [
        [/^\//, () => (
            <Link underline="hover" color="inherit" href="/drawer/accounts">
                All Accounts
            </Link>
        )],
        [/^\//, () => (
            account ? (
                <Link underline="hover" color="inherit" href="/drawer/accounts">
                    {account.accountName}
                </Link>
            ) : null
        )],
        [/^\/drawer\/contracts*/, (match) => (
            account ? (
                <Link underline="hover" color="inherit" href={`/drawer/contracts`}>
                    All Contracts
                </Link>
            ) : null
        )],
        [/^\/drawer\/contract\/(\d+)/, (match) => (
            account ? (
                <Link underline="hover" color="inherit" href={`/drawer/contract/${match[1]}`}>
                    {"100000"+match[1]}
                </Link>
            ) : null
        )],
        [/^\/drawer\/contract\/(\d+)\?attachments=true$/, (match) => (
            account ? (
                <Link underline="hover" color="inherit" href={`/drawer/contract/${match[1]}?attachments=true`}>
                    Documents
                </Link>
            ) : null
        )],
        [/^\/drawer\/contract\/(\d+)\?edit=vendor$/, (match) => (
            account ? (
                <Link underline="hover" color="inherit" href={`/drawer/contract/${match[1]}?edit=vendor`}>
                    Edit Supplier
                </Link>
            ) : null
        )],
        [/^\/drawer\/contract\/(\d+)\?edit=contractName$/, (match) => (
            account ? (
                <Link underline="hover" color="inherit" href={`/drawer/contract/${match[1]}?edit=contractName`}>
                    Edit Contract Name
                </Link>
            ) : null
        )],
        [/^\/drawer\/contract\/(\d+)\?edit=closeDate$/, (match) => (
            account ? (
                <Link underline="hover" color="inherit" href={`/drawer/contract/${match[1]}?edit=closeDate`}>
                    Edit Close Date
                </Link>
            ) : null
        )],
        [/^\/drawer\/contract\/(\d+)\?edit=effectiveDate$/, (match) => (
            account ? (
                <Link underline="hover" color="inherit" href={`/drawer/contract/${match[1]}?edit=effectiveDate`}>
                    Edit Effective Date
                </Link>
            ) : null
        )],
        [/^\/drawer\/contract\/(\d+)\?edit=endDate$/, (match) => (
            account ? (
                <Link underline="hover" color="inherit" href={`/drawer/contract/${match[1]}?edit=endDate`}>
                    Edit End Date
                </Link>
            ) : null
        )],
        [/^\/drawer\/contract\/(\d+)\?edit=contractValue$/, (match) => (
            account ? (
                <Link underline="hover" color="inherit" href={`/drawer/contract/${match[1]}?edit=contractValue`}>
                    Edit Contract Value
                </Link>
            ) : null
        )],
        [/^\/drawer\/contract\/(\d+)\?edit=scopeOfWork$/, (match) => (
            account ? (
                <Link underline="hover" color="inherit" href={`/drawer/contract/${match[1]}?edit=scopeOfWork`}>
                    Edit Scope of Work
                </Link>
            ) : null
        )],
        [/^\/drawer\/contract\/(\d+)\?edit=timeline$/, (match) => (
            account ? (
                <Link underline="hover" color="inherit" href={`/drawer/contract/${match[1]}?edit=timeline`}>
                    Edit Timeline
                </Link>
            ) : null
        )],
        [/^\/drawer\/contract\/(\d+)\?edit=paymentTerms$/, (match) => (
            account ? (
                <Link underline="hover" color="inherit" href={`/drawer/contract/${match[1]}?edit=paymentTerms`}>
                    Edit Payment Terms
                </Link>
            ) : null
        )],
        [/^\/drawer\/contract\/(\d+)\?edit=renewalTerms$/, (match) => (
            account ? (
                <Link underline="hover" color="inherit" href={`/drawer/contract/${match[1]}?edit=renewalTerms`}>
                    Edit Renewal Terms
                </Link>
            ) : null
        )],
        [/^\/drawer\/contract\/(\d+)\?edit=attachments$/, (match) => (
            account ? (
                <Link underline="hover" color="inherit" href={`/drawer/contract/${match[1]}?edit=attachments`}>
                    Edit Documents
                </Link>
            ) : null
        )],
        [/^\/drawer\/contract\/(\d+)\?edit=notes$/, (match) => (
            account ? (
                <Link underline="hover" color="inherit" href={`/drawer/contract/${match[1]}?edit=notes`}>
                    Edit Notes
                </Link>
            ) : null
        )],
        [/^\/drawer\/contract\/(\d+)\?edit=status$/, (match) => (
            account ? (
                <Link underline="hover" color="inherit" href={`/drawer/contract/${match[1]}?edit=status`}>
                    Edit Status
                </Link>
            ) : null
        )],
        [/^\/drawer\/contract\/(\d+)\?edit=serviceType$/, (match) => (
            account ? (
                <Link underline="hover" color="inherit" href={`/drawer/contract/${match[1]}?edit=serviceType`}>
                    Edit Service Type
                </Link>
            ) : null
        )],
    ];
    const location = useLocation();

    return (
        <Breadcrumbs
            sx={{
                position: 'absolute',
                left: isMinimized ? '60px' : `${drawerWidth + 20}px`,
                zIndex: 1, // Ensures it appears above other components
                transition: 'left 0.3s ease',
                ml: "5px",
                pt: "12px",
            }}
            aria-label="breadcrumb"
        >
            {breadcrumbConfig.map(([regex, renderLink]) => {
                const fullPath = location.pathname + location.search;
                const match = fullPath.match(regex);
                return match ? renderLink(match) : null;
            })}
        </Breadcrumbs>
    );
};

export default BreadcrumbLinks;
