import {Paper} from "@mui/material";


const VendorReview = () => {

    return(
        <Paper>
            <p> You haven’t given any reviews yet.</p>
        </Paper>
    )
}

export default VendorReview;