import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField } from "@mui/material";
import { useAccount } from "./provider/AccountProvider";
import {ACCOUNT_ENDPOINT, REMOTE_API_BASE_URL} from "../interfaces/apiConstants";
import {useUser} from "./UserProvider";

interface Account {
    accountId: number;
    accountName: string;
    corporateAccount: boolean;
}

export default function MultipleSelectCheckmarks() {
    const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);
    const [accounts, setAccounts] = useState<Account[]>([]);

    const { state: accountState, dispatch, state: { accountsUpdated } } = useAccount();
    const { state: { user } } = useUser();
    useEffect(() => {
        if(user){
            const fetchAccountNames = async () => {
                try {
                    const response = await fetch(`${ACCOUNT_ENDPOINT}/client/${user.id}/accounts/contracts/summary`);
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const data = await response.json();

                    // Ensure corporateAccount is a boolean
                    data.sort((a: { corporateAccount: any; }, b: { corporateAccount: any; }) => (a.corporateAccount === b.corporateAccount) ? 0 : a.corporateAccount ? -1 : 1);

                    console.log("Fetched Accounts: 5", data);
                    setAccounts(data);
                } catch (error) {
                    console.error("Failed to fetch account names:", error);
                }
            };
            fetchAccountNames();
        }

    }, [user?.id, accountsUpdated ]);

    useEffect(() => {
        if (accountState?.account) {
            const adaptedAccount = {
                accountName: accountState.account.accountName,
                accountId: accountState.account.id,
                corporateAccount: accountState.account.corporateAccount
            };
            setSelectedAccount(adaptedAccount);
        } else {
            setSelectedAccount(null);
        }
    }, [accountState]);

    const handleAccountChange = (event: any, newValue: Account | null) => {
        setSelectedAccount(newValue);
        if (newValue) {
            dispatch({ type: 'SET_ACCOUNT', payload: { accountName: newValue.accountName, id: newValue.accountId, corporateAccount: newValue.corporateAccount } });
        } else {
            dispatch({ type: 'CLEAR_ACCOUNT' });
        }
    };

    return (
        <div>
            <Autocomplete
                id="autocomplete-outlined-basic"
                options={accounts}
                groupBy={(option) => option.corporateAccount ? 'Corporate Accounts' : 'Subsidiary Accounts'}
                getOptionLabel={(option) => option.accountName}
                value={selectedAccount}
                onChange={handleAccountChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Account"
                        variant="outlined"
                        fullWidth
                    />
                )}
                isOptionEqualToValue={(option, value) => option.accountId === value.accountId}
            />
        </div>
    );
}
