// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Selection from './components/Selection';
import Auth from './containers/Login/Auth';
import Dashboard from './components/Dashboard';
import ContractPage from './components/ContractPage';
import ResponsiveDrawer from './components/SidePanel'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LandingPage from "./components/LandingPage";
import {UserProvider} from "./components/UserProvider";
import {AccountProvider} from "./components/provider/AccountProvider";
import {PreviewProvider} from "./components/provider/PreviewContext";
import defaultTheme from "./components/themes/defaultTheme";
import azureTheme from "./components/themes/azureTheme";
import pdfjsWorker from "react-pdf/node_modules/pdfjs-dist/build/pdf.worker.min.mjs";
import { pdfjs } from 'react-pdf';

// Automatically get the version of pdfjs-dist
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';
const App: React.FC = () => {
    const [themeMode, setThemeMode] = React.useState('light'); // State to toggle theme

    // Function to toggle theme mode
    const toggleTheme = (theme: string) => {
        setThemeMode(theme === 'light' ? 'dark' : 'light');
    };

    const comic = createTheme({
        palette: {
            mode: 'dark',
            primary: {
                main: '#bd0707',
            },
            secondary: {
                main: '#ffc510',
            },
            background: {
                default: '#4c69f6',
                paper: '#4c94f6',
            },
        },
        typography: {

            fontFamily: 'Bangers',

        },
    });

    const themeOptions = createTheme({
        palette: {
            mode: 'light',
            primary: {
                main: '#f9595f', // A pink-red shade similar to the buttons and highlights
            },
            secondary: {
                main: '#000000', // A teal shade, as seen in some UI elements
            },
            background: {
                default: '#d5d2cd', // A light background
                paper: '#ffffff', // Slightly off-white for paper elements
            },
            text: {
                primary: '#2c2929', // Dark gray for primary text
                secondary: '#6d6d6d', // Lighter gray for secondary text
            },
            info: {
                main: '#2196f3', // Blue for informational elements
            },
            error: {
                main: '#f44336', // Red for error states
            },
            warning: {
                main: '#ffc107', // Amber for warning messages
            },
            success: {
                main: '#4caf50', // Green for success states
            },
        },
        typography: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // The default font family
            h1: {
                fontFamily: '"Roboto Slab", "Times New Roman", serif', // A slab serif for headings
                fontWeight: 700,
            },
            h2: {
                fontFamily: '"LatinoType", "Roboto Slab", "Times New Roman", serif',
                fontWeight: 700,
            },
            h3: {
                fontFamily: '"Roboto Slab", "Times New Roman", serif',
                fontWeight: 700,
            },
            h4: {
                fontFamily: '"LatinoType", "Times New Roman", serif',
                fontWeight: 400,
                color:  '#3b3b3b' // A light background
            },
            h5: {
                fontFamily: '"LatinoType", "Times New Roman", serif',
                fontWeight: 400,
                color:  '#3b3b3b' // A light background
            },


            // ... and so on for h4, h5, h6, subtitle1, subtitle2, button, overline
            button: {
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: 500,
            },
            overline: {
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                textTransform: 'uppercase',
            },
        },

        components: {
            MuiButton: {
                styleOverrides: {
                    contained: {
                        background:'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)'
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        //boxShadow: '0px 0px 30px rgba(75,56,162,0.35)',

                        //background: 'linear-gradient(0deg, #dadada 30%, #FF8E53 90%)'
                    },
                },
            },
            MuiCssBaseline: {
                styleOverrides: `
                    body {
                        background: linear-gradient(0deg, #FFFFFF 85%, rgba(75,56,162,0.35) 100%);
                        min-height: 100vh; /* Full viewport height */
                    }
                `,
            },
        },
    });

    const darkTheme = createTheme({
        palette: {
            mode: 'dark', // This sets the color mode of the theme
            background: {
                default: '#2c2929', // Background color for the dark mode
                paper: '#2c2929', // Background color for components using paper
            },
            text: {
                primary: 'rgba(75,56,162,0.35)', // Primary font color for the dark mode
                secondary: '#0a0823', // Secondary font color for the dark mode
            },

        },
        typography: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // The default font family
            h1: {
                fontFamily: '"Roboto Slab", "Times New Roman", serif', // A slab serif for headings
                fontWeight: 700,
            },
            h2: {
                fontFamily: '"LatinoType", "Roboto Slab", "Times New Roman", serif',
                fontWeight: 700,
            },
            h3: {
                fontFamily: '"Roboto Slab", "Times New Roman", serif',
                fontWeight: 700,
            },
            h4: {
                fontFamily: '"LatinoType", "Times New Roman", serif',
                fontWeight: 400,
                color:  '#8fc8f7' // A light background
            },
            h5: {
                fontFamily: '"LatinoType", "Times New Roman", serif',
                fontWeight: 400,
                color:  '#8fc8f7' // A light background
            },


            // ... and so on for h4, h5, h6, subtitle1, subtitle2, button, overline
            button: {
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: 500,
            },
            overline: {
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                textTransform: 'uppercase',
            },
        },
    });
    const lightTheme = createTheme({
        palette: {
            mode: 'light',
            background: {
                default: '#ffffff', // Light mode background color
                paper: '#ffffff', // Light mode background color for paper
            },
            text: {
                primary: '#000000', // Primary font color for light mode
                secondary: '#555555', // Secondary font color for light mode
            },
        },
    });


    return (
        <Router>
            <ThemeProvider theme={azureTheme}>
                <UserProvider>
                    <AccountProvider>
                        <PreviewProvider>
                        <Switch>
                        <Route exact path="/" component={LandingPage} />

                        <Route path="/drawer" component={ResponsiveDrawer} />
                        </Switch>
                        </PreviewProvider>
                    </AccountProvider>
                </UserProvider>
            </ThemeProvider>
        </Router>

    );
};

export default App;
