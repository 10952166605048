import CustomTable from "./Dashboard";
import * as React from "react";
import {useEffect, useState} from "react";
import {Button, Fade, Grow, Paper, TextField, Typography} from "@mui/material";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import {DataGrid, GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import ScheduledPaymentsRowEdit from "./ScheduledPaymentsRowEdit";
import Chip from "@mui/material/Chip";
import VendorListRowEdit from "./VendorListRow";
import {Link, useHistory} from "react-router-dom";
import ProfileVendorEdit from "./ProfileVendorEdit";
import {Vendor} from "../interfaces/vendor";
import {VENDOR_ENDPOINT} from "../interfaces/apiConstants";
import DataGridToolBar from "./DataGridToolBar";

const renderStatus = (params: GridRenderCellParams) => {
    let backgroundColor, color;
    switch (params.value) {
        case 'Pending':
            backgroundColor = '#ffd97a'; // Light Salmon
            color = '#9b7e1e'; // Darker Salmon
            break;
        case 'Paid':
            backgroundColor = '#98FB98'; // Pale Green
            color = '#3CB371'; // Medium Sea Green
            break;
        case 'Delinquent':
            backgroundColor = '#FFB6C1'; // Light Pink
            color = '#d55595'; // Hot Pink
            break;
        default:
            backgroundColor = '#D3D3D3'; // Light Grey
            color = '#A9A9A9'; // Dark Grey
    }

    return (
        <Chip
            label={params.value}
            size="small"
            sx={{ bgcolor: backgroundColor, color: color, fontWeight: 'bold' }}
        />
    );
};

const formatCurrency = (amount: number): string => {
    return `$${amount.toFixed(2)}`; // Adjust formatting as needed
};

/*
renderCell: (params) => (
            <Link to={`/drawer/vendor/${params.value}`}>{params.value}</Link>
        ),
 */

const columns: GridColDef[] = [
    {
        field: 'businessName',
        headerName: 'Name',
        flex: 1,

        align: 'center', headerAlign: 'center',
    },
    {
        field: 'typeOfBusiness',
        flex: 1,
        headerName: 'Service',
        align: 'center', headerAlign: 'center',
    },
    {
        field: 'numberOfContracts',
        flex: 1,
        headerName: 'Address',
        align: 'center', headerAlign: 'center',
    },
    {
        field: 'businessWebsite',
        flex: 1,
        headerName: 'Website',
        align: 'center', headerAlign: 'center',
    },
    { field: 'email', headerName: 'Status', width: 250, align: 'center', headerAlign: 'center', },
    /*{ field: 'numberOfContracts', headerName: '# of Contracts', width: 140, align: 'center', headerAlign: 'center' },*/
    /*{
        field: 'created',
        headerName: 'Created',
        type: 'date',
        width: 150,
        align: 'center',
        headerAlign: 'center',
        valueGetter: (params) => params.value ? new Date(params.value) : null,
    },*/
    // ... [other columns as needed]
];
const VendorTable = () => {
    const history = useHistory();
    const handleRowClick = (params: any) => {
        // Assuming 'contractId' is the unique identifier for each row
        // Navigate to the desired page with the contract's ID
        console.log( params)
        history.push(`/drawer/vendor/${params.row.businessName}`);
    };


    const [openScheduledPayments, setScheduledPayments] = React.useState(false);
    const handleOpenScheduledPayments = () => setScheduledPayments(true);
    const handleCloseScheduledPayments = () => {
        fetchVendors();
        setScheduledPayments(false);
    }


    //const [gridRows, setGridRows] = useState(sampleGridData);


    const [vendors, setVendors] = useState<Vendor[]>([]);
    const fetchVendors = async () => {
        try {
            const response = await fetch(`${VENDOR_ENDPOINT}`);
            const vendors = await response.json();
            //console.log(data);
            //console.log(vendorNames);
            setVendors(vendors);
        } catch (error) {
            console.error('Failed to fetch vendors:', error);
        }
    };
    useEffect(() => {
        fetchVendors();
    }, []);

    const onDataChange = (newData: any) => {
        fetchVendors()
    };

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    return (
        <Box>
        <div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="h5">Suppliers</Typography>
                {/*<Button onClick={handleOpenScheduledPayments}
                        variant="contained"
                        startIcon={<AddTwoToneIcon style={{ fontSize: 'small' }} />}
                >
                    Add Vendor
                </Button>*/}
            </div>
            <br/>
            <Fade  in={true} timeout={400}>
            <div style={{ height: "85vh", width: '100%' }}>
                <DataGrid
                    disableColumnMenu
                    rows={vendors}
                    columns={columns}
                    onRowClick={handleRowClick}
                    slots={{
                        toolbar: DataGridToolBar,
                    }}
                    sx={{
                        '.MuiDataGrid-columnHeaderTitle': {
                            fontWeight: 'bold', // Applying bold directly
                        }
                    }}
                />
            </div>
            </Fade >

            <Modal
                open={openScheduledPayments}
                onClose={handleCloseScheduledPayments}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                BackdropProps={{
                    style: {
                        backgroundColor: 'rgba(255, 255, 255, 0.7)', // Or any color with desired opacity
                    },
                }}
            >
                <Box sx={style}>
                    <ProfileVendorEdit goBack={handleCloseScheduledPayments} onDataChange={onDataChange}  />
                </Box>
            </Modal>
        </div>
        </Box>

    )

}

export default VendorTable;