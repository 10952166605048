export const documentStepRadioOptions  = [
    { value: 'ACTIVE_CONTRACT', label: 'Active Contract' },
    { value: 'ACTIVE_DOCUMENT', label: 'Active Document' },
    { value: 'DRAFT_DOCUMENT', label: 'Draft Document' },
    { value: 'ARCHIVE_DOCUMENT', label: 'Archive Document' },
    { value: 'new', label: 'New Document' },
    { value: 'replace', label: 'Replace Existing Document' },
    { value: 'new', label: 'New Document' },
];

