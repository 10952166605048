// src/api/fetchContracts.ts
import {ACCOUNT_ENDPOINT, CONTRACT_ENDPOINT} from "../interfaces/apiConstants";
import {Contract} from "../interfaces/contract";
import dayjs, {Dayjs} from "dayjs";
import {Attachment} from "../interfaces/attachment";
import {TimelineEvent} from "../interfaces/TimeLineEvent";


export async function fetchContractData (contractId: string ): Promise<Contract> {
    try {
        const response = await fetch(`${CONTRACT_ENDPOINT}/${contractId}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error("Failed to fetch contract", error);
        throw error; // Rethrow the error to be handled by the calling function
    }
}

export async function fetchContracts(userId: number | undefined): Promise<any[]> {
    if (!userId) {
        throw new Error("User ID is undefined");
    }

    try {
        const response = await fetch(`${CONTRACT_ENDPOINT}/client/${userId}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data: any[] = await response.json();
        // Sort contracts if necessary
        // ...

        const sortedContracts = data.sort((a: any, b: any) => {
            // Handle null names by defining them as empty strings for comparison
            const nameA = a.contractName ?? '';
            const nameB = b.contractName ?? '';
            return nameA.localeCompare(nameB);
        });

        return sortedContracts; // Return the sorted contracts
    } catch (error) {
        console.error("Failed to fetch contracts:", error);
        throw error; // Rethrow the error to be handled by the calling function
    }
}

export async function fetchAndMapContractsByAccount(userId: number | undefined): Promise<Map<number, any[]>> {
    if (!userId) {
        throw new Error("User ID is undefined");
    }

    try {
        console.log( `${ACCOUNT_ENDPOINT}/client/${userId}/accounts/contracts/summary`)
        const response = await fetch(`${ACCOUNT_ENDPOINT}/client/${userId}/accounts/contracts/summary`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const accounts: { accountId: number; contracts: any[] }[] = await response.json();

        // Initialize the map
        const accountIdToSortedContractsMap = new Map<number, any[]>();
        console.log( `${ACCOUNT_ENDPOINT}/client/${userId}/accounts/contracts/summary`)

        // Iterate through each account
        accounts.forEach(account => {


            // First, sort contracts within each account based on 'contractName'
            const sortedContracts = account.contracts.sort((a, b) => {
                const nameA = a.contractName ?? '';
                const nameB = b.contractName ?? '';
                return nameA.localeCompare(nameB);
            });

            // Then, iterate over the sorted contracts to add 'vendorName' property
            const updatedContracts = sortedContracts.map(contract => ({
                ...contract,
                vendor: contract?.vendorName, // Assuming 'vendor' is a property of the contract
            }));
            console.log( updatedContracts)

            // Add to map: account ID to sorted and updated contracts
            accountIdToSortedContractsMap.set(account.accountId, updatedContracts);
        });

        return accountIdToSortedContractsMap; // Return the map
    } catch (error) {
        console.error("Failed to fetch and map contracts:", error);
        throw error;
    }
}


interface AccountSummary {
    accountId: number;
    accountName: string;
    yearEstablished: string;
    entityName: string;
    contracts: any[];
    corporateAccount: boolean;
    industry: string;
    address: string;
    website: string;
    email: string;
    phoneNumber: string;
    faxNumber: string;
    numberOfEmployees: string;
    corporateParentId: string | null;
    corporateParentName: string | null;
}

export async function fetchAccounts(userId: number | undefined): Promise<AccountSummary[]> {
    if (!userId) {
        throw new Error("User ID is undefined");
    }

    try {
        console.log(`${ACCOUNT_ENDPOINT}/client/${userId}/accounts/contracts/summary`);
        const response = await fetch(`${ACCOUNT_ENDPOINT}/client/${userId}/accounts/contracts/summary`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const accounts: AccountSummary[] = await response.json();

        return accounts;
    } catch (error) {
        console.error("Failed to fetch and map contracts:", error);
        throw error;
    }
}

export async function fetchAndMapAccountAndEvents(userId: number | undefined): Promise<Map<number, any[]>> {
    if (!userId) {
        throw new Error("User ID is undefined");
    }

    try {
        console.log( `${ACCOUNT_ENDPOINT}/client/${userId}/accounts/events/`)
        const response = await fetch(`${ACCOUNT_ENDPOINT}/client/${userId}/accounts/events/`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const accounts: { accountId: number; events: any[] }[] = await response.json();

        // Initialize the map
        const accountIdToSortedContractsMap = new Map<number, any[]>();

        // Iterate through each account
        accounts.forEach(account => {

            // Then, iterate over the sorted contracts to add 'vendorName' property

            console.log( account)
            const today = dayjs();
            const futureEvents = account.events.filter(event => event.eventDate && dayjs(event.eventDate).isAfter(today) && event.event );
// Sort the filtered events by eventDate
            futureEvents.sort((a, b) => dayjs(a.eventDate).diff(dayjs(b.eventDate)));


            // Add to map: account ID to sorted and updated contracts
            accountIdToSortedContractsMap.set(account.accountId, futureEvents);
        });
        console.log(accountIdToSortedContractsMap)
        return accountIdToSortedContractsMap; // Return the map
    } catch (error) {
        console.error("Failed to fetch and map contracts:", error);
        throw error;
    }
}


export async function deleteAttachmentApi(attachmentId: string) {
    try {
        const response = await fetch(`${CONTRACT_ENDPOINT}/attachment/${attachmentId}`, {
            method: 'DELETE',
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        // Check if the response has content
        const text = await response.text();
        try {
            // If there's content, try to parse it as JSON
            return JSON.parse(text);
        } catch (e) {
            // If parsing throws an error, it means response wasn't JSON, so just return an empty object
            console.log('No JSON content returned from DELETE operation');
            return {};
        }
    } catch (error) {
        console.error("Could not delete the attachment:", error);
        // Handle the error state appropriately, such as displaying a message to the user
    }
}

export async function saveAttachmentApi(attachment: any, contractId: any){
    const formData = new FormData();
    formData.append('file', attachment.file); // Append the file directly to FormData
    formData.append('state', attachment.state); // Append the file directly to FormData
    // Append the metadata as JSON under the 'fileData' key
    formData.append('id', attachment.id.includes('NEW') ? '' : attachment.id);
    // Append other form data as necessary
    // formData.append('name', attachment.name )
    if (attachment.tag !== undefined) {
        formData.append('tag', attachment.tag);
    } else {
        formData.append('tag', ''); // or don't append it at all if that's acceptable for your backend
    }

    formData.append('contractId', contractId.toString()); // Append the contractId

    // Perform the fetch inside an async function or use then/catch for promise handling
    fetch(`${CONTRACT_ENDPOINT}/upload`, {
        method: 'POST',
        body: formData,
    })
        .then(response => {
            if (!response.ok) {
                throw new Error('File upload failed');
            }
            return response.json();
        })
        .then(data => {
            // Handle success for this file
            console.log('File uploaded successfully', data);
        })
        .catch(error => {
            // Handle error for this file
            console.error('Error uploading file:', attachment.name, error);
        });
}




export async function  postContract  (contractPayload: any, attachments: Attachment[] = [])  {
    console.log("Handle Submit!!!!!!!!!!!!!!")
    // Prepare the contract details data
    const contractDetails = contractPayload;
    console.log(contractDetails);

    const contractDetailsData =  JSON.stringify(contractDetails);
    //alert(contractDetailsData)
    // First, submit the contract details
    try {
        const responseDetails = await fetch(`${CONTRACT_ENDPOINT}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: contractDetailsData
        });

        if (!responseDetails.ok) {
            return responseDetails.text().then(text => {
                const message =  text || 'Contract details submission failed'
                console.log(text)
                return;
            });

        }

        // Parse the JSON response body to access the contractId
        const responseData = await responseDetails.json(); // This line extracts the JSON body

        // Check if the responseData actually contains the contractId
        if (responseData && responseData.contractId) {
            if( attachments ){
                attachments.forEach((attachment) => {
                    console.log(attachment)

                    if(attachment.markedForDelete){
                        deleteAttachmentApi(attachment.id)
                    }else{
                        saveAttachmentApi(attachment, responseData.contractId)
                    } //TODO FIX UPDATE CONTRACT BUG THIS DISCOUNT BUG
                });
            }

        } else {
            throw new Error('No contract ID present or no file to upload');
        }

        return responseData

    } catch (error: any) {
        console.error('There was an error submitting the contract:', error);
        return Promise.reject(error.toString());
    }

};