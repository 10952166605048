import React from 'react';
import { Box, Paper } from '@mui/material';

interface RibbonProps {
    leftChildren: React.ReactNode;
    rightChildren: React.ReactNode;
}

const Ribbon: React.FC<RibbonProps> = ({ leftChildren, rightChildren }) => {
    return (
        <Paper
            elevation={0}
            sx={{
                borderRadius: '10px',
                my: 1,
                p: 1,
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: 'rgba(176,176,176,0.69)',
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box display="flex" gap={1}>
                    {leftChildren}
                </Box>
                <Box display="flex" gap={1}>
                    {rightChildren}
                </Box>
            </Box>
        </Paper>
    );
};

export default Ribbon;
