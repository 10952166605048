import React from 'react';
import {
    Box,
    Button,
    Checkbox,
    Container,
    FormControlLabel,
    FormGroup,
    Grid,
    Paper,
    TextField,
    Typography
} from '@mui/material';
import dayjs from "dayjs";



interface AboutInformation{
    businessDescription: string,
    yearsInBusiness: number | null,
    numberOfEmployees: number
}




type ResponsiveDatePickersProps = {
    goBack: () => void;
    onDataChange: (AboutInformation: any) => void;
};
export default function ResponsiveDatePickers( { goBack, onDataChange }: ResponsiveDatePickersProps) {
    // State for form fields (not fully implemented for brevity)
    const [aboutInfo, setAboutInfo] = React.useState<AboutInformation>({
        businessDescription: '',
        yearsInBusiness: null,
        numberOfEmployees: 0
    });

    const handleChange = (event: { target: { name: any; value: any; }; }) => {
        const { name, value } = event.target;
        setAboutInfo({ ...aboutInfo, [name]: value });
    };


    const handleSubmit = (event: any) => {
        onDataChange(aboutInfo);
        goBack();
        // Form processing logic goes here
    };

    const handleCancel = ()=>{
        goBack();
    };


    return (
        <Container component="main" maxWidth="sm">
            <Typography component="h1" variant="h5">
                About
            </Typography>
            <form onSubmit={handleSubmit}>
                <Box sx={{ mt: 3 }}>
                    <TextField
                        required
                        fullWidth
                        id="business-description"
                        label="Business Description"
                        name="businessDescription"
                        multiline
                        rows={4}
                        placeholder="Tell potential customers what makes you stand out. Include details like specific services offered, info about your team’s expertise, and how you guarantee quality work."
                        inputProps={{ maxLength: 2000 }}
                        value={aboutInfo.businessDescription}
                        onChange={handleChange}
                    />
                    <TextField
                        fullWidth
                        id="in-business-since"
                        label="In business since"
                        name="inBusinessSince"
                        type="number"
                        InputLabelProps={{ shrink: true }}
                        placeholder="Year"
                        sx={{ mt: 2 }}
                        value={aboutInfo.yearsInBusiness}
                        onChange={handleChange}
                    />
                    <TextField
                        fullWidth
                        id="number-of-employees"
                        label="# of employees"
                        name="numberOfEmployee"
                        type="number"
                        InputLabelProps={{ shrink: true }}
                        sx={{ mt: 2 }}
                        value={aboutInfo.numberOfEmployees}
                        onChange={handleChange}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                    <Button
                        type="submit"
                        fullWidth
                        sx={{ mt: 3, mb: 2 }}
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                </Box>
            </form>
        </Container>
    );
}

