import React, {useState} from 'react';
import {
    Box,
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Typography,
    Paper, Button, Grid, Card, CardContent, Stack, CardActions, TextField
} from '@mui/material';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import Divider from "@mui/material/Divider";
import {useHistory} from "react-router-dom";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import ContractEdit from "./ContractEdit";
import StarIcon from "@mui/icons-material/Star";
import MessageItem from "./Messages";
import MinimizeIcon from "@mui/icons-material/Minimize";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";

interface Event {
    id: number;
    title: string;
    dateSent: string;
    eventType: string;
    eventDate: string;
    imageUrl: string;
    status: 'available' | 'unavailable';
}




const eventsData: Event[] = [
    // Populate with your actual events data
    {
        id: 1,
        title: 'Install a Garage in Building XYZ',
        dateSent: 'Aug 1',
        eventType: 'Brooklyn Garage Masters',
        eventDate: 'Sat, Aug 5, 2023',
        imageUrl: 'garage_1.png', // Replace with the actual path to the image
        status: 'unavailable',
    },{
        id: 1,
        title: 'Garage Experts Inc.',
        dateSent: 'Aug 1',
        eventType: 'Property Manager LLC.',
        eventDate: 'Sat, Aug 5, 2023',
        imageUrl: 'garage_2.png', // Replace with the actual path to the image
        status: 'unavailable',
    }/*
    {
        id: 1,
        title: 'Citywide Garage Solutions',
        dateSent: 'Aug 1',
        eventType: 'Property Manager LLC.',
        eventDate: 'Sat, Aug 5, 2023',
        imageUrl: 'garage_3.png', // Replace with the actual path to the image
        status: 'unavailable',
    },
    {
        id: 1,
        title: 'Brooklyn Garage Masters',
        dateSent: 'Aug 1',
        eventType: 'Property Manager LLC.',
        eventDate: 'Sat, Aug 5, 2023',
        imageUrl: 'garage_4.png', // Replace with the actual path to the image
        status: 'unavailable',
    },*/
    // ... other events
];

interface VendorInfo {
    name: string;
    location: string;
    rating: number;
    reviewCount: number;
    imageUrl: string;
}


const vendorInfo: VendorInfo = {
    name: 'Property Manager LLC',
    location: 'New York City, NY',
    rating: 4.96,
    reviewCount: 6,
    imageUrl: '/garage_15346.png', // Replace with the path to the vendor's image
};

const items = [
    { id: 0, name: 'Frank Flushing', jobTitle: 'CEO', avatarUrl: '/path/to/alice.jpg' },
    { id: 1, name: 'Harold Hills', jobTitle: 'Product Manager', avatarUrl: '/path/to/bob.jpg' },
    { id: 2, name: 'Alex Astoria', jobTitle: 'Account Manager', avatarUrl: '/path/to/bob.jpg' },
    // ... more items
];

interface ProposalComparisonTableProps {
    activeNegotiation: number;
    setActiveNegotiation: (value: number) => void;
}

const ContractNegotiations: React.FC<ProposalComparisonTableProps> = ({ activeNegotiation, setActiveNegotiation }) => {


    const handleOpenProposal = (professionalId: number) => {
        // Implement your logic to open proposal details
        //console.log('Open proposal for professional with ID:', professionalId);

        setActiveNegotiation(professionalId);
    };
    const history = useHistory();

    const [message, setMessage] = useState('What would you like to say? ');


    const sendMessage = () => {
        //console.log(message); // Here you would handle the message sending logic
        setMessage(''); // Reset message input after sending
    };

    const handleSendMessage = () => {
        // Handle the message sending logic here
        //console.log(message);
        setMessage(''); // Clear the input field after sending the message
    };


    const handleToggleExpand = (chatId: number) => {
        // Create a new array with the 'isExpanded' flag toggled for the chat with the given ID
        const updatedChats = chats.map(chat => {
            if (chat.id === chatId) {
                return { ...chat, isExpanded: !chat.isExpanded };
            }
            return chat;
        });

        // Update the state with the new chats array
        setChats(updatedChats);
    };

    const [chats, setChats] = useState([
        { id: 0, name: 'Frank Flushing', message: 'This is a sample message', isExpanded: false },
        // ... you can add more chats here
    ]);

    const handleRemoveChat = (chatId: number) => {
        // Filter out the chat with the matching id
        setChats(chats.filter(chat => chat.id !== chatId));
    };

    const handleAddChat = (itemId: number) => {
        // Retrieve the name from items using the itemId
        const newName = items[itemId].name;

        // Create a new chat object
        const newChat = {
            id: chats.length, // Assuming id should be the next sequential number
            name: newName,
            message: 'This is a sample message', // You can customize this message
            isExpanded: false // Default state for expansion can be set as false
        };

        // Append the new chat to the existing chats array
        setChats([...chats, newChat]);
    };

    return (
        <div>
            {activeNegotiation === -1 && (
                <Paper style={{ padding: '20px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant="h4" style={{paddingLeft:'5px'}}>Contract Negotiations</Typography>
                    </div>

                    <List>
                        {eventsData.map((event, index) => (
                            <React.Fragment key={event.id}>
                                <ListItem alignItems="flex-start"  >
                                    <ListItemAvatar>
                                        <Avatar src={"/" + event.imageUrl} />
                                    </ListItemAvatar>
                                    <ListItemText style={{cursor: 'pointer' }} onClick={() => handleOpenProposal(event.id)}
                                                  primary={event.title}
                                                  secondary={
                                                      <>
                                                          <Typography component="span" variant="body2" color="text.primary">
                                                              Sent: {event.dateSent}
                                                          </Typography>
                                                          {" — "}{event.eventType}{" — "}{event.eventDate}
                                                      </>
                                                  }
                                    />

                                </ListItem>
                                {/* Add the Divider conditionally based on index */}
                                {index !== eventsData.length - 1 && <Divider variant="inset" component="li" />}
                            </React.Fragment>
                        ))}
                    </List>
                </Paper>)
            }
            {activeNegotiation != -1 && (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8} >

                            <Paper sx={{p:3, my: 1}}>
                                <Grid container alignItems="center" justifyContent="center">
                                    <Grid item xs={12} container justifyContent="center">
                                        <Typography variant="h4" component="h1" gutterBottom>
                                            We will take care of the heavy lifting!
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} container justifyContent="center">
                                        <Typography variant="h5" component="h1" gutterBottom>
                                            Upload a Service Agreement and Travis will handle the rest.
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent="center" style={{ marginTop: '20px' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<AutoFixHighIcon style={{ fontSize: 'large' }} />}
                                    >
                                        Upload
                                    </Button>
                                </Grid>


                            </Paper>
                            {/*<Paper sx={{ mb: 1, p: 2 }}>
                                <ContractEdit onClose={()=>{}}  contract={null} isEditCancelButtonAvailable
                                              onRightPanelExpand={()=>{}}
                                              onRightPanelRetract={()=>{}}></ContractEdit>
                            </Paper>*/}
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Card>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Vendor
                                    </Typography>
                                    <Avatar
                                        alt={'Citywide Garage Solutions'}
                                        src={'/garage_3.png'}
                                        sx={{ width: 150, height: 150 }}
                                    />
                                    <Typography variant="h6">{'Citywide Garage Solutions'}</Typography>
                                    <Typography variant="body2">{'Queens, NY'}</Typography>
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <StarIcon color="primary" />
                                        <Typography variant="body2">
                                            {vendorInfo.rating} ({vendorInfo.reviewCount} reviews)
                                        </Typography>
                                    </Stack>
                                </CardContent>
                                <CardActions>
                                    <Button size="small">View Profile</Button>
                                    <Button size="small">Write a review</Button>
                                </CardActions>
                            </Card>

                            <Paper sx={{my: 2, p:2}}>
                                <List>
                                    {items.map(item => (
                                        <ListItem key={item.id}>
                                            <ListItemAvatar>
                                                <Avatar src={item.avatarUrl} />
                                            </ListItemAvatar>
                                            <ListItemText primary={item.name} secondary={item.jobTitle} />
                                            <Button variant="contained" color="primary" onClick={()=>handleAddChat(item.id)}>
                                                Message
                                            </Button>
                                        </ListItem>
                                    ))}
                                </List>
                            </Paper>



                            <Paper sx={{ my: 2,  p: 2 }}>

                                {/* ... */}
                                <MessageItem></MessageItem>
                                <Divider sx={{ my: 2 }} />
                                {/* Here you would add the quote information and other messages */}
                                {/* ... */}
                            </Paper>
                            {chats.map((chat) => (
                                <React.Fragment key={chat.id}>
                                    <Box sx={{
                                        position: 'fixed',
                                        bottom: 0,
                                        right: 80 + ( 320 * chat.id) ,
                                        width: 300, // Width of the chat box
                                        height: chat.isExpanded ? 500 : 72, // Height of the chat box
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        <Paper elevation={3} sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: '100%',
                                            overflow: 'hidden'
                                        }}>
                                            {/* Header */}
                                            <Box sx={{ p: 2, display: 'flex', alignItems: 'center', backgroundColor: '#d3d0cb' }}>
                                                <Avatar sx={{ marginRight: 2 }}>D</Avatar>
                                                <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>{chat.name}</Typography>

                                                <IconButton onClick={() => handleToggleExpand(chat.id)}>
                                                    <MinimizeIcon/>
                                                </IconButton>

                                                <IconButton onClick={() => handleRemoveChat(chat.id)}>
                                                    <CloseIcon />
                                                </IconButton>
                                            </Box>

                                            {/* Message Area */}
                                            <Box sx={{ p: 2, flexGrow: 1, overflow: 'auto' }}>
                                                <Typography variant="body1">
                                                    Hi Matthew,
                                                    <br />
                                                    {/* Add the rest of the message here */}
                                                </Typography>
                                            </Box>

                                            {/* Reply Area */}
                                            <Box sx={{ display: 'flex', alignItems: 'center', padding: 2, backgroundColor: '#f5f5f5' }}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    variant="outlined"
                                                    placeholder="Type a message..."
                                                    value={message}
                                                    onChange={(e) => setMessage(e.target.value)}
                                                    onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                                                    sx={{ marginRight: 1 }}
                                                />
                                                <IconButton color="primary" onClick={handleSendMessage}>
                                                    <SendIcon />
                                                </IconButton>
                                            </Box>
                                        </Paper>
                                    </Box>
                                </React.Fragment>
                            ))}


                        </Grid>
                    </Grid>
                </Box>
            )}
        </div>
    );
}

export default ContractNegotiations;
